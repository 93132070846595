import { useState, useEffect } from "react"
import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import BlogEditor from "@/components/BlogEditor/BlogEditor"
import { useUser } from "@/providers/UserProvider"
import { UsernamePromptModal } from "@/components/UsernamePromptModal"
import { BackButton } from "@/components/BackButton"
import { LoadingScreen } from "@/providers"
import { Navbar } from "@/components/Navbar"

export const Route = createFileRoute("/research/new")({
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { isSubscribed, userData, isLoading: isUserLoading } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: "/research/new",
          },
        })
      }

      // Redirect to checkout if not subscribed
      if (isAuthenticated && !isSubscribed) {
        navigate({ to: "/checkout" })
      }

      // Redirect to onboarding if user hasn't completed it
      if (
        isAuthenticated &&
        !isUserLoading &&
        isSubscribed &&
        !userData?.hasOnboarded
      ) {
        navigate({ to: "/onboarding" })
      }
    }, [isLoading, isAuthenticated, isSubscribed, userData, isUserLoading])

    // Show LoadingScreen while checking auth status or redirecting
    if (isLoading || isUserLoading || !isAuthenticated) {
      return <LoadingScreen />
    }

    return <NewPost />
  },
})

const NewPost = () => {
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(true)
  const { userData } = useUser()
  const navigate = useNavigate()

  // Check username status whenever userData changes
  useEffect(() => {
    if (userData?.userName) {
      setShowUsernamePrompt(false)
    }
  }, [userData?.userName])

  if (!userData?.userName && showUsernamePrompt) {
    return (
      <UsernamePromptModal
        onClose={() => {
          setShowUsernamePrompt(false)
          // If they cancel without setting a username, go back
          if (!userData?.userName) {
            navigate({ to: "/dashboard" })
          }
        }}
      />
    )
  }

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <div className="max-w-4xl mx-auto">
        <BlogEditor
          initialContent=""
          initialTitle=""
          onCancel={() => window.history.back()}
        />
      </div>
    </div>
  )
}
