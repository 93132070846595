import React, { useMemo, useState, useCallback } from "react"
import {
  createEditor,
  Descendant,
  Element as SlateElement,
  Range,
  Transforms,
  Editor,
  Text,
} from "slate"
import {
  Slate,
  Editable,
  withReact,
  RenderElementProps,
  useSelected,
  useFocused,
  useSlate,
} from "slate-react"
import { withHistory } from "slate-history"
import CommandMenu from "./CommandMenu"
import { SearchOverlay } from "@/presentation/screens/homescreen/components/MarketHeadlines/SearchOverlay"
import { insertHeadline, insertEconomicEvent } from "./utils/editorCommands"
import CalendarSearchOverlay from "./CalendarSearchOverlay"
import { HistoricalAreaChart } from "@/presentation/screens/homescreen/components/EconomicCalendar/EventAnalysisPanel"
import {
  formatValue,
  getCountryFlag,
} from "@/presentation/screens/homescreen/components/EconomicCalendar/EconomicCalendar"
import { ReactEditor } from "slate-react"
import { useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "@tanstack/react-query"
import { useUser } from "@/providers/UserProvider"
import { Dialog } from "@headlessui/react"
import { Resizable } from "re-resizable"
import type { CustomElement, ImageElement } from "./types/slate"
import { toast } from "@/hooks"
import { formatEventDate } from "@/presentation"
import { BackButton } from "../BackButton"

const CONTENT_MAX_WIDTH = "700px"

const withLayout = (editor: any) => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.deleteBackward = (...args: Parameters<typeof deleteBackward>) => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [node] = Editor.node(editor, selection)
      if (Text.isText(node) && node.text === "" && selection.offset === 0) {
        return
      }
    }
    deleteBackward(...args)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {
      const [node] = Editor.node(editor, selection)
      if (SlateElement.isElement(node) && node.type !== "paragraph") {
        Transforms.insertNodes(editor, {
          type: "paragraph",
          children: [{ text: "" }],
        })
        return
      }
    }
    insertBreak()
  }

  editor.normalizeNode = ([node, path]: any) => {
    if (path.length === 0) {
      // Ensure there's always at least one block
      if (node.children.length === 0) {
        const paragraph: SlateElement = {
          type: "paragraph",
          children: [{ text: "" }],
        }
        Transforms.insertNodes(editor, paragraph, { at: [0] })
      }

      // Ensure last node is a paragraph
      const lastChild = node.children[node.children.length - 1]
      if (lastChild && lastChild.type !== "paragraph") {
        const paragraph: SlateElement = {
          type: "paragraph",
          children: [{ text: "" }],
        }
        Transforms.insertNodes(editor, paragraph)
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

interface BlogEditorProps {
  initialContent: any
  postId?: string
  initialTitle: string
  onCancel: () => void
  onEditPublishComplete?: () => void
  onEditDraftComplete?: () => void
  shareUrl?: string
  onCopyLink?: () => void
}

const createPost = async ({ data, token }: { data: any; token: string }) => {
  const response = await fetch(
    `${import.meta.env.VITE_MRKT_SERVER}/research/posts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    },
  )
  if (!response.ok) {
    throw new Error("Failed to save post")
  }
  return response.json()
}

interface ImageElementProps extends RenderElementProps {
  element: ImageElement
}

const ImageElement = ({ attributes, children, element }: ImageElementProps) => {
  const selected = useSelected()
  const focused = useFocused()
  const editor = useSlate()

  const updateSize = (width: string | number, height: string | number) => {
    const path = ReactEditor.findPath(editor, element)
    Transforms.setNodes(editor, { width, height }, { at: path })
  }

  return (
    <div {...attributes}>
      <div contentEditable={false} style={{ margin: "1em auto" }}>
        <Resizable
          size={{
            width: element.width || "100%",
            height: element.height || "auto",
          }}
          onResizeStop={(e, direction, ref, d) => {
            updateSize(ref.style.width, ref.style.height)
          }}
          enable={{
            top: false,
            right: true,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: true,
            bottomLeft: false,
            topLeft: false,
          }}
          handleStyles={{
            right: { right: -12 },
            bottom: { bottom: -12 },
            bottomRight: { bottom: -12, right: -12 },
          }}
          handleClasses={{
            right: "resize-handle",
            bottom: "resize-handle",
            bottomRight: "resize-handle",
          }}
        >
          <div
            style={{
              position: "relative",
              boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
            }}
          >
            <img
              src={element.url}
              alt={element.alt}
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "4px",
              }}
            />
          </div>
        </Resizable>
      </div>
      {children}
    </div>
  )
}

const BlogEditor: React.FC<BlogEditorProps> = ({
  initialContent,
  postId,
  initialTitle,
  onCancel,
  onEditPublishComplete,
  onEditDraftComplete,
  shareUrl,
  onCopyLink,
}) => {
  const [value, setValue] = useState<Descendant[]>(
    initialContent || initialValue,
  )
  const [showCommandMenu, setShowCommandMenu] = useState(false)
  const [commandMenuPosition, setCommandMenuPosition] = useState({ x: 0, y: 0 })
  const [showHeadlineSearch, setShowHeadlineSearch] = useState(false)
  const [showCalendarSearch, setShowCalendarSearch] = useState(false)
  const [title, setTitle] = useState(initialTitle || "")
  const [showImageDialog, setShowImageDialog] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  const [imageAlt, setImageAlt] = useState("")
  const [isPublished, setIsPublished] = useState(false)
  const [description, setDescription] = useState("")
  const editor = useMemo(
    () => withLayout(withHistory(withReact(createEditor()))),
    [],
  )
  const { userData } = useUser()
  const navigate = useNavigate()
  const { getAccessTokenSilently, user } = useAuth0()

  if (!userData?.userName) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">
            Username Required
          </h2>
          <p className="text-gray-400 mb-6">
            Please set up your username before creating a post
          </p>
          <button
            onClick={() => navigate({ to: "/settings" })}
            className="px-6 py-3 bg-blue-600 text-white rounded hover:bg-blue-500"
          >
            Set Up Username
          </button>
        </div>
      </div>
    )
  }

  const { mutateAsync: savePost, isPending: isSaving } = useMutation({
    mutationFn: async (status: "draft" | "published") => {
      const token = await getAccessTokenSilently()
      return createPost({
        data: {
          id: postId,
          title,
          content: value,
          status,
          description,
          tags: [],
          userId: user?.sub,
          author: userData?.userName,
        },
        token,
      })
    },
    onSuccess: (savedPost, status) => {
      if (status === "published") {
        toast({
          title: "Published!",
          description: "Your post has been published successfully",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
        navigate({ to: `/research/${savedPost.author}/${savedPost.slug}` })
        onEditPublishComplete?.()
      } else {
        toast({
          title: "Saved!",
          description: "Private research saved successfully",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
        navigate({ to: `/research/${savedPost.author}` })
        onEditDraftComplete?.()
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to save post",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
  })

  const renderElement = useCallback((props: RenderElementProps) => {
    const baseStyle = { color: "white", maxWidth: "700px", margin: "0 auto" }
    switch (props.element.type) {
      case "heading-one":
        return (
          <h1
            {...props.attributes}
            style={{
              ...baseStyle,
              fontSize: "2.5em",
              fontWeight: "600",
              marginTop: "1em",
              marginBottom: "0.3em",
              letterSpacing: "-0.02em",
            }}
          >
            {props.children}
          </h1>
        )
      case "heading-two":
        return (
          <h2
            {...props.attributes}
            style={{
              ...baseStyle,
              fontSize: "1.875em",
              fontWeight: "600",
              marginTop: "0.8em",
              marginBottom: "0.3em",
              letterSpacing: "-0.02em",
            }}
          >
            {props.children}
          </h2>
        )
      case "heading-three":
        return (
          <h3
            {...props.attributes}
            style={{
              ...baseStyle,
              fontSize: "1.5em",
              fontWeight: "600",
              marginTop: "0.8em",
              marginBottom: "0.3em",
            }}
          >
            {props.children}
          </h3>
        )
      case "image":
        return <ImageElement {...(props as ImageElementProps)} />
      case "headline":
        const { headlineData } = props.element
        if (!headlineData) return <p {...props.attributes}>{props.children}</p>
        return (
          <div
            {...props.attributes}
            style={{
              ...baseStyle,
              margin: "2em auto",
            }}
            className="group relative pl-2"
          >
            <div className="absolute left-[10px] top-[22px] w-[11px] h-[11px] rounded-full bg-purple-400/80 ring-4 ring-black group-hover:ring-zinc-800/50 transition-all duration-200" />
            <div className="ml-6 p-5 rounded-lg bg-zinc-900/50 border border-zinc-800/50 hover:bg-zinc-800/50 hover:border-zinc-700/80 transition-all duration-200">
              <div className="text-sm text-zinc-400 mb-2.5">
                {headlineData.time}
              </div>
              <div className="text-white text-lg font-medium leading-relaxed">
                {headlineData.title}
              </div>
              {props.children}
            </div>
          </div>
        )
      case "economic-event":
        const { eventData } = props.element
        if (!eventData) return <p {...props.attributes}>{props.children}</p>
        return (
          <div
            {...props.attributes}
            style={{
              ...baseStyle,
              padding: "1em",
              margin: "1em auto",
              backgroundColor: "#111",
              borderRadius: "8px",
              border: "1px solid #333",
            }}
          >
            <div className="flex items-center gap-2 mb-2">
              <span className="text-gray-400 text-sm">
                {formatEventDate(eventData.dateUtc)}
              </span>
              <span className="text-gray-400">•</span>
              <span className="text-gray-400 text-sm">
                {getCountryFlag(eventData.countryCode)}
              </span>
            </div>
            <div className="text-white text-lg mb-2">{eventData.name}</div>
            <div className="grid grid-cols-3 gap-4 text-sm">
              <div>
                <span className="text-gray-400">Actual: </span>
                <span className="text-white">
                  {formatValue(
                    eventData.actual === null ? null : Number(eventData.actual),
                    eventData.unit,
                    eventData.potency,
                  )}
                </span>
              </div>
              <div>
                <span className="text-gray-400">Consensus: </span>
                <span className="text-white">
                  {formatValue(
                    eventData.consensus === null
                      ? null
                      : Number(eventData.consensus),
                    eventData.unit,
                    eventData.potency,
                  )}
                </span>
              </div>
              <div>
                <span className="text-gray-400">Previous: </span>
                <span className="text-white">
                  {formatValue(
                    eventData.previous === null
                      ? null
                      : Number(eventData.previous),
                    eventData.unit,
                    eventData.potency,
                  )}
                </span>
              </div>
            </div>
            {eventData.historicalData && (
              <div className="mt-4">
                <HistoricalAreaChart
                  data={eventData.historicalData.filter(
                    (item) => item.actual !== null,
                  )}
                  unit={eventData.unit}
                  potency={eventData.potency}
                />
              </div>
            )}
            {props.children}
          </div>
        )
      default:
        return (
          <p
            {...props.attributes}
            style={{
              ...baseStyle,
              fontSize: "1em",
              lineHeight: "1.7",
              marginBottom: "0.5em",
              color: "#e0e0e0",
              fontWeight: "normal",
            }}
          >
            {props.children}
          </p>
        )
    }
  }, [])

  const renderLeaf = useCallback((props: any) => {
    let { attributes, children, leaf } = props

    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    return <span {...attributes}>{children}</span>
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "/" && !showCommandMenu) {
        event.preventDefault()

        const { selection } = editor
        if (selection) {
          // Get the DOM node and its position
          const domSelection = window.getSelection()
          if (domSelection && domSelection.rangeCount > 0) {
            const range = domSelection.getRangeAt(0)
            const rect = range.getBoundingClientRect()

            // Calculate position relative to viewport
            setCommandMenuPosition({
              x: rect.left,
              y: rect.top + rect.height + window.scrollY,
            })
            setShowCommandMenu(true)
          }
        }
      }

      if (event.key === "Escape" && showCommandMenu) {
        event.preventDefault()
        setShowCommandMenu(false)
      }
    },
    [showCommandMenu, editor],
  )

  const handleImageSubmit = () => {
    if (imageUrl) {
      Transforms.insertNodes(editor, {
        type: "image",
        url: imageUrl,
        alt: imageAlt,
        width: "100%",
        height: "auto",
        children: [{ text: "" }],
      })
      setImageUrl("")
      setImageAlt("")
      setShowImageDialog(false)
    }
  }

  const handlePublish = async () => {
    try {
      await savePost("published")
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to publish post",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  const handleSaveDraft = async () => {
    try {
      await savePost("draft")
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save private research",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  return (
    <div
      className="blog-editor"
      style={{
        padding: "40px 20px",
        maxWidth: "1000px",
        margin: "0 auto",
        minHeight: "100vh",
      }}
    >
      <div className="px-8 pt-8 mb-8 flex justify-between items-center">
        <BackButton />
        <div className="flex gap-2">
          <button
            onClick={handleSaveDraft}
            disabled={isSaving}
            className="px-4 py-2 text-sm font-medium bg-zinc-800 text-zinc-100 rounded-md hover:bg-zinc-700 disabled:opacity-50 transition-colors"
          >
            {isSaving ? "Saving..." : "Save as Private"}
          </button>
          <button
            onClick={handlePublish}
            disabled={isSaving}
            className="px-4 py-2 text-sm font-medium bg-zinc-800 text-white rounded-md hover:bg-zinc-600 disabled:opacity-50 transition-colors"
          >
            {isSaving ? "Publishing..." : "Publish"}
          </button>
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium bg-zinc-800 text-zinc-100 rounded-md hover:bg-zinc-700 disabled:opacity-50 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className="mb-8"
        style={{ maxWidth: CONTENT_MAX_WIDTH, margin: "0 auto" }}
      >
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Post title..."
          className="w-full bg-transparent text-4xl font-medium text-white border-none outline-none mb-4 font-inter"
          style={{
            fontSize: "2.5em",
            marginBottom: "0.5em",
          }}
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a brief description for research preview..."
          className="w-full bg-transparent text-lg text-gray-300 border-none outline-none mb-4 font-inter"
          style={{
            fontSize: "1.1em",
            marginBottom: "1em",
          }}
        />
      </div>

      <Slate editor={editor} initialValue={value} onChange={setValue}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Type '/' for commands..."
          onKeyDown={handleKeyDown}
          style={{
            minHeight: "calc(100vh - 80px)",
            padding: "0 20px",
            color: "white",
            backgroundColor: "transparent",
            fontSize: "1.125em",
            lineHeight: "1.7",
            outline: "none",
          }}
        />
        <CommandMenu
          editor={editor}
          isOpen={showCommandMenu}
          onClose={() => setShowCommandMenu(false)}
          position={commandMenuPosition}
          setShowHeadlineSearch={setShowHeadlineSearch}
          setShowCalendarSearch={setShowCalendarSearch}
          setShowImageDialog={setShowImageDialog}
        />
      </Slate>

      {showHeadlineSearch && (
        <SearchOverlay
          isOpen={showHeadlineSearch}
          onClose={() => setShowHeadlineSearch(false)}
          highlightEnabled={false}
          onHeadlineSelect={(headline) => {
            insertHeadline(editor, {
              title: headline.title,
              time: headline.time,
              relativeTime: headline.relativeTime,
            })
            setShowHeadlineSearch(false)
          }}
        />
      )}

      {showCalendarSearch && (
        <CalendarSearchOverlay
          isOpen={showCalendarSearch}
          onClose={() => setShowCalendarSearch(false)}
          onEventSelect={(event) => {
            insertEconomicEvent(editor, event)
            setShowCalendarSearch(false)
          }}
        />
      )}

      {showImageDialog && (
        <Dialog
          open={showImageDialog}
          onClose={() => setShowImageDialog(false)}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="relative bg-zinc-900/90 backdrop-blur-sm border border-zinc-800 rounded-xl p-6 w-full max-w-md mx-4 shadow-xl">
              <Dialog.Title className="text-xl font-semibold text-white mb-6">
                Insert TradingView Chart
              </Dialog.Title>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-zinc-300 mb-2">
                    Chart URL
                  </label>
                  <input
                    type="url"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    placeholder="https://tradingview.com/chart?symbol=NASDAQ%3ABAC"
                    className="w-full px-4 py-2.5 bg-zinc-800/50 border border-zinc-700 rounded-lg text-white placeholder-zinc-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-zinc-300 mb-2">
                    Description (Optional)
                  </label>
                  <input
                    type="text"
                    value={imageAlt}
                    onChange={(e) => setImageAlt(e.target.value)}
                    placeholder="Brief description of the chart"
                    className="w-full px-4 py-2.5 bg-zinc-800/50 border border-zinc-700 rounded-lg text-white placeholder-zinc-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500"
                  />
                </div>

                <div className="flex justify-end gap-3 mt-8">
                  <button
                    onClick={() => setShowImageDialog(false)}
                    className="px-4 py-2 text-sm font-medium text-zinc-300 hover:text-white transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleImageSubmit}
                    disabled={!imageUrl}
                    className="px-4 py-2 text-sm font-medium bg-zinc-700 text-white rounded-lg hover:bg-zinc-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    Insert Chart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}

      <div className="flex justify-end gap-4 mt-4">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={handlePublish}>Publish</button>
      </div>

      {isPublished && shareUrl && (
        <div className="mt-4 flex items-center gap-2 bg-zinc-900 p-4 rounded-lg">
          <input
            type="text"
            value={shareUrl}
            readOnly
            className="flex-1 bg-zinc-800 px-3 py-2 rounded text-white"
          />
          <button
            onClick={onCopyLink}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center gap-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
              <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
            </svg>
            Copy Link
          </button>
        </div>
      )}
    </div>
  )
}

export default BlogEditor
