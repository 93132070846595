import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useParams, Outlet } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"
import { Navbar } from "@/components/Navbar"
import { useUser } from "@/providers"
import { useState } from "react"
import { Dialog } from "@headlessui/react"
import { toast } from "@/hooks/use-toast"
import { BackButton } from "@/components/BackButton"
import { useAuth0 } from "@auth0/auth0-react"

interface Post {
  _id: string
  title: string
  description: string
  slug: string
  author: string
  createdAt: string
  status: "draft" | "published"
}

function EmptyState({ message }: { message: string }) {
  return (
    <div className="text-center py-12">
      <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-zinc-800/50 mb-4">
        <svg
          className="w-6 h-6 text-zinc-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
      </div>
      <p className="text-zinc-400 text-lg">{message}</p>
    </div>
  )
}

function DeleteConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  postType,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  postType: "published" | "draft"
}) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-zinc-900 rounded-lg p-6 max-w-sm w-full">
          <Dialog.Title className="text-xl font-medium text-white mb-2">
            Delete {postType}
          </Dialog.Title>
          <Dialog.Description className="text-zinc-400 mb-6">
            Are you sure you want to delete this {postType}? This action cannot
            be undone.
          </Dialog.Description>

          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-zinc-400 hover:text-white transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onConfirm()
                onClose()
              }}
              className="px-4 py-2 text-sm font-medium bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
            >
              Delete
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

function AuthorPosts() {
  const [activeTab, setActiveTab] = useState<"published" | "drafts" | "likes">(
    new URLSearchParams(window.location.search).get("tab") === "likes"
      ? "likes"
      : "published",
  )

  const { author } = useParams({ from: "/research/$author" })
  const navigate = useNavigate()
  const { userData } = useUser()
  const isAuthor = userData?.userName === author

  const { getAccessTokenSilently } = useAuth0()

  const isExactPath = window.location.pathname === `/research/${author}`

  const publishedPostsQuery = useQuery({
    queryKey: ["author-posts", author, "published"],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts/${author}?status=published`,
      )
      if (!response.ok) {
        return [] // Return empty array on 404
      }
      return response.json() as Promise<Post[]>
    },
    enabled: activeTab === "published",
    retry: false,
  })

  const draftPostsQuery = useQuery({
    queryKey: ["author-posts", author, "drafts"],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts/${author}?status=draft`,
      )
      if (!response.ok) {
        return [] // Return empty array on 404
      }
      return response.json() as Promise<Post[]>
    },
    enabled: isAuthor && activeTab === "drafts",
    retry: false,
  })

  const likedPostsQuery = useQuery({
    queryKey: ["author-liked-posts", author],
    queryFn: async () => {
      if (!userData?.likedPosts?.length) return []

      const queryParams = new URLSearchParams({
        ids: userData.likedPosts.join(","),
      })

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts-by-id?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch liked posts")
      }
      return response.json() as Promise<Post[]>
    },
    enabled: activeTab === "likes" && isAuthor,
    retry: false,
  })

  const queryClient = useQueryClient()

  const deletePostMutation = useMutation({
    mutationFn: async ({ id, author }: { id: string; author: string }) => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ author }),
        },
      )
      if (!response.ok) {
        throw new Error("Failed to delete post")
      }
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["author-posts"] })
      toast({
        title: "Research deleted successfully",
        variant: "default",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
    onError: (error) => {
      toast({
        title: "Failed to delete research",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
      console.error("Delete error:", error)
    },
  })

  const [deleteModalState, setDeleteModalState] = useState<{
    isOpen: boolean
    postId: string | null
    postType: "published" | "draft"
  }>({
    isOpen: false,
    postId: null,
    postType: "published",
  })

  const handleDelete = () => {
    if (deleteModalState.postId) {
      deletePostMutation.mutate({
        id: deleteModalState.postId,
        author,
      })
    }
  }

  if (!isExactPath) {
    return <Outlet />
  }

  const LoadingPosts = () => (
    <div className="animate-pulse space-y-8">
      {[1, 2].map((i) => (
        <div key={`loading-${i}`} className="space-y-3">
          <div className="h-6 bg-zinc-800/50 rounded w-3/4" />
          <div className="h-4 bg-zinc-800/50 rounded w-1/2" />
          <div className="h-24 bg-zinc-800/50 rounded-lg" />
        </div>
      ))}
    </div>
  )

  const isLoading =
    (activeTab === "published" && publishedPostsQuery.isLoading) ||
    (activeTab === "drafts" && draftPostsQuery.isLoading) ||
    (activeTab === "likes" && likedPostsQuery.isLoading)

  return (
    <div className="min-h-screen bg-black">
      <DeleteConfirmationModal
        isOpen={deleteModalState.isOpen}
        onClose={() =>
          setDeleteModalState({
            isOpen: false,
            postId: null,
            postType: "published",
          })
        }
        onConfirm={handleDelete}
        postType={deleteModalState.postType}
      />
      <Navbar />
      <div className="py-16 px-12">
        <div className="max-w-4xl mx-auto">
          <div className="mb-8">
            <BackButton />
          </div>
          <header className="mb-12">
            <div className="flex items-center gap-4 mb-8">
              <div className="h-12 w-12 rounded-full bg-zinc-800 flex items-center justify-center">
                <span className="text-lg font-medium text-white">
                  {author[0].toUpperCase()}
                </span>
              </div>
              <div>
                <h1 className="text-3xl font-semibold text-white">
                  Market Research
                </h1>
                <p className="text-zinc-400 mt-1">by {author}</p>
              </div>
            </div>
            {isAuthor && (
              <div className="flex space-x-4 mb-8">
                <button
                  onClick={() => setActiveTab("published")}
                  className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    activeTab === "published"
                      ? "bg-zinc-800 text-white"
                      : "text-zinc-400 hover:text-white"
                  }`}
                >
                  Published
                </button>
                <button
                  onClick={() => setActiveTab("drafts")}
                  className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    activeTab === "drafts"
                      ? "bg-zinc-800 text-white"
                      : "text-zinc-400 hover:text-white"
                  }`}
                >
                  Private
                </button>
                <button
                  onClick={() => setActiveTab("likes")}
                  className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    activeTab === "likes"
                      ? "bg-zinc-800 text-white"
                      : "text-zinc-400 hover:text-white"
                  }`}
                >
                  Likes
                </button>
              </div>
            )}
            <div className="h-px bg-zinc-800 w-full" />
          </header>

          {activeTab === "published" && (
            <div className="space-y-12">
              {isLoading ? (
                <LoadingPosts />
              ) : publishedPostsQuery.data?.length ? (
                publishedPostsQuery.data.map((post) => (
                  <div
                    key={post._id}
                    onClick={() => {
                      navigate({
                        to: "/research/$author/$slug",
                        params: {
                          author: post.author,
                          slug: post.slug,
                        },
                      })
                    }}
                    className="block group hover:cursor-pointer"
                  >
                    <article className="space-y-4">
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <time className="text-sm text-zinc-500">
                            {new Date(post.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              },
                            )}
                          </time>
                          {isAuthor && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation()
                                setDeleteModalState({
                                  isOpen: true,
                                  postId: post._id,
                                  postType: "published",
                                })
                              }}
                              className="p-2 text-zinc-500 hover:text-red-500 transition-colors"
                            >
                              <svg
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                        <h2 className="text-2xl font-medium text-white group-hover:text-zinc-200 transition-colors">
                          {post.title}
                        </h2>
                      </div>
                      <p className="text-zinc-400 line-clamp-2 text-lg">
                        {post.description}
                      </p>
                      <div className="flex items-center gap-2 text-sm text-zinc-500 group-hover:text-zinc-400 transition-colors">
                        <span>Read article</span>
                        <svg
                          className="w-4 h-4 group-hover:translate-x-0.5 transition-transform"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </article>
                    <div className="h-px bg-zinc-800/50 w-full mt-12" />
                  </div>
                ))
              ) : (
                <EmptyState
                  message={`${author} hasn't published any market research yet.`}
                />
              )}
            </div>
          )}

          {activeTab === "drafts" && isAuthor && (
            <div className="space-y-12">
              {isLoading ? (
                <LoadingPosts />
              ) : draftPostsQuery.data?.length ? (
                draftPostsQuery.data.map((post) => (
                  <div
                    key={post._id}
                    onClick={() => {
                      navigate({
                        to: "/research/$author/$slug",
                        params: {
                          author: post.author,
                          slug: post.slug,
                        },
                      })
                    }}
                    className="block group hover:cursor-pointer"
                  >
                    <article className="space-y-4">
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3">
                            <time className="text-sm text-zinc-500">
                              {new Date(post.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                },
                              )}
                            </time>
                            <span className="px-2 py-0.5 text-xs font-medium bg-zinc-800 text-zinc-300 rounded">
                              Private
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              setDeleteModalState({
                                isOpen: true,
                                postId: post._id,
                                postType: "draft",
                              })
                            }}
                            className="p-2 text-zinc-500 hover:text-red-500 transition-colors"
                          >
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        </div>
                        <h2 className="text-2xl font-medium text-white group-hover:text-zinc-200 transition-colors">
                          {post.title}
                        </h2>
                      </div>
                      <p className="text-zinc-400 line-clamp-2 text-lg">
                        {post.description}
                      </p>
                      <div className="flex items-center gap-2 text-sm text-zinc-500 group-hover:text-zinc-400 transition-colors">
                        <span>Continue editing</span>
                        <svg
                          className="w-4 h-4 group-hover:translate-x-0.5 transition-transform"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </article>
                    <div className="h-px bg-zinc-800/50 w-full mt-12" />
                  </div>
                ))
              ) : (
                <EmptyState message="You don't have any draft posts yet." />
              )}
            </div>
          )}

          {activeTab === "likes" && isAuthor && (
            <div className="space-y-12">
              {isLoading ? (
                <LoadingPosts />
              ) : likedPostsQuery.data?.length ? (
                [...likedPostsQuery.data].reverse().map((post) => (
                  <div
                    key={post._id}
                    onClick={() => {
                      navigate({
                        to: "/research/$author/$slug",
                        params: {
                          author: post.author,
                          slug: post.slug,
                        },
                      })
                    }}
                    className="block group hover:cursor-pointer"
                  >
                    <article className="space-y-4">
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3">
                            <time className="text-sm text-zinc-500">
                              {new Date(post.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                },
                              )}
                            </time>
                            <span className="text-sm text-zinc-500">
                              by {post.author}
                            </span>
                          </div>
                          <div className="flex items-center text-pink-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 fill-current"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                              />
                            </svg>
                          </div>
                        </div>
                        <h2 className="text-2xl font-medium text-white group-hover:text-zinc-200 transition-colors">
                          {post.title}
                        </h2>
                      </div>
                      <p className="text-zinc-400 line-clamp-2 text-lg">
                        {post.description}
                      </p>
                      <div className="flex items-center gap-2 text-sm text-zinc-500 group-hover:text-zinc-400 transition-colors">
                        <span>Read article</span>
                        <svg
                          className="w-4 h-4 group-hover:translate-x-0.5 transition-transform"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </article>
                    <div className="h-px bg-zinc-800/50 w-full mt-12" />
                  </div>
                ))
              ) : (
                <EmptyState message="You haven't liked any posts yet." />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const Route = createFileRoute("/research/$author")({
  component: AuthorPosts,
})

export default AuthorPosts
