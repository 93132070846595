import { createFileRoute } from "@tanstack/react-router"
import { useState, useEffect } from "react"
import { clsx } from "clsx"
import { Navbar } from "@/components/Navbar"
import { useQuery, useMutation } from "@tanstack/react-query"
import { format, subMonths, formatDistance } from "date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { HistoricalAreaChart } from "@/presentation/screens/homescreen/components/EconomicCalendar/EventAnalysisPanel"
import { GlobalEconomyAnalysisPanel } from "@/presentation/screens/homescreen/components/GlobalEconomy/GlobalEconomyAnalysisPanel"
import { AnimatePresence } from "framer-motion"
import { Sparkles } from "lucide-react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import { Tab } from "@headlessui/react"
import { AIGenerateButton } from "@/components/ui/AIGenerateButton"

const tabs = [
  { id: "us", label: "US" },
  { id: "euro", label: "Euro Zone" },
  { id: "uk", label: "UK" },
  { id: "japan", label: "Japan" },
  { id: "china", label: "China" },
  { id: "germany", label: "Germany" },
  { id: "france", label: "France" },
  { id: "spain", label: "Spain" },
  { id: "india", label: "India" },
  { id: "australia", label: "Australia" },
  { id: "newzealand", label: "New Zealand" },
  { id: "russia", label: "Russia" },
]

type EventKey =
  | "manufacturing_pmi"
  | "services_pmi"
  | "composite_pmi"
  | "cpi"
  | "unemployment"
  | "interest_rate"
  | "gdp"
  | "retail_sales"
  | "consumer_confidence"
  | "money_supply"
  | "ppi"

const REGIONS: Record<
  string,
  {
    name: string
    events: Partial<
      Record<
        EventKey,
        {
          id: string
          name: string
        }
      >
    >
  }
> = {
  US: {
    name: "United States",
    events: {
      interest_rate: {
        id: "fcfae951-09a7-449e-b6fe-525e1335aaba",
        name: "Interest Rate",
      },
      cpi: {
        id: "6f846eaa-9a12-43ab-930d-f059069c6646",
        name: "CPI",
      },
      unemployment: {
        id: "932c21fa-f664-40e1-a921-dbeb452f0081",
        name: "Unemployment Rate",
      },
      gdp: {
        id: "5f64264e-5097-4359-b60f-fb9b01229068",
        name: "GDP",
      },
      consumer_confidence: {
        id: "608ffc81-99e8-4b1c-b673-633100761034",
        name: "Consumer Sentiment Index",
      },
      retail_sales: {
        id: "31b216da-2502-4428-af5b-d3c54b68ebe4",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "2e1d69f3-8273-4096-b01b-8d2034d4fade",
        name: "ISM Manufacturing PMI",
      },
      services_pmi: {
        id: "6c5853c1-a409-4722-bdea-17ad5d8a193f",
        name: "ISM Services PMI",
      },
      composite_pmi: {
        id: "ac172f6c-6b25-4a45-a986-c96789740c8b",
        name: "S&P Global Composite PMI",
      },
    },
  },
  JP: {
    name: "Japan",
    events: {
      interest_rate: {
        id: "8b3a0864-3ad8-4510-88a5-acaf0dc44ce0",
        name: "Interest Rate",
      },
      cpi: {
        id: "7f15ca74-c327-4eb6-b76f-d0783f9b14e5",
        name: "CPI",
      },
      unemployment: {
        id: "b892c6ef-e1d0-4944-9ee1-e28f10a0a335",
        name: "Unemployment Rate",
      },
      gdp: {
        id: "6ca644c2-2423-4b0a-afac-be3190415639",
        name: "GDP",
      },
      consumer_confidence: {
        id: "55b5513a-49fa-4cdd-a699-90984f820a54",
        name: "Consumer Confidence Index",
      },
      retail_sales: {
        id: "fe4ccb8d-9b5f-4e5f-9ba2-431284ba8078",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "59861198-636b-4006-88be-addbaed5fb9b",
        name: "Jibun Bank Manufacturing PMI",
      },
      services_pmi: {
        id: "cb7aed00-7237-40fd-8149-a0f84b67a3c4",
        name: "Jibun Bank Services PMI",
      },
    },
  },
  UK: {
    name: "United Kingdom",
    events: {
      interest_rate: {
        id: "9959649d-be62-47f5-bafa-ff2f474b9e13",
        name: "Interest Rate",
      },
      cpi: {
        id: "3ac4e096-06c8-4981-b973-622269563b1f",
        name: "CPI",
      },
      unemployment: {
        id: "98bb2374-b9f9-46ae-93e3-9f7e8a4391c1",
        name: "ILO Unemployment Rate",
      },
      gdp: {
        id: "c8e75081-4d7e-4ca3-b29b-968f71a5d480",
        name: "GDP",
      },
      consumer_confidence: {
        id: "86ab275d-83f3-4760-a9f3-3a51f842029f",
        name: "Consumer Confidence Index",
      },
      retail_sales: {
        id: "febce648-9291-4b5a-9ab7-c09f7f6c7bfe",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "f21a55ef-8d4b-4970-8f7e-0100bae77407",
        name: "S&P Global/CIPS Manufacturing PMI",
      },
      services_pmi: {
        id: "0fbc81b5-a029-4c0e-a5c7-9baf1fc47cd2",
        name: "S&P Global/CIPS Services PMI",
      },
      composite_pmi: {
        id: "26d1175b-7b3c-4914-b78a-c5c283eefbcf",
        name: "S&P Global/CIPS Composite PMI",
      },
    },
  },
  EU: {
    name: "European Union",
    events: {
      interest_rate: {
        id: "8beb52bf-2294-407c-b979-8e383584a233",
        name: "Interest Rate",
      },
      cpi: {
        id: "54334a3a-4347-40bc-8755-78f6f4ae4b9a",
        name: "CPI",
      },
      unemployment: {
        id: "40c78644-5d06-4d87-ba5c-f2e2691cc577",
        name: "Unemployment Rate",
      },
      gdp: {
        id: "5ffba5b4-686d-4a65-ad51-818220c41590",
        name: "GDP",
      },
      consumer_confidence: {
        id: "1f448283-3319-4405-aebb-3dee56d30b25",
        name: "Consumer Confidence",
      },
      retail_sales: {
        id: "78a4364e-21c3-4d6d-ba5d-30d6e5d22682",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "b03fa53b-f5c1-4ff9-8fc6-49cd848f3d1c",
        name: "HCOB Manufacturing PMI",
      },
      services_pmi: {
        id: "d693560c-1a96-4409-a9d8-5979a23eaca4",
        name: "HCOB Services PMI",
      },
      composite_pmi: {
        id: "a738eb19-a948-4d02-b644-fad25576c613",
        name: "HCOB Composite PMI",
      },
    },
  },
  CN: {
    name: "China",
    events: {
      interest_rate: {
        id: "fa510216-a58a-4dba-93f7-0c05c1e5e3d1",
        name: "Interest Rate",
      },
      cpi: {
        id: "29d28158-f0f3-47af-9d86-f2494426395a",
        name: "CPI",
      },
      ppi: {
        id: "6a4a75b9-c05d-489c-abca-5970a2a03056",
        name: "PPI",
      },
      gdp: {
        id: "1251e0d5-5f55-4aaa-990a-23927418adcf",
        name: "GDP",
      },
      money_supply: {
        id: "d24a41c7-20d2-4db5-943a-ca0609f39ac0",
        name: "M2 Money Supply",
      },
      retail_sales: {
        id: "2b1b973d-3280-48cc-8c71-d9d6bad2e859",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "80b0adcf-cfa9-4583-9d3a-f720a4a3f5fa",
        name: "Caixin Manufacturing PMI",
      },
      services_pmi: {
        id: "65e6efe8-5058-4f51-a061-a42ac5e93f36",
        name: "Caixin Services PMI",
      },
    },
  },
  IN: {
    name: "India",
    events: {
      interest_rate: {
        id: "48f164bb-f870-45e0-9cae-34e497ca03f9",
        name: "Interest Rate",
      },
      cpi: {
        id: "2b4bbc2f-3a80-47b6-9784-7dcb476ab170",
        name: "CPI",
      },
      gdp: {
        id: "7aad3a75-a76c-47ed-807f-47dc5605b89a",
        name: "GDP",
      },
      manufacturing_pmi: {
        id: "90cb7570-37d9-41fd-9278-1a3b6d4dd1c9",
        name: "HSBC Manufacturing PMI",
      },
      services_pmi: {
        id: "22028f37-4909-4987-b851-1b37052de84e",
        name: "HSBC Services PMI",
      },
      composite_pmi: {
        id: "ab8f3a62-1f1f-47f0-8fcd-444b7ea2c3eb",
        name: "HSBC Composite PMI",
      },
      money_supply: {
        id: "4624d3de-50e0-43be-8e7e-cd14e6a9cc84",
        name: "M3 Money Supply",
      },
    },
  },
  AU: {
    name: "Australia",
    events: {
      interest_rate: {
        id: "97e8d83b-a333-4be6-8cf9-1f4fa9f9789d",
        name: "Interest Rate",
      },
      cpi: {
        id: "e39cdc9f-802d-40ea-ac1f-60a1862e8ee9",
        name: "CPI",
      },
      unemployment: {
        id: "7bf5a806-92d7-4d18-95a4-e10c42b1eeba",
        name: "Unemployment Rate",
      },
      gdp: {
        id: "f13e06f8-cbaa-4d92-b375-568b0d6bb756",
        name: "GDP",
      },
      retail_sales: {
        id: "8f24e686-4fa6-45a1-97ed-02c8c3a5362e",
        name: "Retail Sales",
      },
      consumer_confidence: {
        id: "51e5f825-7fe5-497c-9199-f783ae1fade9",
        name: "Westpac Consumer Confidence",
      },
      services_pmi: {
        id: "9b505ff1-dc46-4f81-b5a3-06a908aeb19d",
        name: "Judo Bank Services PMI",
      },
      manufacturing_pmi: {
        id: "3766e161-8431-4a94-8a37-1257784bba6c",
        name: "Judo Bank Manufacturing PMI",
      },
    },
  },
  DE: {
    name: "Germany",
    events: {
      retail_sales: {
        id: "729e8791-c2f6-4f8f-8567-29dfb8cdfaa7",
        name: "Retail Sales",
      },
      manufacturing_pmi: {
        id: "6083b607-13b0-4f1d-9522-4c3461be2b96",
        name: "HCOB Manufacturing PMI",
      },
      services_pmi: {
        id: "a36bc751-eba3-4262-ad6f-4d3ec2c182e8",
        name: "HCOB Services PMI",
      },
      composite_pmi: {
        id: "891ff107-c21f-4952-b749-6d8c17944f60",
        name: "HCOB Composite PMI",
      },
      cpi: {
        id: "13cd64fa-9914-4aef-ae7d-4471843e57b4",
        name: "CPI",
      },
      unemployment: {
        id: "c5270c56-b089-488e-97d8-13d47fec774f",
        name: "Unemployment Rate",
      },
      gdp: {
        id: "ce2654a8-b444-4f35-b559-cbbca52c1aec",
        name: "GDP",
      },
      ppi: {
        id: "6c7aaf26-837f-482f-8482-cc95756a3627",
        name: "PPI",
      },
    },
  },
  FR: {
    name: "France",
    events: {
      manufacturing_pmi: {
        id: "490e9f4a-4a34-46ee-aed4-90e3b1b51cb7",
        name: "HCOB Manufacturing PMI",
      },
      services_pmi: {
        id: "c73b6b13-4fb9-42c6-8e56-cd71ad4e5a47",
        name: "HCOB Services PMI",
      },
      cpi: {
        id: "986a4278-f6b4-4082-9f93-aa7853daf466",
        name: "CPI",
      },
      consumer_confidence: {
        id: "14f1b15a-be7c-4e1a-8983-bfa41a06bc31",
        name: "Consumer Confidence",
      },
    },
  },
  ES: {
    name: "Spain",
    events: {
      cpi: {
        id: "ccfc1b3a-a9e8-47c0-9093-de089e302351",
        name: "CPI",
      },
      gdp: {
        id: "4dfc0cfe-6d95-4ee0-9f48-0f20f4a2a3f0",
        name: "GDP",
      },
      manufacturing_pmi: {
        id: "e02e91f5-a42a-440a-bd46-f33d4828f7ea",
        name: "HCOB Manufacturing PMI",
      },
      services_pmi: {
        id: "a26781f0-50cd-4a99-80f2-f6b1d38374d8",
        name: "HCOB Services PMI",
      },
      retail_sales: {
        id: "5d7cf212-a79c-4564-a456-6e18c8a7603e",
        name: "Retail Sales",
      },
    },
  },
  NZ: {
    name: "New Zealand",
    events: {
      unemployment: {
        id: "7bdda5c5-3034-429e-b8c0-bf331d513b8b",
        name: "Unemployment Rate",
      },
      interest_rate: {
        id: "af0112f8-5b85-4b01-84cc-bd4b13f1e9d0",
        name: "Interest Rate",
      },
      gdp: {
        id: "565fd792-e442-4cf4-9c93-2121e1d53760",
        name: "GDP",
      },
      cpi: {
        id: "ea25453a-3b4e-4df7-9825-48c6ccdc283b",
        name: "CPI",
      },
    },
  },
  RU: {
    name: "Russia",
    events: {
      manufacturing_pmi: {
        id: "8d43e8ac-68ba-4a60-a6d7-b5a43a41fac5",
        name: "S&P Manufacturing PMI",
      },
      services_pmi: {
        id: "0bd71f34-94c8-40cc-9b44-42824dffb48b",
        name: "S&P Services PMI",
      },
      unemployment: {
        id: "1576f05f-1e55-46bd-ad1c-3c78e1eece16",
        name: "Unemployment Rate",
      },
      interest_rate: {
        id: "25cd9566-5e9f-4003-9091-c66e5a6f72e9",
        name: "Interest Rate",
      },
    },
  },
}

type HistoricalDataPoint = {
  actual: number
  date: string
  eventId: string
  forecast: number | null
  previous: number | null
}

const prepareAIAnalysisData = (
  regionData: HistoricalDataPoint[][],
  events: Array<{ id: string; name: string }>,
) => {
  const analysisData = events.map((event, index) => {
    const eventData = regionData[index]
    if (!eventData?.length) return null

    // Get last 6 months of data
    const recentReadings = eventData.slice(0, 6).map((point) => ({
      date: point.date,
      actual: point.actual,
      forecast: point.forecast,
    }))

    return {
      indicator: event.name,
      recentReadings,
    }
  })

  return analysisData.filter(
    (data): data is NonNullable<typeof data> => data !== null,
  )
}

type Reading = {
  actual: number
  date: string
  forecast: number | null
}

type Indicator = {
  indicator: string
  recentReadings: Reading[]
}

type AnalyzeRegionRequest = {
  region: string
  data: Indicator[]
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const highlightText = (text: string, keyPoints: string[]) => {
  let highlightedText = text
  keyPoints.forEach((point) => {
    const escapedPoint = escapeRegExp(point)
    const regex = new RegExp(escapedPoint, "gi")
    highlightedText = highlightedText.replace(
      regex,
      `<span class="bg-yellow-500/30 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
    )
  })
  return (
    <p
      className="text-gray-300"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  )
}

const formatValue = (value: number, unit: string, potency: string) => {
  if (unit === "%") {
    return `${value.toFixed(2)}%`
  }
  return value.toFixed(2)
}

const PurpleAreaChart = ({
  data,
  unit,
  potency,
  title,
}: {
  data: any
  unit: string
  potency: string
  title: string
}) => {
  // Sort the data first
  const sortedData = data.sort(
    (a: any, b: any) =>
      new Date(a.dateUtc).getTime() - new Date(b.dateUtc).getTime(),
  )

  // Get the latest value from the sorted data (last item is most recent)
  const latestValue = sortedData[sortedData.length - 1]?.actual

  const chartData = sortedData.map((item: any) => ({
    date: format(new Date(item.dateUtc), "MMM yyyy"),
    actual: item.actual,
  }))

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-lg font-semibold text-gray-200">{title}</h2>
          <div className="flex items-center gap-2">
            <span className="text-lg font-semibold text-white">
              {formatValue(latestValue || 0, unit, potency)}
            </span>
            <span className="text-xs font-medium text-gray-400 bg-white/5 px-2 py-0.5 rounded-md">
              Latest
            </span>
          </div>
        </div>
      </div>
      <div className="w-full h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: -15,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A855F7" stopOpacity={0.15} />
                <stop offset="95%" stopColor="#A855F7" stopOpacity={0.01} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              stroke="#666"
              tick={{ fill: "#666", fontSize: 10 }}
              axisLine={{ stroke: "#333" }}
              tickLine={false}
              dy={5}
            />
            <YAxis
              stroke="#666"
              tick={{ fill: "#666", fontSize: 10 }}
              axisLine={{ stroke: "#333" }}
              tickLine={false}
              tickFormatter={(value) => formatValue(value, unit, potency)}
              dx={-5}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#18181B",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "6px",
                boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.2)",
              }}
              labelStyle={{
                color: "#A1A1AA",
                fontWeight: 500,
                marginBottom: "4px",
              }}
              itemStyle={{ color: "#fff", fontSize: "12px", padding: "2px 0" }}
              formatter={(value: number) => [
                formatValue(value, unit, potency),
                "Actual",
              ]}
            />
            <Area
              type="monotone"
              dataKey="actual"
              stroke="#A855F7"
              strokeWidth={1.5}
              fill="url(#colorPurple)"
              dot={false}
              activeDot={{
                r: 3,
                fill: "#A855F7",
                stroke: "#fff",
                strokeWidth: 1,
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export function GlobalEconomy() {
  const [activeTab, setActiveTab] = useState("us")
  const [showAnalysis, setShowAnalysis] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  const getEventData = async (eventId: string) => {
    const token = await getAccessTokenSilently()
    const toDate = new Date()
    const fromDate = subMonths(toDate, 24)

    const params = new URLSearchParams({
      eventId,
      preliminary: "true",
      amount: "100",
      dateFromUtc: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      dateToUtc: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    })

    const response = await fetch(
      `${import.meta.env.VITE_MRKT_SERVER}/calendar/historical?${params}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    if (!response.ok) {
      throw new Error("Failed to fetch historical data")
    }
    return response.json()
  }

  const fetchRegionData = async (events: typeof REGIONS.US.events) => {
    const eventIds = Object.values(events).map((event) => event.id)
    return Promise.all(eventIds.map(getEventData))
  }

  const { data: regionData, isLoading } = useQuery({
    queryKey: ["economic-data", activeTab],
    queryFn: () => {
      const regionMap = {
        us: REGIONS.US.events,
        euro: REGIONS.EU.events,
        germany: REGIONS.DE.events,
        france: REGIONS.FR.events,
        spain: REGIONS.ES.events,
        japan: REGIONS.JP.events,
        uk: REGIONS.UK.events,
        china: REGIONS.CN.events,
        india: REGIONS.IN.events,
        australia: REGIONS.AU.events,
        newzealand: REGIONS.NZ.events,
        russia: REGIONS.RU.events,
      }
      return fetchRegionData(regionMap[activeTab as keyof typeof regionMap])
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const analyzeRegion = useMutation({
    mutationFn: async ({ region, data }: AnalyzeRegionRequest) => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/analyze/region`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ region, data }),
        },
      )

      if (!response.ok) {
        throw new Error("Failed to analyze region")
      }

      return response.json()
    },
  })

  const getRegionKey = (tab: string) => {
    const regionKeyMap = {
      us: "US",
      euro: "EU",
      germany: "DE",
      france: "FR",
      spain: "ES",
      japan: "JP",
      uk: "UK",
      china: "CN",
      india: "IN",
      australia: "AU",
      newzealand: "NZ",
      russia: "RU",
    }
    return regionKeyMap[tab as keyof typeof regionKeyMap]
  }

  const handleAnalysisClick = async () => {
    if (!regionData) return null

    setShowAnalysis(true)

    const events = Object.values(getRegionEvents())
    const analysisData = prepareAIAnalysisData(regionData, events)

    analyzeRegion.mutate({
      region: REGIONS[getRegionKey(activeTab)]?.name || "Unknown Region",
      data: analysisData,
    })
  }

  useEffect(() => {
    setShowAnalysis(false)
  }, [activeTab])

  const renderSkeletonCards = () => {
    return Array(9)
      .fill(0)
      .map((_, index) => (
        <div
          key={index}
          className="bg-zinc-900/50 p-4 rounded-lg animate-pulse"
        >
          <div className="h-6 bg-zinc-800 rounded w-2/3 mb-4"></div>
          <div className="h-[200px] bg-zinc-800 rounded"></div>
        </div>
      ))
  }

  const getRegionEvents = () => {
    const regionMap = {
      us: REGIONS.US.events,
      euro: REGIONS.EU.events,
      germany: REGIONS.DE.events,
      france: REGIONS.FR.events,
      spain: REGIONS.ES.events,
      japan: REGIONS.JP.events,
      uk: REGIONS.UK.events,
      china: REGIONS.CN.events,
      india: REGIONS.IN.events,
      australia: REGIONS.AU.events,
      newzealand: REGIONS.NZ.events,
      russia: REGIONS.RU.events,
    }
    return regionMap[activeTab as keyof typeof regionMap] || {}
  }

  const renderAIAnalysis = () => {
    return (
      <div className="mb-8">
        <AIGenerateButton
          onClick={handleAnalysisClick}
          isGenerating={isLoading}
        />
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-black">
      <div className="text-white p-8">
        {/* Tabs - centered with greater width */}
        <div className="w-full mx-auto mb-8">
          <div className="flex justify-center">
            <Tab.Group
              selectedIndex={tabs.findIndex((t) => t.id === activeTab)}
              onChange={(index) => setActiveTab(tabs[index].id)}
            >
              <Tab.List className="inline-flex bg-zinc-900 rounded-md p-1">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    className={({ selected }) =>
                      clsx(
                        "px-8 h-9 rounded-sm text-sm font-medium transition-colors",
                        selected
                          ? "bg-zinc-800 text-white"
                          : "text-gray-400 hover:text-white",
                      )
                    }
                  >
                    {tab.label}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
        </div>

        {/* Content with original width */}
        <div className="max-w-7xl mx-auto">
          <AIGenerateButton
            onClick={handleAnalysisClick}
            isGenerating={isLoading}
          />

          {/* Rest of the content */}
          <div className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {isLoading
                ? renderSkeletonCards()
                : regionData?.map((data, index) => {
                    const events = Object.values(getRegionEvents())
                    const eventName =
                      events[index]?.name?.toLowerCase() || "Unknown Event"

                    const shouldShowPercentage =
                      eventName.includes("retail sales") ||
                      eventName.includes("cpi") ||
                      eventName.includes("unemployment") ||
                      eventName.includes("gdp") ||
                      eventName.includes("interest rate") ||
                      eventName.includes("money supply") ||
                      eventName.includes("ppi")

                    return (
                      <div
                        key={events[index]?.id || index}
                        className="bg-zinc-900/50 p-4 rounded-lg"
                      >
                        <PurpleAreaChart
                          data={data.filter(
                            (item: any) => item.actual !== null,
                          )}
                          unit={shouldShowPercentage ? "%" : ""}
                          potency=""
                          title={events[index]?.name || "Unknown Event"}
                        />
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showAnalysis && (
          <GlobalEconomyAnalysisPanel
            analysis={analyzeRegion.data?.data?.analysis}
            isLoading={analyzeRegion.isPending}
            onClose={() => setShowAnalysis(false)}
            region={REGIONS[getRegionKey(activeTab)]?.name || "Unknown Region"}
          />
        )}
      </AnimatePresence>
    </div>
  )
}
