import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

interface EconomicCalendarFilters {
  countries: string[]
  impact: string[]
  categories: string[]
}

interface TradingInfo {
  tradingExperience: "beginner" | "intermediate" | "advanced" | null
  tradingInstruments: ("commodities" | "crypto" | "forex" | "equities")[] | null
  fundamentalsExperience: "beginner" | "intermediate" | "advanced" | null
}

interface UpdateUserParams {
  email: string
  updates: {
    name?: string
    economicCalendarFilters?: EconomicCalendarFilters
    hasOnboarded?: boolean
    tradingInfo?: TradingInfo
    referralSource?: string
    userName?: string
  }
}

export const useUpdateUser = () => {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ email, updates }: UpdateUserParams) => {
      const token = await getAccessTokenSilently()

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/user/update/${email}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updates),
        },
      )

      if (!response.ok) {
        throw new Error("Failed to update user")
      }

      return response.json()
    },
    onSuccess: () => {
      // Invalidate and refetch user data
      queryClient.invalidateQueries({ queryKey: ["user"] })
    },
  })

  return {
    updateUser: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  }
}
