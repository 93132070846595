import React, { useEffect, useState } from "react"
import { Editor } from "slate"
import { toggleBlock, toggleMark, insertImage } from "./utils/editorCommands"
import { Search, Calendar } from "lucide-react"

interface Command {
  label?: string
  title?: string
  action?: () => void
  command?: () => void
  icon?: React.ReactNode | string
  description?: string
}

interface CommandMenuProps {
  editor: Editor
  isOpen: boolean
  onClose: () => void
  position: { x: number; y: number }
  setShowHeadlineSearch: (show: boolean) => void
  setShowCalendarSearch: (show: boolean) => void
  setShowImageDialog: (show: boolean) => void
}

const CommandMenu: React.FC<CommandMenuProps> = ({
  editor,
  isOpen,
  onClose,
  position,
  setShowHeadlineSearch,
  setShowCalendarSearch,
  setShowImageDialog,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const commands = [
    { label: "Text", action: () => toggleBlock(editor, "paragraph") },
    { label: "Heading 1", action: () => toggleBlock(editor, "heading-one") },
    { label: "Heading 2", action: () => toggleBlock(editor, "heading-two") },
    { label: "Heading 3", action: () => toggleBlock(editor, "heading-three") },
    { label: "Bold", action: () => toggleMark(editor, "bold") },
    { label: "Italic", action: () => toggleMark(editor, "italic") },
    {
      label: "Headline",
      icon: <Search className="w-4 h-4" />,
      action: () => {
        onClose()
        setShowHeadlineSearch(true)
      },
    },
    {
      label: "Economic Event",
      icon: <Calendar className="w-4 h-4" />,
      action: () => {
        onClose()
        setShowCalendarSearch(true)
      },
    },
    {
      title: "TradingView Chart",
      description: "Insert a TradingView chart link",
      icon: "chart",
      command: () => {
        setShowImageDialog(true)
        onClose()
      },
    },
  ]

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e: KeyboardEvent) => {
        e.preventDefault()
        switch (e.key) {
          case "ArrowDown":
            setSelectedIndex((prev) => (prev + 1) % commands.length)
            break
          case "ArrowUp":
            setSelectedIndex((prev) =>
              prev - 1 < 0 ? commands.length - 1 : prev - 1,
            )
            break
          case "Enter":
            executeCommand(commands[selectedIndex])
            onClose()
            break
          case "Escape":
            onClose()
            break
        }
      }

      document.addEventListener("keydown", handleKeyDown)
      return () => document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpen, selectedIndex, commands, editor, onClose])

  const executeCommand = (cmd: Command) => {
    if (cmd.action) {
      cmd.action()
    } else if (cmd.command) {
      cmd.command()
    }
  }

  if (!isOpen) return null

  return (
    <div
      className="command-menu"
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        backgroundColor: "#1a1a1a",
        border: "1px solid #333",
        borderRadius: "6px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
        zIndex: 1000,
        width: "220px",
        padding: "4px",
      }}
    >
      {commands.map((command, index) => (
        <button
          key={command.label || command.title}
          onClick={() => {
            executeCommand(command)
            onClose()
          }}
          style={{
            display: "block",
            width: "100%",
            padding: "8px 12px",
            border: "none",
            background: index === selectedIndex ? "#333" : "transparent",
            color: "white",
            textAlign: "left",
            cursor: "pointer",
            borderRadius: "4px",
            fontSize: "14px",
            fontWeight: index === selectedIndex ? "500" : "normal",
          }}
          onMouseEnter={() => setSelectedIndex(index)}
        >
          {command.label || command.title}
        </button>
      ))}
    </div>
  )
}

export default CommandMenu
