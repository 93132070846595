import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts"
import { format, parseISO, subMonths } from "date-fns"
import { formatValue } from "../homescreen/components/EconomicCalendar/EconomicCalendar"
import { cn } from "@/lib/utils"
import { enUS } from "date-fns/locale"

interface HistoricalBarChartProps {
  data: any[]
  unit: string | null
  potency: string | null
  selectedCategories: ("Actual" | "Forecast")[]
  chartTypes: Record<"Actual" | "Forecast", "bar" | "line">
  periodType?: string
}

// Custom tooltip component
const CustomTooltip = ({ active, payload, label, unit, potency }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#111] border border-[#333] rounded-lg p-3 shadow-lg">
        <p className="text-gray-400 text-sm mb-1">{label}</p>
        {payload.map((entry: any, index: number) => (
          <p
            key={`${entry.name}-${index}`}
            className={cn(
              "font-medium",
              entry.name === "Actual" && "text-blue-600",
              entry.name === "Forecast" && "text-orange-400",
            )}
          >
            {entry.name}: {formatValue(entry.value, unit, potency)}
          </p>
        ))}
      </div>
    )
  }
  return null
}

// Add the helper function
const formatPeriodDate = (dateUtc: string, periodType: string) => {
  const date = new Date(dateUtc)
  return format(date, "MMM d, yyyy", { locale: enUS })
}

export function HistoricalBarChart({
  data,
  unit,
  potency,
  selectedCategories,
  chartTypes,
  periodType = "",
}: HistoricalBarChartProps) {
  // If data is empty, show empty state
  if (!data?.length) {
    return (
      <div className="w-full h-[500px] flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-400 text-lg">No historical data available</p>
        </div>
      </div>
    )
  }

  const chartData = data
    .sort(
      (a: { dateUtc: string }, b: { dateUtc: string }) =>
        new Date(a.dateUtc).getTime() - new Date(b.dateUtc).getTime(),
    )
    .map((item) => ({
      date: formatPeriodDate(item.dateUtc, periodType),
      actual: item.actual,
      forecast: item.consensus,
      previous: item.revised ?? item.previous,
    }))

  const barWidth = 30

  const colors = {
    Actual: "rgb(37 99 235)", // text-blue-600 (deeper blue)
    Forecast: "rgb(251 146 60)", // text-orange-400 (muted orange)
  }

  return (
    <div className="w-full h-[500px] relative">
      {/* MRKT Watermark */}
      <div className="absolute right-8 pointer-events-none z-0">
        <span className="text-[60px] text-white/[0.04] select-none font-normal">
          MRKT
        </span>
      </div>

      <ResponsiveContainer width="100%" height="100%" className="relative z-10">
        <ComposedChart
          data={chartData}
          margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
          barCategoryGap={selectedCategories.length > 1 ? "50%" : "35%"}
          barGap={selectedCategories.length > 1 ? 12 : 0}
        >
          <XAxis
            dataKey="date"
            stroke="#666"
            tick={{ fill: "#666", fontSize: 12 }}
            axisLine={{ stroke: "#333" }}
            angle={-45}
            textAnchor="end"
            height={60}
          />
          <YAxis
            stroke="#666"
            tick={{ fill: "#666", fontSize: 12 }}
            axisLine={{ stroke: "#333" }}
            tickFormatter={(value) => formatValue(value, unit, potency)}
          />
          <Tooltip
            content={<CustomTooltip unit={unit} potency={potency} />}
            cursor={{ fill: "rgba(255, 255, 255, 0.05)" }}
          />
          <Legend
            wrapperStyle={{
              paddingTop: "20px",
            }}
            formatter={(value) => (
              <span className="text-gray-400">{value}</span>
            )}
          />
          {selectedCategories.includes("Actual") &&
            (chartTypes.Actual === "bar" ? (
              <Bar
                dataKey="actual"
                name="Actual"
                fill={colors.Actual}
                fillOpacity={0.85}
                barSize={barWidth}
              />
            ) : (
              <Line
                type="monotone"
                dataKey="actual"
                name="Actual"
                stroke={colors.Actual}
                strokeWidth={2.5}
                dot={false}
                activeDot={{ r: 5, strokeWidth: 2 }}
              />
            ))}
          {selectedCategories.includes("Forecast") &&
            (chartTypes.Forecast === "bar" ? (
              <Bar
                dataKey="forecast"
                name="Forecast"
                fill={colors.Forecast}
                fillOpacity={0.85}
                barSize={barWidth}
              />
            ) : (
              <Line
                type="monotone"
                dataKey="forecast"
                name="Forecast"
                stroke={colors.Forecast}
                strokeWidth={2.5}
                dot={false}
                activeDot={{ r: 5, strokeWidth: 2 }}
              />
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
