import { useEffect, useState } from "react"
import { X, Calendar } from "lucide-react"
import { useEconomicCalendar } from "@/providers"
import SimpleBar from "simplebar-react"
import {
  addDays,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
} from "date-fns"
import Flag from "react-flagkit"
import { HistoricalAreaChart } from "@/presentation/screens/homescreen/components/EconomicCalendar/EventAnalysisPanel"
import { useAuth0 } from "@auth0/auth0-react"
import {
  formatValue,
  getCountryFlag,
} from "@/presentation/screens/homescreen/components/EconomicCalendar/EconomicCalendar"
import {
  CountriesMultiSelectFilter,
  DatePickerFilterPill,
} from "@/components/Filters"
import clsx from "clsx"
import {
  HeadlineBadge,
  HeadlinePriority,
} from "@/presentation/screens/homescreen/components/MarketHeadlines"

import {
  countryOptions,
  impactOptions,
  categoryOptions,
} from "@/presentation/screens/homescreen/components/EconomicCalendar/EconomicCalendar"

interface CalendarSearchOverlayProps {
  isOpen: boolean
  onClose: () => void
  onEventSelect?: (event: {
    name: string
    dateUtc: string
    countryCode: string
    actual: string
    consensus: string
    previous: string
    unit: string
    volatility: string
    potency: string
    historicalData?: Array<{
      actual: number | null
      date: string
    }>
  }) => void
}

interface CalendarEvent {
  id: string
  eventId: string
  dateUtc: string
  name: string
  countryCode: string
  actual: string
  consensus: string
  previous: string
  unit: string
  volatility: string
  potency: string
  historicalData?: Array<{
    actual: number | null
    date: string
  }>
}

export const CalendarSearchOverlay = ({
  isOpen,
  onClose,
  onEventSelect,
}: CalendarSearchOverlayProps) => {
  const {
    economicCalendarData,
    setStartDateFilter,
    setEndDateFilter,
    countryFilter,
    impactFilter,
    categoryFilter,
    setCountryFilter,
    setImpactFilter,
    setCategoryFilter,
  } = useEconomicCalendar()
  const { getAccessTokenSilently } = useAuth0()

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<
    "today" | "tomorrow" | "week" | "month"
  >("week")

  useEffect(() => {
    if (isOpen) {
      const today = new Date()
      setStartDateFilter(startOfDay(today))
      setEndDateFilter(endOfDay(addDays(today, 7)))
    }
  }, [isOpen])

  const handleEventSelect = async (event: CalendarEvent) => {
    setSelectedEvent(event)
    const token = await getAccessTokenSilently()
    const toDate = new Date()
    const fromDate = subMonths(toDate, 12)

    const params = new URLSearchParams({
      eventId: event.eventId,
      preliminary: "true",
      amount: "100",
      dateFromUtc: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      dateToUtc: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    })

    const response = await fetch(
      `${import.meta.env.VITE_MRKT_SERVER}/calendar/historical?${params}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const rawData = await response.json()

    // Transform the data to match HistoricalAreaChart expectations
    const historicalData = rawData.map((item: any) => ({
      actual: item.actual === null ? null : Number(item.actual),
      dateUtc: item.dateUtc,
    }))

    onEventSelect?.({
      ...event,
      historicalData,
      potency: event.potency || "1",
    })
    onClose()
  }

  const handlePeriodChange = (
    period: "today" | "tomorrow" | "week" | "month",
  ) => {
    const today = new Date()
    let start: Date
    let end: Date

    switch (period) {
      case "today":
        start = startOfDay(today)
        end = endOfDay(today)
        break
      case "tomorrow":
        start = startOfDay(addDays(today, 1))
        end = endOfDay(addDays(today, 1))
        break
      case "week":
        start = startOfDay(startOfWeek(today))
        end = endOfDay(endOfWeek(today))
        break
      case "month":
        start = startOfDay(startOfMonth(today))
        end = endOfDay(endOfMonth(today))
        break
      default:
        start = startOfDay(today)
        end = endOfDay(today)
    }

    setSelectedPeriod(period)
    setStartDateFilter(start)
    setEndDateFilter(end)
  }

  if (!isOpen) return null

  return (
    <>
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50"
      />
      <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
        <div className="w-full max-w-4xl rounded-lg shadow-2xl flex flex-col bg-obsidian/95 border border-[#333]">
          <div className="p-4 border-b border-[#333]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Calendar className="w-5 h-5 text-purple-400" />
                <h2 className="text-lg text-white">Select Economic Event</h2>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-[#333] rounded-md transition-colors"
              >
                <X className="w-5 h-5 text-gray-300" />
              </button>
            </div>
          </div>

          <div className="p-4 border-b border-[#333]">
            {/* Period Selection */}
            <div className="flex gap-1 mb-4">
              <button
                onClick={() => handlePeriodChange("today")}
                className={clsx(
                  "rounded-md px-4 transition-colors p-2 border border-[#333]",
                  selectedPeriod === "today"
                    ? "bg-gray-600/50 hover:bg-[#333] text-white border-none"
                    : "bg-[#222] hover:bg-[#333] text-white",
                )}
              >
                Today
              </button>
              <button
                onClick={() => handlePeriodChange("tomorrow")}
                className={clsx(
                  "rounded-md px-4 transition-colors p-2 border border-[#333]",
                  selectedPeriod === "tomorrow"
                    ? "bg-gray-600/50 hover:bg-[#333] text-white border-none"
                    : "bg-[#222] hover:bg-[#333] text-white",
                )}
              >
                Tomorrow
              </button>
              <button
                onClick={() => handlePeriodChange("week")}
                className={clsx(
                  "rounded-md px-4 transition-colors p-2 border border-[#333]",
                  selectedPeriod === "week"
                    ? "bg-gray-600/50 hover:bg-[#333] text-white border-none"
                    : "bg-[#222] hover:bg-[#333] text-white",
                )}
              >
                Week
              </button>
              <button
                onClick={() => handlePeriodChange("month")}
                className={clsx(
                  "rounded-md px-4 transition-colors p-2 border border-[#333]",
                  selectedPeriod === "month"
                    ? "bg-gray-600/50 hover:bg-[#333] text-white border-none"
                    : "bg-[#222] hover:bg-[#333] text-white",
                )}
              >
                Month
              </button>
            </div>

            {/* Filters */}
            <div className="flex gap-2">
              <CountriesMultiSelectFilter
                title="Countries"
                selectedStatuses={countryFilter}
                onChange={(selectedOptions) =>
                  setCountryFilter(selectedOptions)
                }
                filterOptions={countryOptions}
              />
              <CountriesMultiSelectFilter
                title="Impact"
                selectedStatuses={impactFilter}
                onChange={(selectedOptions) => setImpactFilter(selectedOptions)}
                filterOptions={impactOptions}
              />
              <CountriesMultiSelectFilter
                title="Categories"
                selectedStatuses={categoryFilter}
                onChange={(selectedOptions) =>
                  setCategoryFilter(selectedOptions)
                }
                filterOptions={categoryOptions}
              />
              <DatePickerFilterPill
                title="Date"
                selectedStartDate=""
                onChange={(dateRange) => {
                  if (dateRange?.from && dateRange?.to) {
                    setStartDateFilter(dateRange.from)
                    setEndDateFilter(dateRange.to)
                  }
                }}
                onClear={() => handlePeriodChange("week")}
              />
            </div>
          </div>

          <SimpleBar style={{ maxHeight: "600px" }}>
            <div className="p-4">
              {selectedEvent && selectedEvent.historicalData && (
                <div className="mb-6">
                  <HistoricalAreaChart
                    data={selectedEvent.historicalData.filter(
                      (item) => item.actual !== null,
                    )}
                    unit={selectedEvent.unit}
                    potency={selectedEvent.potency}
                  />
                </div>
              )}
              <table className="w-full">
                <thead>
                  <tr className="text-left text-gray-400 text-sm">
                    <th className="px-3 py-2">Time</th>
                    <th className="px-3 py-2">Country</th>
                    <th className="px-3 py-2">Impact</th>
                    <th className="px-3 py-2">Event</th>
                    <th className="px-3 py-2">Actual</th>
                    <th className="px-3 py-2">Consensus</th>
                    <th className="px-3 py-2">Previous</th>
                  </tr>
                </thead>
                <tbody>
                  {economicCalendarData?.map((event: CalendarEvent) => (
                    <tr
                      key={event.id}
                      onClick={() => handleEventSelect(event)}
                      className="hover:bg-gray-800/50 cursor-pointer transition-colors"
                    >
                      <td className="px-3 py-2 text-sm text-gray-400">
                        {new Date(event.dateUtc).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex items-center gap-2">
                          {getCountryFlag(event.countryCode)}
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        {HeadlineBadge(event.volatility as HeadlinePriority)}
                      </td>
                      <td className="px-3 py-2">
                        <span className="text-white text-sm">{event.name}</span>
                      </td>
                      <td className="px-3 py-2 text-sm text-gray-400">
                        {formatValue(
                          event.actual === null ? null : Number(event.actual),
                          event.unit,
                          event.potency,
                        )}
                      </td>
                      <td className="px-3 py-2 text-sm text-gray-400">
                        {formatValue(
                          event.consensus === null
                            ? null
                            : Number(event.consensus),
                          event.unit,
                          event.potency,
                        )}
                      </td>
                      <td className="px-3 py-2 text-sm text-gray-400">
                        {formatValue(
                          event.previous === null
                            ? null
                            : Number(event.previous),
                          event.unit,
                          event.potency,
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  )
}

export default CalendarSearchOverlay
