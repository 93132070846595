import { cn } from "@/lib/utils"

interface ToggleProps {
  enabled: boolean
  onChange: (enabled: boolean) => void
  label?: string
  size?: "sm" | "md"
}

export const Toggle = ({
  enabled,
  onChange,
  label,
  size = "md",
}: ToggleProps) => {
  return (
    <button
      type="button"
      onClick={() => onChange(!enabled)}
      className={cn(
        "group inline-flex items-center gap-3",
        size === "sm" ? "text-sm" : "text-base",
      )}
    >
      <div
        className={cn(
          "relative inline-flex h-5 w-9 items-center rounded-full transition-colors duration-200",
          enabled ? "bg-yellow-500/30" : "bg-white/10",
        )}
      >
        <span
          className={cn(
            "inline-block h-4 w-4 transform rounded-full transition-transform duration-200 shadow-md",
            enabled
              ? "translate-x-4 bg-yellow-400"
              : "translate-x-1 bg-gray-400",
          )}
        />
      </div>
      {label && (
        <span
          className={cn(
            "font-medium transition-colors duration-200",
            enabled ? "text-yellow-200" : "text-gray-400",
          )}
        >
          {label}
        </span>
      )}
    </button>
  )
}
