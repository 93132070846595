import { createContext, useContext, useState } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

interface Article {
  publishedDate: string
  title: string
  image: string | null
  site: string
  text: string
  url: string
  symbol: string
  sentiment?: string
  sentimentScore?: number
}

interface ArticlesContextType {
  fxArticles: Article[]
  stockArticles: Article[]
  cryptoArticles: Article[]
  isLoadingFx: boolean
  isLoadingStocks: boolean
  isLoadingCrypto: boolean
  fetchNextFxPage: () => void
  fetchNextStocksPage: () => void
  fetchNextCryptoPage: () => void
  hasNextFxPage: boolean
  hasNextStocksPage: boolean
  hasNextCryptoPage: boolean
  isFetchingNextFxPage: boolean
  isFetchingNextStocksPage: boolean
  isFetchingNextCryptoPage: boolean
}

const ArticlesContext = createContext<ArticlesContextType | undefined>(
  undefined,
)

export const ArticlesProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const fetchArticles = async ({
    pageParam = 0,
    type,
  }: {
    pageParam?: number
    type: string
  }) => {
    const token = await getAccessTokenSilently()
    const response = await fetch(
      `${import.meta.env.VITE_MRKT_SERVER}/articles/${type}?page=${pageParam}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response.json()
  }

  const {
    data: fxData,
    fetchNextPage: fetchNextFxPage,
    hasNextPage: hasNextFxPage,
    isLoading: isLoadingFx,
    isFetchingNextPage: isFetchingNextFxPage,
  } = useInfiniteQuery({
    queryKey: ["fxArticles"],
    queryFn: ({ pageParam }) => fetchArticles({ pageParam, type: "fx" }),
    getNextPageParam: (lastPage, pages) => pages.length,
    initialPageParam: 0,
  })

  const {
    data: stocksData,
    fetchNextPage: fetchNextStocksPage,
    hasNextPage: hasNextStocksPage,
    isLoading: isLoadingStocks,
    isFetchingNextPage: isFetchingNextStocksPage,
  } = useInfiniteQuery({
    queryKey: ["stockArticles"],
    queryFn: ({ pageParam }) => fetchArticles({ pageParam, type: "stocks" }),
    getNextPageParam: (lastPage, pages) => pages.length,
    initialPageParam: 0,
  })

  const {
    data: cryptoData,
    fetchNextPage: fetchNextCryptoPage,
    hasNextPage: hasNextCryptoPage,
    isLoading: isLoadingCrypto,
    isFetchingNextPage: isFetchingNextCryptoPage,
  } = useInfiniteQuery({
    queryKey: ["cryptoArticles"],
    queryFn: ({ pageParam }) => fetchArticles({ pageParam, type: "crypto" }),
    getNextPageParam: (lastPage, pages) => pages.length,
    initialPageParam: 0,
  })

  const fxArticles = fxData?.pages.flat() ?? []
  const stockArticles = stocksData?.pages.flat() ?? []
  const cryptoArticles = cryptoData?.pages.flat() ?? []

  return (
    <ArticlesContext.Provider
      value={{
        fxArticles,
        stockArticles,
        cryptoArticles,
        isLoadingFx,
        isLoadingStocks,
        isLoadingCrypto,
        fetchNextFxPage,
        fetchNextStocksPage,
        fetchNextCryptoPage,
        hasNextFxPage,
        hasNextStocksPage,
        hasNextCryptoPage,
        isFetchingNextFxPage,
        isFetchingNextStocksPage,
        isFetchingNextCryptoPage,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  )
}

export const useArticles = () => {
  const context = useContext(ArticlesContext)
  if (context === undefined) {
    throw new Error("useArticles must be used within an ArticlesProvider")
  }
  return context
}
