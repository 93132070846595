import { useAuth0 } from "@auth0/auth0-react"
import { useInfiniteQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { StockLogo } from "@/presentation/components/StockLogo/StockLogo"

interface StockNews {
  symbol: string
  publishedDate: string
  title: string
  image: string
  site: string
  text: string
  url: string
}

interface StockNewsProps {
  symbol: string
}

export function StockNews({ symbol }: StockNewsProps) {
  const { getAccessTokenSilently } = useAuth0()

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["stockNews", symbol],
      queryFn: async ({ pageParam = 0 }) => {
        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/stock-news?tickers=${symbol}&page=${pageParam}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.json() as Promise<StockNews[]>
      },
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === 10 ? pages.length : undefined,
      initialPageParam: 0,
    })

  const news = data?.pages.flat() ?? []

  if (isLoading) {
    return (
      <div className="space-y-3">
        {[...Array(5)].map((_, i) => (
          <Skeleton
            key={i}
            height={88}
            className="mb-2"
            baseColor="#222"
            highlightColor="#333"
          />
        ))}
      </div>
    )
  }

  return (
    <SimpleBar className="h-[calc(100%-2rem)]">
      <div className="space-y-3">
        {news.map((article) => (
          <a
            key={article.url}
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
            className="group block"
          >
            <div
              className="relative flex gap-4 p-4 rounded-xl
              border border-white/5 
              bg-black/20
              hover:bg-black/30
              hover:border-white/10
              hover:scale-[1.02]
              transition-all duration-200 ease-out"
            >
              <div className="flex-shrink-0">
                <StockLogo symbol={symbol} size="lg" />
              </div>
              <div className="flex-1 min-w-0 flex flex-col justify-center">
                <div className="flex items-center gap-2 mb-1">
                  <span className="text-sm font-medium text-white/70">
                    {article.site}
                  </span>
                  <span className="inline-block w-1 h-1 rounded-full bg-white/30" />
                  <span className="text-xs text-white/40">
                    {format(new Date(article.publishedDate), "MMM d, HH:mm")}
                  </span>
                </div>
                <h3 className="text-sm text-white/60 font-medium leading-snug group-hover:text-white/90 transition-colors duration-200">
                  {article.title}
                </h3>
              </div>
            </div>
          </a>
        ))}

        {hasNextPage && (
          <button
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
            className="w-full py-3 text-sm font-medium text-white/50 
              hover:text-white/70 transition-colors duration-200
              border border-white/5 rounded-xl hover:bg-white/5"
          >
            {isFetchingNextPage ? "Loading more..." : "Load more articles"}
          </button>
        )}
      </div>
    </SimpleBar>
  )
}
