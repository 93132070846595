export const noAIExplanationEvents = [
  // Fed Speeches
  "Fed's Bostic speech",
  "Fed's Cook speech",
  "Fed's Kashkari speech",
  "Fed's Goolsbee speech",
  "Fed's Barkin speech",
  "Fed's Williams speech",
  "Fed's Daly speech",
  "Fed's Logan speech",
  "Fed's Bowman speech",
  "Fed's Musalem speech",
  "Fed's Schmid speech",
  "Fed's Hammack speech",
  "Fed's Harker speech",
  "Fed's Collins speech",
  "Fed's Jefferson speech",
  "Fed's Kugler speech",
  "Fed's Waller speech",
  "Fed's Chair Powell speech",
  "Fed's Barr speech",

  // BoE Speeches
  "BoE's Pill speech",
  "BoE's Governor Bailey speech",
  "BoE's Mann speech",
  "BoE's Greene speech",
  "BoE's Dhingra speech",
  "BoE's Lombardelli speech",
  "BoE's Breeden speech",
  "BoE's Ramsden speech",

  // ECB/Bundesbank Speeches
  "German Buba President Nagel speech",

  // Other Central Bank Speeches
  "BoJ Governor Ueda speech",
  "RBA Governor Bullock speech",
  "BoC's Governor Macklem speech",
  "SNB Chairman Schlegel speech",

  // Press Conferences and Special Events
  "FOMC Press Conference",
  "BoJ Press Conference",
  "BoC Press Conference",
  "SNB Press Conference",
  "RBA Press Conference",

  // Meeting Minutes and Reports
  "FOMC Minutes",
  "BoE Minutes",
  "RBA Meeting Minutes",
  "BoJ Monetary Policy Meeting Minutes",
  "BoJ Summary of Opinions",
  "BoC Summary of Deliberations",
  "FPC Meeting Minutes",
  "Monetary Policy Meeting Minutes",

  // Additional Central Bank Reports/Statements
  "BoJ Outlook Report",
  "BoJ Monetary Policy Statement",
  "BoE Monetary Policy Statement",
  "BoE Monetary Policy Report",
  "Fed Monetary Policy Statement",
  "BoC Press Conference",
  "SNB Monetary Policy Assessment",
  "BoC Monetary Policy Statement",
  "RBA Monetary Policy Statement",
  "RBA Rate Statement",
  "FPC Statement",

  // Additional Reports and Bulletins
  "SNB Quarterly Bulletin",
  "RBA Quarterly Bulletin",
  "RBA Bulletin",
  "BoE Quarterly Bulletin",
  "German Buba Monthly Report",
  "Financial Stability Report",
  "FOMC Economic Projections",
  "BoC Monetary Policy Report",
  "Fed's Beige Book",
  "BoE Monetary Policy Report Hearings",
  "BoE Credit Conditions Survey",
  "RBA Annual Report",
  "Loan Officer Survey",

  // Interest Rate Decisions
  "Fed Interest Rate Decision",
  "BoE Interest Rate Decision",
  "ECB Interest Rate Decision",
  "BoJ Interest Rate Decision",
  "RBA Interest Rate Decision",
  "BoC Interest Rate Decision",
  "SNB Interest Rate Decision",
  "RBNZ Interest Rate Decision",
  "Riksbank Interest Rate Decision",
  "Norges Bank Interest Rate Decision",

  // FOMC Projections and Interest Rate Events
  "FOMC Economic Projections",
  "Interest Rate Projections - 1st year",
  "Interest Rate Projections - 2nd year",
  "Interest Rate Projections - 3rd year",
  "Interest Rate Projections - Current",
  "Interest Rate Projections - Longer",
  "Interest Rate Decision",
  "Interest Rate Statement",
  "Interest Rate Vote",
  "BoE MPC Vote Rate Cut",
  "BoE MPC Vote Rate Hike",
  "BoE MPC Vote Rate Unchanged",
  "Reverse Repo Rate",
]

const fedSpeakerImages: { [key: string]: string } = {
  "Fed's Bostic speech": "/fed_bostic.jpg",
  "Fed's Barkin speech": "/fed_barkin.jpg",
  "Fed's Daly speech": "/fed_daly.jpg",
  "Fed's Goolsbee speech": "/fed_goolsbee.jpg",
  "Fed's Williams speech": "/fed_williams.jpg",
  "Fed's Waller speech": "/fed_waller.jpg",
  "Fed's Barr speech": "/fed_barr.jpg",
  "Fed's Bowman speech": "/fed_bowman.jpg",
  "Fed's Logan speech": "/fed_logan.jpg",
  "Fed's Chair Powell speech": "/fed_powell.jpg",
  "Fed's Harker speech": "/fed_harker.jpg",
  "Fed's Kashkari speech": "/fed_kashkari.jpg",
  "Fed's Kugler speech": "/fed_kugler.jpg",
  "Fed's Collins speech": "/fed_collins.jpg",
  "Fed's Musalem speech": "/fed_musalem.jpg",
  "Fed's Cook speech": "/fed_cook.jpg",
  "Fed's Hammack speech": "/fed_hammack.jpg",
  "Fed's Jefferson speech": "/fed_jefferson.jpg",
  "Fed's Schmid speech": "/fed_schmid.jpg",
  "Fed Monetary Policy Statement": "/fed_powell.jpg",
  "FOMC Press Conference": "/fed_powell.jpg",
}

export const getEventImage = (eventName: string): string | undefined => {
  return fedSpeakerImages[eventName] || undefined
}

export const getCentralBankName = (countryCode: string): string => {
  switch (countryCode) {
    case "US":
      return "Federal Reserve"
    case "EU":
      return "European Central Bank"
    case "GB":
      return "Bank of England"
    case "JP":
      return "Bank of Japan"
    case "CA":
      return "Bank of Canada"
    case "AU":
      return "Reserve Bank of Australia"
    case "NZ":
      return "Reserve Bank of New Zealand"
    case "CH":
      return "Swiss National Bank"
    default:
      return ""
  }
}
