import { useNavigate } from "@tanstack/react-router"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"

export const BackButton = () => {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => navigate({ to: ".." })}
      className="inline-flex items-center gap-2 text-zinc-400 hover:text-white transition-colors"
    >
      <ArrowLeftIcon className="h-5 w-5" />
      <span>Back</span>
    </button>
  )
}
