import { motion } from "framer-motion"
import { X, Loader2, CalendarIcon, Search } from "lucide-react"
import { format } from "date-fns"
import SimpleBar from "simplebar-react"
import { HeadlineItem } from "./HeadlineItem"
import {
  parseHeadlineTime,
  parseHeadlineTitle,
} from "../../utilities/headlineParser"
import { Resizable } from "re-resizable"
import { useState } from "react"

interface HeadlineData {
  text: string
  date: string
}

interface DailyHeadlinesPanelProps {
  headlines: any[]
  isLoading: boolean
  onClose: () => void
  selectedDate: Date | undefined
  highlightEnabled: boolean
  className?: string
}

export const DailyHeadlinesPanel = ({
  headlines,
  isLoading,
  onClose,
  selectedDate,
  highlightEnabled,
  className,
}: DailyHeadlinesPanelProps) => {
  const [searchQuery, setSearchQuery] = useState("")
  const headlinesArray = Array.isArray(headlines) ? headlines : []

  const filteredHeadlines = headlinesArray.filter((headline) => {
    const headlineTitle = parseHeadlineTitle(headline.text)
    return headlineTitle?.toLowerCase().includes(searchQuery.toLowerCase())
  })

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: 500, height: "100%" }}
        minWidth={500}
        maxWidth={1000}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleClasses={{
          left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 border-b border-[#333] bg-black/50 backdrop-blur-sm">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-purple-500/10 border border-purple-500/20">
                <CalendarIcon className="w-5 h-5 text-purple-400" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Daily Headlines
                </h2>
                <p className="text-sm text-gray-400">
                  {selectedDate
                    ? format(selectedDate, "MMMM d, yyyy")
                    : "Latest Headlines"}
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="flex items-center justify-center w-8 h-8 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="px-6 py-4 border-b border-[#333] bg-black/30">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search headlines..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-[#222] border border-[#444] rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:border-purple-500/50 focus:ring-1 focus:ring-purple-500/50"
              />
            </div>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="flex items-center gap-2 text-gray-400">
                <Loader2 className="w-6 h-6 animate-spin" />
                <span>Loading headlines...</span>
              </div>
            </div>
          ) : filteredHeadlines.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <div className="text-gray-400">
                {searchQuery
                  ? "No headlines found matching your search"
                  : "No headlines found for this date"}
              </div>
            </div>
          ) : (
            <SimpleBar
              className="flex-1 overflow-y-auto"
              style={{ height: "100%" }}
              autoHide={false}
            >
              <div className="p-6">
                <ul className="space-y-2 relative">
                  <div className="absolute left-[15px] top-0 bottom-0 w-px bg-gray-600/40"></div>
                  {filteredHeadlines.map((headline, index) => {
                    const { relativeTime, localTime } = parseHeadlineTime(
                      headline.text,
                    )
                    const headlineTitle = parseHeadlineTitle(headline.text)
                    if (headlineTitle === undefined) return null

                    return (
                      <li key={index} className="relative pl-2 group">
                        <div className="absolute left-[10px] top-[22px] w-[11px] h-[11px] rounded-full bg-purple-400/80 ring-4 ring-black group-hover:ring-gray-800 transition-all duration-200"></div>
                        <div className="ml-6 p-4 rounded-lg hover:bg-gray-800/50 transition-all duration-200 border border-transparent hover:border-gray-700">
                          <HeadlineItem
                            headlineTitle={headlineTitle}
                            headlineTime={localTime}
                            relativeTime={relativeTime}
                            highlightEnabled={highlightEnabled}
                          />
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </SimpleBar>
          )}
        </div>
      </Resizable>
    </motion.div>
  )
}
