import { useState } from "react"
import { useUser } from "@/providers/UserProvider"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { useAuth0 } from "@auth0/auth0-react"

interface UsernamePromptModalProps {
  onClose: () => void
}

export const UsernamePromptModal = ({ onClose }: UsernamePromptModalProps) => {
  const [username, setUsername] = useState("")
  const { updateUser, isPending } = useUpdateUser()
  const { user } = useAuth0()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (!user?.email) {
        throw new Error("User email is required")
      }
      await updateUser({ email: user?.email, updates: { userName: username } })
      onClose()
    } catch (error) {
      console.error("Failed to update username:", error)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-lg p-6 max-w-md w-full">
        <h2 className="text-2xl font-bold text-white mb-4">
          Choose a Username
        </h2>
        <p className="text-gray-400 mb-6">
          Please choose a username before creating your first market research!
          This will be used to identify your research and cannot be changed
          later.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            className="w-full bg-gray-800 text-white px-4 py-2 rounded mb-4"
            required
            pattern="[a-zA-Z0-9_-]{3,20}"
            title="Username must be 3-20 characters and can only contain letters, numbers, underscores, and hyphens"
          />
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-white"
              disabled={isPending}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 disabled:opacity-50"
              disabled={isPending}
            >
              {isPending ? "Saving..." : "Save Username"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
