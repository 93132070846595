import { createFileRoute, Outlet } from "@tanstack/react-router"
import { Link } from "@tanstack/react-router"
import { useNavigate } from "@tanstack/react-router"
import { useUser } from "@/providers/UserProvider"
import { BackButton } from "@/components/BackButton"
import { Navbar } from "@/components/Navbar"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { ResearchSearchOverlay } from "@/components/ResearchSearchOverlay"
import { Search } from "lucide-react"

export const Route = createFileRoute("/research")({
  component: ResearchPage,
})

interface Post {
  _id: string
  title: string
  description: string
  content: any
  author: string
  publishedAt: string
  status: string
  slug: string
}

function ResearchPage() {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(true)
  const { userData, isSubscribed } = useUser()
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()

  // Check username status whenever userData changes
  useEffect(() => {
    if (userData?.userName) {
      setShowUsernamePrompt(false)
    }
  }, [userData?.userName])

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault()
        setIsSearchOpen(true)
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])

  const { data: recentPosts, isLoading } = useQuery({
    queryKey: ["recentPosts"],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts/recent?limit=3`,
      )
      if (!response.ok) {
        throw new Error("Failed to fetch recent posts")
      }
      return response.json() as Promise<Post[]>
    },
  })

  const { data: likedPosts, isLoading: isLoadingLiked } = useQuery({
    queryKey: ["likedPosts", userData?.likedPosts],
    queryFn: async () => {
      if (!userData?.likedPosts?.length) return []

      const queryParams = new URLSearchParams({
        ids: userData.likedPosts.join(","),
      })

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts-by-id?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch liked posts")
      }
      return response.json() as Promise<Post[]>
    },
    enabled: !!userData?.userName,
    retry: false, // Don't retry on failure
  })

  const showBackButton = isSubscribed
  // Check if we're on the exact /research path
  const isIndex =
    window.location.pathname === "/research" ||
    window.location.pathname === "/research/"

  if (!isIndex) {
    return <Outlet />
  }

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {showBackButton && (
          <div className="mb-8">
            <BackButton />
          </div>
        )}

        <div className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold text-white">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              Research Hub
            </span>
          </h1>

          {/* Add this search button */}
          <button
            onClick={() => setIsSearchOpen(true)}
            className="flex items-center gap-2 px-4 py-2
                     bg-[#111] hover:bg-[#333] transition-colors
                     rounded-md border border-[#333]"
          >
            <Search className="w-4 h-4 text-purple-400" />
            <span className="text-md text-white">Search research</span>
            <kbd
              className="hidden md:inline-flex items-center gap-1 px-2 py-0.5
                         text-[10px] font-medium text-gray-500 bg-gray-900/50 
                         border border-[#333] rounded"
            >
              <span className="text-md">⌘</span>K
            </kbd>
          </button>
        </div>

        {/* Add the search overlay */}
        <ResearchSearchOverlay
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
        />

        <div className="grid gap-12">
          {/* Featured Research Section */}
          <div>
            <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
              <span className="mr-2">📈</span> Featured Research
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 hover:border-blue-500/50 transition-all duration-300 backdrop-blur-sm">
                <div className="flex items-start space-x-4">
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-white mb-2">
                      Market Analysis Coming Soon
                    </h3>
                    <p className="text-gray-400">
                      In-depth market analysis and research will be published
                      here. Stay tuned for expert insights and detailed reports.
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 hover:border-purple-500/50 transition-all duration-300 backdrop-blur-sm">
                <div className="flex items-start space-x-4">
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-white mb-2">
                      Trading Strategies
                    </h3>
                    <p className="text-gray-400">
                      Discover proven trading strategies and analytical
                      frameworks from experienced traders.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Recent Research Section */}
          <div>
            <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
              <span className="mr-2">🔥</span> Recent Research
            </h2>
            <div className="grid md:grid-cols-3 gap-6">
              {isLoading ? (
                // Loading skeleton
                [...Array(3)].map((_, i) => (
                  <div
                    key={`loading-${i}`}
                    className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 animate-pulse"
                  >
                    <div className="h-32 bg-gray-700/50 rounded-lg mb-4" />
                    <div className="h-6 bg-gray-700/50 rounded mb-2 w-3/4" />
                    <div className="h-4 bg-gray-700/50 rounded w-full" />
                  </div>
                ))
              ) : recentPosts && recentPosts.length > 0 ? (
                recentPosts.map((post) => (
                  <div
                    key={post._id}
                    onClick={() =>
                      navigate({
                        to: "/research/$author/$slug",
                        params: { author: post.author, slug: post.slug },
                      })
                    }
                    className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 hover:border-blue-500/50 transition-all duration-300 backdrop-blur-sm cursor-pointer"
                  >
                    <div className="h-32 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-lg mb-4" />
                    <h3 className="text-lg font-semibold text-white mb-2 line-clamp-1">
                      {post.title}
                    </h3>
                    <p className="text-gray-400 text-sm line-clamp-2">
                      {post.description}
                    </p>
                    <div className="mt-4 flex items-center justify-between text-sm text-gray-500">
                      <span>{post.author}</span>
                      <span>
                        {new Date(post.publishedAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 col-span-3">
                  <p className="text-gray-400 text-center">
                    No research articles published yet.
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Liked Research Section - Always shown if user is logged in */}
          {userData?.userName && (
            <div>
              <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
                <span className="mr-2">❤️</span> Liked Research
              </h2>
              <div className="grid md:grid-cols-3 gap-6">
                {isLoadingLiked ? (
                  // Loading skeleton
                  [...Array(3)].map((_, i) => (
                    <div
                      key={`loading-liked-${i}`}
                      className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 animate-pulse"
                    >
                      <div className="h-32 bg-gray-700/50 rounded-lg mb-4" />
                      <div className="h-6 bg-gray-700/50 rounded mb-2 w-3/4" />
                      <div className="h-4 bg-gray-700/50 rounded w-full" />
                    </div>
                  ))
                ) : likedPosts && likedPosts.length > 0 ? (
                  likedPosts
                    .slice(-3)
                    .reverse()
                    .map((post) => (
                      <div
                        key={post._id}
                        onClick={() =>
                          navigate({
                            to: "/research/$author/$slug",
                            params: { author: post.author, slug: post.slug },
                          })
                        }
                        className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 hover:border-pink-500/50 transition-all duration-300 backdrop-blur-sm cursor-pointer"
                      >
                        <div className="h-32 bg-gradient-to-br from-pink-500/10 to-purple-500/10 rounded-lg mb-4" />
                        <h3 className="text-lg font-semibold text-white mb-2 line-clamp-1">
                          {post.title}
                        </h3>
                        <p className="text-gray-400 text-sm line-clamp-2">
                          {post.description}
                        </p>
                        <div className="mt-4 flex items-center justify-between text-sm text-gray-500">
                          <span>{post.author}</span>
                          <span>
                            {new Date(post.publishedAt).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="col-span-3 p-8 rounded-xl bg-gray-800/30 border border-gray-700 text-center">
                    <div className="text-md text-zinc-400 mb-3">
                      No liked posts yet
                    </div>
                    <p className="text-md text-zinc-500">
                      When you like research posts, they'll appear here for easy
                      access
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() =>
                  navigate({
                    to: "/research/$author",
                    params: { author: userData.userName },
                    search: { tab: "likes" },
                  })
                }
                className="mt-3 flex justify-end"
              >
                <button className="text-sm text-zinc-400 hover:text-blue-400 flex items-center gap-1.5 group">
                  View all liked posts
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5 transform group-hover:translate-x-0.5 transition-transform"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* My Research Section - Only shown if user is logged in */}
          {userData?.userName && (
            <div>
              <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
                <span className="mr-2">📚</span> My Research
              </h2>
              <div
                onClick={() =>
                  navigate({
                    to: "/research/$author",
                    params: { author: userData.userName },
                  })
                }
                className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 hover:border-blue-500/50 transition-all duration-300 backdrop-blur-sm cursor-pointer group"
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-white group-hover:text-blue-400 transition-colors">
                    View My Research Collection
                  </h3>
                  <span className="text-gray-400 group-hover:text-blue-400 transition-colors transform group-hover:translate-x-1 duration-300">
                    →
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
