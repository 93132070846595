import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

type PaginationMetadata = {
  currentPage: number
  totalPages: number
  totalItems: number
  itemsPerPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
}

type SearchMetadata = {
  query: string
  matchCount: number
}

type ResearchResponse = {
  posts: Post[]
  pagination: PaginationMetadata
  searchMetadata?: SearchMetadata | null
}

interface Post {
  _id: string
  title: string
  description: string
  content: any
  author: string
  publishedAt: string
  status: string
  slug: string
}

type ResearchContextProps = {
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  searchResults: Post[]
  isSearching: boolean
  searchNextPage: () => Promise<void>
  searchHasNextPage: boolean
  isSearchingNextPage: boolean
  searchResearch: (query: string) => Promise<void>
}

const ResearchContext = createContext<ResearchContextProps | undefined>(
  undefined,
)

export const ResearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState<Post[]>([])
  const [searchPage, setSearchPage] = useState(1)
  const [searchHasNextPage, setSearchHasNextPage] = useState(false)
  const [isSearchingNextPage, setIsSearchingNextPage] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  const { isLoading: isSearching } = useQuery({
    queryKey: ["researchSearch", searchQuery, 1],
    queryFn: async () => {
      if (!searchQuery) {
        setSearchResults([])
        return null
      }

      const response = await fetch(
        `${
          import.meta.env.VITE_MRKT_SERVER
        }/research/search?search=${encodeURIComponent(
          searchQuery,
        )}&page=1&limit=50`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to search research posts")
      }

      const data: ResearchResponse = await response.json()
      setSearchResults(data.posts)
      setSearchHasNextPage(data.pagination.hasNextPage)
      setSearchPage(1)
      return data
    },
    enabled: !!searchQuery,
  })

  const searchResearch = async (query: string) => {
    setSearchQuery(query)
  }

  const searchNextPage = async () => {
    if (!searchHasNextPage || isSearchingNextPage || !searchQuery) return

    setIsSearchingNextPage(true)
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_MRKT_SERVER
        }/research/search?search=${encodeURIComponent(
          searchQuery,
        )}&page=${searchPage + 1}&limit=50`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch next page of search results")
      }

      const data: ResearchResponse = await response.json()
      setSearchResults((prev) => [...prev, ...data.posts])
      setSearchHasNextPage(data.pagination.hasNextPage)
      setSearchPage(data.pagination.currentPage)
    } catch (err) {
      console.error("Error fetching next page of search results:", err)
    } finally {
      setIsSearchingNextPage(false)
    }
  }

  return (
    <ResearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchResults,
        isSearching,
        searchNextPage,
        searchHasNextPage,
        isSearchingNextPage,
        searchResearch,
      }}
    >
      {children}
    </ResearchContext.Provider>
  )
}

export const useResearch = (): ResearchContextProps => {
  const context = useContext(ResearchContext)
  if (!context) {
    throw new Error("useResearch must be used within a ResearchProvider")
  }
  return context
}
