import { useArticles } from "@/providers/ArticlesProvider"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { format } from "date-fns"
import { useNavigate } from "@tanstack/react-router"
import { useCallback, useEffect, useRef } from "react"
import { ExternalLink } from "lucide-react"

export function EquitiesNews() {
  const {
    stockArticles,
    isLoadingStocks,
    fetchNextStocksPage,
    hasNextStocksPage,
    isFetchingNextStocksPage,
  } = useArticles()
  const navigate = useNavigate()
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const onScroll = useCallback(() => {
    if (!scrollContainerRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current

    // Load more when user has scrolled to within 100px of the bottom
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      !isFetchingNextStocksPage &&
      hasNextStocksPage
    ) {
      fetchNextStocksPage()
    }
  }, [fetchNextStocksPage, hasNextStocksPage, isFetchingNextStocksPage])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", onScroll)
      return () => scrollContainer.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  if (isLoadingStocks) {
    return (
      <Card className="h-full w-full bg-inherit border-none p-0">
        <CardHeader className="p-2">
          <CardTitle className="text-lg font-medium text-white">
            <Skeleton
              width={150}
              height={24}
              baseColor="#222"
              highlightColor="#333"
            />
          </CardTitle>
        </CardHeader>
        <CardContent className="p-4 h-[calc(100%-3rem)]">
          {[...Array(8)].map((_, i) => (
            <Skeleton
              key={i}
              height={104}
              className="mb-2"
              baseColor="#222"
              highlightColor="#333"
            />
          ))}
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className="h-full w-full bg-inherit border-none p-0">
      <CardHeader className="p-2">
        <CardTitle className="text-lg font-medium text-white">
          Market News
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0 h-[calc(100%-3rem)]">
        <SimpleBar
          scrollableNodeProps={{ ref: scrollContainerRef }}
          className="h-full"
        >
          <div className="space-y-2 p-4">
            {stockArticles.map((article) => (
              <div
                key={article.url}
                onClick={(e) => {
                  e.preventDefault()
                  navigate({
                    to: "/equities/symbol/$symbol",
                    params: { symbol: article.symbol },
                  })
                }}
                className="group block cursor-pointer"
              >
                <div
                  className="relative flex flex-col p-3 rounded-lg
                    border border-white/5 
                    bg-black/20
                    hover:bg-black/30
                    hover:border-white/10
                    transition-all duration-200"
                >
                  <div className="flex gap-3 mb-2">
                    <div className="w-8 h-8 flex-shrink-0">
                      <img
                        src={`https://financialmodelingprep.com/image-stock/${article.symbol}.png`}
                        alt={`${article.symbol} logo`}
                        className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement
                          target.style.display = "none"
                          target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50 bg-white/5 rounded-md">${article.symbol}</div>`
                        }}
                      />
                    </div>
                    <h3 className="flex-1 text-sm font-medium text-white/80 leading-snug pr-6 group-hover:text-white transition-colors duration-200">
                      {article.title}
                    </h3>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 flex-wrap text-white/40">
                      <span className="text-[10px] font-medium tracking-wider uppercase bg-white/5 px-2 py-0.5 rounded-sm">
                        {article.symbol}
                      </span>
                      <span className="text-[10px] tracking-wide">
                        {format(
                          new Date(article.publishedDate),
                          "MMM d, HH:mm",
                        )}
                      </span>
                      <div
                        className={`h-1.5 w-1.5 rounded-full ${
                          article.sentiment === "Positive"
                            ? "bg-emerald-500/50"
                            : article.sentiment === "Negative"
                              ? "bg-red-500/50"
                              : "bg-white/20"
                        }`}
                      />
                    </div>
                    <div
                      className="flex items-center"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open(article.url, "_blank")
                      }}
                    >
                      <ExternalLink className="w-4 h-4 text-white/30 hover:text-white/60 transition-colors duration-200" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {isFetchingNextStocksPage && (
              <div className="py-4">
                <Skeleton
                  height={104}
                  className="mb-2"
                  baseColor="#222"
                  highlightColor="#333"
                />
              </div>
            )}
          </div>
        </SimpleBar>
      </CardContent>
    </Card>
  )
}
