import { useEffect, useState } from "react"
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { Button } from "@/components/ui/button"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { cn } from "@/lib/utils"

type TimeRange = "1D" | "1W" | "1M" | "3M" | "1Y" | "ALL"
const timeRanges: TimeRange[] = ["1D", "1W", "1M", "3M", "1Y", "ALL"]

interface DataPoint {
  date: number
  close: number
  symbol: string
  percentChange: number
}

interface MultiSymbolChartProps {
  symbols: string[]
  symbolLabels: Record<string, string>
  symbolColors?: Record<string, string>
  height?: number
  defaultVisibleSymbols?: string[]
  title?: string
  colors?: string[]
}

const ChartSkeleton = ({
  height = 400,
  title,
  symbols,
}: {
  height?: number
  title?: string
  symbols: string[]
}) => {
  return (
    <div className="flex flex-col h-full animate-pulse">
      {title && <div className="mb-2 h-5 w-32 bg-white/5 rounded" />}

      {/* Time range buttons skeleton */}
      <div className="flex gap-2 mb-4">
        {timeRanges.map((range) => (
          <div key={range} className="h-7 w-12 bg-white/5 rounded" />
        ))}
      </div>

      {/* Chart area skeleton with gradient effect */}
      <div className="flex-grow relative overflow-hidden">
        <div
          className="w-full bg-gradient-to-b from-white/5 to-white/[0.02] rounded-lg"
          style={{ height }}
        >
          {/* Fake chart lines */}
          <div className="absolute inset-0 flex flex-col justify-between py-8">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="w-full h-px bg-white/5" />
            ))}
          </div>
        </div>
      </div>

      {/* Symbol buttons skeleton */}
      <div className="flex flex-wrap gap-3 mt-4">
        {symbols.map((symbol) => (
          <div key={symbol} className="h-7 w-24 bg-white/5 rounded" />
        ))}
      </div>
    </div>
  )
}

export const MultiSymbolChart = ({
  symbols,
  symbolLabels,
  symbolColors = {},
  height = 400,
  defaultVisibleSymbols,
  title,
  colors,
}: MultiSymbolChartProps) => {
  const [selectedRange, setSelectedRange] = useState<TimeRange>("1W")
  const [visibleSymbols, setVisibleSymbols] = useState<Set<string>>(
    new Set(defaultVisibleSymbols || symbols),
  )
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setVisibleSymbols(new Set(defaultVisibleSymbols || symbols))
  }, [symbols, defaultVisibleSymbols])

  const defaultColors: { [key: string]: string } = {
    SPY: "rgba(255, 255, 255, 0.7)",
    QQQ: "rgba(147, 197, 253, 0.7)",
    DIA: "rgba(167, 139, 250, 0.7)",
    IWM: "rgba(248, 113, 113, 0.7)",
    VIX: "rgba(251, 191, 36, 0.7)",
    ...symbolColors,
  }

  const toggleSymbol = (symbol: string) => {
    setVisibleSymbols((prev) => {
      const next = new Set(prev)
      if (next.has(symbol) && next.size > 1) {
        next.delete(symbol)
      } else if (!next.has(symbol)) {
        next.add(symbol)
      }
      return next
    })
  }

  const fetchPriceData = async (symbol: string) => {
    try {
      let endpoint = ""
      const now = new Date()

      switch (selectedRange) {
        case "1D":
          const threeDaysAgo = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/5min?from=${format(threeDaysAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "1W":
          const weekAgo = new Date(now.setDate(now.getDate() - 7))
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/15min?from=${format(weekAgo, "yyyy-MM-dd")}`
          break
        case "1M":
          const monthAgo = new Date(now.setMonth(now.getMonth() - 1))
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(monthAgo, "yyyy-MM-dd")}`
          break
        case "3M":
          const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3))
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(threeMonthsAgo, "yyyy-MM-dd")}`
          break
        case "1Y":
          const yearAgo = new Date(now.setFullYear(now.getFullYear() - 1))
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}?from=${format(yearAgo, "yyyy-MM-dd")}`
          break
        case "ALL":
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}`
          break
      }

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch price data")
      }

      const data = await response.json()
      const isIntraday = ["1D", "1W", "1M", "3M"].includes(selectedRange)

      let prices = isIntraday
        ? data
        : data.historical.map((item: any) => ({
            date: item.date,
            close: item.adjClose || item.close,
          }))

      prices = prices.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
      )

      const firstPrice = prices[0].close
      return prices.map((price: any) => ({
        date: price.date,
        symbol,
        close: price.close,
        percentChange: ((price.close - firstPrice) / firstPrice) * 100,
      }))
    } catch (error) {
      console.error(`Error fetching price data for ${symbol}:`, error)
      throw error
    }
  }

  const { data: combinedData, isLoading } = useQuery({
    queryKey: ["multiSymbolChart", selectedRange, symbols.join()],
    queryFn: async () => {
      try {
        const allData = await Promise.all(
          symbols.map((symbol) => fetchPriceData(symbol)),
        )

        const allTimestamps = [
          ...new Set(
            allData.flat().map((d: DataPoint) => new Date(d.date).getTime()),
          ),
        ].sort((a, b) => a - b)

        const processedData = symbols.map((symbol, index) => {
          const symbolData = allData[index]
          const firstPrice = symbolData[0].close

          return symbolData.map((d: DataPoint) => ({
            date: new Date(d.date).getTime(),
            symbol,
            close: d.close,
            percentChange: ((d.close - firstPrice) / firstPrice) * 100,
          }))
        })

        const combined = allTimestamps.map((timestamp) => {
          const dataPoint: any = { date: new Date(timestamp).toISOString() }

          symbols.forEach((symbol, index) => {
            const symbolData = processedData[index]
            const matchingPoint = symbolData.find(
              (d: DataPoint) => d.date === timestamp,
            )
            if (matchingPoint) {
              dataPoint[`${symbol}_percentChange`] = matchingPoint.percentChange
            }
          })

          return dataPoint
        })

        // Filter to only show last 24 hours if selectedRange is "1D"
        const filteredData =
          selectedRange === "1D"
            ? combined.filter((point) => {
                const pointTime = new Date(point.date).getTime()
                // Get the latest timestamp from the data
                const latestTime = Math.max(...allTimestamps)
                const twentyFourHoursBeforeLatest =
                  latestTime - 24 * 60 * 60 * 1000
                return pointTime > twentyFourHoursBeforeLatest
              })
            : combined

        return filteredData.filter((point) =>
          symbols.every(
            (symbol) => point[`${symbol}_percentChange`] !== undefined,
          ),
        )
      } catch (error) {
        console.error("Error processing market indices data:", error)
        throw error
      }
    },
    refetchInterval: selectedRange === "1D" ? 60000 : false,
    refetchOnWindowFocus: selectedRange === "1D",
  })

  const formatXAxis = (value: string) => {
    const date = new Date(value)
    if (selectedRange === "1D") {
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    }
    return date.toLocaleDateString([], { month: "short", day: "numeric" })
  }

  const getSymbolColor = (symbol: string) => {
    if (colors && colors.length > 0) {
      return colors[0]
    }
    const defaultColorKeys = Object.keys(defaultColors)
    const index = symbols.indexOf(symbol)
    return (
      symbolColors[symbol] ||
      defaultColors[defaultColorKeys[index % defaultColorKeys.length]]
    )
  }

  const isVixVisible = visibleSymbols.has("^VIX")
  const hasVix = symbols.includes("^VIX")

  if (isLoading) {
    return <ChartSkeleton height={height} title={title} symbols={symbols} />
  }

  if (!combinedData || combinedData.length === 0) {
    return (
      <div className="flex flex-col h-full">
        {title && (
          <h2 className="text-white text-sm font-medium mb-2">{title}</h2>
        )}
        <div className="flex gap-2 mb-2 text-xs">
          {timeRanges.map((range) => (
            <button
              key={range}
              onClick={() => setSelectedRange(range)}
              className={cn(
                "px-2 py-1 rounded transition-colors",
                selectedRange === range
                  ? "bg-white/10 text-white"
                  : "text-gray-400 hover:text-white",
              )}
            >
              {range}
            </button>
          ))}
        </div>
        <div className="flex-grow flex items-center justify-center text-gray-400 text-sm">
          No data available for selected time range
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      {title && (
        <h2 className="text-white text-sm font-medium mb-2">{title}</h2>
      )}
      <div className="flex gap-2 mb-2 text-xs">
        {timeRanges.map((range) => (
          <button
            key={range}
            onClick={() => setSelectedRange(range)}
            className={cn(
              "px-2 py-1 rounded transition-colors",
              selectedRange === range
                ? "bg-white/10 text-white"
                : "text-gray-400 hover:text-white",
            )}
          >
            {range}
          </button>
        ))}
      </div>

      <div className="flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={combinedData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              minTickGap={50}
              stroke="transparent"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              yAxisId="indices"
              domain={["auto", "auto"]}
              tickFormatter={(value) => `${value.toFixed(2)}%`}
              width={0}
              stroke="transparent"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            {hasVix && (
              <YAxis
                yAxisId="vix"
                orientation="right"
                domain={["auto", "auto"]}
                tickFormatter={(value) => `${value.toFixed(2)}%`}
                width={0}
                stroke="transparent"
                tick={false}
                axisLine={false}
                tickLine={false}
              />
            )}
            <Tooltip
              formatter={(value: number, name: string) => [
                `${value.toFixed(2)}%`,
                name.replace("_percentChange", ""),
              ]}
              labelFormatter={(label) => new Date(label).toLocaleString()}
              contentStyle={{
                backgroundColor: "rgba(0,0,0,0.7)",
                backdropFilter: "blur(8px)",
                border: "1px solid rgba(255,255,255,0.05)",
                borderRadius: "4px",
                color: "#fff",
                padding: "8px",
                fontSize: "12px",
              }}
            />
            {symbols
              .filter((symbol) => visibleSymbols.has(symbol))
              .map((symbol) => (
                <Area
                  key={symbol}
                  type="monotone"
                  dataKey={`${symbol}_percentChange`}
                  name={symbol}
                  stroke={getSymbolColor(symbol)}
                  strokeWidth={1.5}
                  fillOpacity={0}
                  isAnimationActive={false}
                  dot={false}
                  yAxisId={symbol === "^VIX" ? "vix" : "indices"}
                  activeDot={{
                    r: 4,
                    fill: "#fff",
                    stroke: "rgba(255, 255, 255, 0.2)",
                    strokeWidth: 8,
                  }}
                />
              ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="flex flex-wrap gap-3 mt-2">
        {symbols.map((symbol) => (
          <button
            key={symbol}
            onClick={() => toggleSymbol(symbol)}
            className={cn(
              "flex items-center gap-1.5 px-2 py-1 rounded transition-all text-xs",
              visibleSymbols.has(symbol)
                ? "text-white"
                : "text-gray-500 opacity-50 hover:opacity-75",
            )}
          >
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: getSymbolColor(symbol) }}
            />
            {symbolLabels[symbol]}
          </button>
        ))}
      </div>
    </div>
  )
}
