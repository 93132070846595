import { createFileRoute } from "@tanstack/react-router"
import { HomescreenHeader } from "../presentation/screens/homescreen/homescreen"
import { EconomicCalendar } from "../presentation/screens/homescreen/components/EconomicCalendar"
import { MarketHeadlines } from "../presentation/screens/homescreen/components/MarketHeadlines"
import { useState } from "react"
import { Navbar } from "@/components/Navbar"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

export const Route = createFileRoute("/fx")({
  component: FXComponent,
})

function FXComponent() {
  const [isPrioritized, setIsPrioritized] = useState(true)

  const toggleView = () => {
    setIsPrioritized(!isPrioritized)
  }

  return (
    <div className="bg-black h-screen flex flex-col">
      <Navbar />
      <SimpleBar className="flex-1 w-full">
        <div className="px-12 sm:px-6 lg:px-12 mx-auto w-full">
          <div className="flex flex-col">
            <div className="mt-2">
              <HomescreenHeader />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 gap-4 mt-6">
              <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
                <EconomicCalendar />
              </div>
              <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
                <MarketHeadlines />
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  )
}
