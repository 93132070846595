import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  ReferenceDot,
  Area,
  XAxis,
} from "recharts"
import { format } from "date-fns"

interface BullBearChartProps {
  symbol: string
  bullishTarget: number
  bearishTarget: number
}

export function BullBearTargetChart({
  symbol,
  bullishTarget,
  bearishTarget,
}: BullBearChartProps) {
  const { getAccessTokenSilently } = useAuth0()

  const { data: stockPriceData, isLoading } = useQuery({
    queryKey: ["stockPrice", symbol, "6M"],
    retry: false,
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        const now = new Date()
        const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6))

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}?from=${format(sixMonthsAgo, "yyyy-MM-dd")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (!response.ok) {
          throw new Error("Failed to fetch price data")
        }
        const data = await response.json()
        if (!data?.historical?.length) {
          throw new Error("No historical price data available")
        }
        return data.historical.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime(),
        )
      } catch (error) {
        console.error("Error fetching stock price data:", error)
        throw error
      }
    },
  })

  if (isLoading || !stockPriceData?.length) {
    return <div className="h-[200px] bg-white/5 rounded-lg animate-pulse" />
  }

  try {
    const currentPrice = stockPriceData[stockPriceData.length - 1]?.close
    if (!currentPrice || !bullishTarget || !bearishTarget) {
      return (
        <div className="h-[200px] bg-white/5 rounded-lg flex items-center justify-center text-white/50 text-sm">
          Unable to display price projections
        </div>
      )
    }

    const lastHistoricalDate = new Date(
      stockPriceData[stockPriceData.length - 1]?.date,
    )

    // Instead of curved paths, create simple linear projections
    const projectionDays = 365 // 12 months
    const bufferDays = 30 // increased buffer for more spacing
    const numPoints = 30
    const msPerDay = 24 * 60 * 60 * 1000
    const volatility = Math.abs(bullishTarget - currentPrice) * 0.2 // increased volatility

    const projectionStartDate = new Date(
      lastHistoricalDate.getTime() + bufferDays * msPerDay,
    )
    const projectionEndDate = new Date(
      lastHistoricalDate.getTime() + (bufferDays + projectionDays) * msPerDay,
    )

    // Create linear projection data
    const projectionData = Array.from({ length: numPoints }, (_, index) => {
      const timeProgress = index / (numPoints - 1)
      const pointDate = new Date(
        projectionStartDate.getTime() +
          (projectionEndDate.getTime() - projectionStartDate.getTime()) *
            timeProgress,
      )

      // Create market structure patterns
      const bullishExpected =
        currentPrice + (bullishTarget - currentPrice) * timeProgress
      const bearishExpected =
        currentPrice + (bearishTarget - currentPrice) * timeProgress

      // Create higher highs and higher lows for bullish trend
      const bullishWave =
        Math.sin(timeProgress * Math.PI * 4) * (volatility * 0.5)
      const bullishTrend =
        Math.max(0, Math.sin(timeProgress * Math.PI * 2)) * volatility

      // Create lower highs and lower lows for bearish trend
      const bearishWave =
        Math.sin(timeProgress * Math.PI * 4) * (volatility * 0.5)
      const bearishTrend =
        Math.min(0, Math.sin(timeProgress * Math.PI * 2)) * volatility

      // Ensure last point hits target exactly
      const bullish =
        index === numPoints - 1
          ? bullishTarget
          : bullishExpected + bullishWave + bullishTrend
      const bearish =
        index === numPoints - 1
          ? bearishTarget
          : bearishExpected + bearishWave + bearishTrend

      return {
        date: pointDate.toISOString(),
        bullish,
        bearish,
      }
    })

    // Combine historical and projection data
    const combinedData = [
      ...stockPriceData.map((d: any) => ({
        date: d.date,
        close: d.close,
      })),
      {
        date: new Date(
          lastHistoricalDate.getTime() + (bufferDays / 2) * msPerDay,
        ).toISOString(),
        close: null,
        bullish: null,
        bearish: null,
      },
      ...projectionData,
    ]

    return (
      <div className="h-[250px] relative">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={combinedData}>
            <defs>
              <linearGradient id="bullishGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#22c55e" stopOpacity={0.15} />
                <stop offset="100%" stopColor="#22c55e" stopOpacity={0.05} />
              </linearGradient>
              <linearGradient id="bearishGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ef4444" stopOpacity={0.15} />
                <stop offset="100%" stopColor="#ef4444" stopOpacity={0.05} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="date"
              tick={{ fill: "rgba(255, 255, 255, 0.4)", fontSize: 10 }}
              axisLine={false}
              tickLine={false}
              tickFormatter={(date) => format(new Date(date), "MMM d")}
              interval="preserveStartEnd"
              dy={10}
            />

            <YAxis
              domain={[
                (dataMin: number) => dataMin * 0.95,
                (dataMax: number) => dataMax * 1.05,
              ]}
              tick={{ fill: "rgba(255, 255, 255, 0.4)", fontSize: 10 }}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `$${value.toFixed(0)}`}
              orientation="right"
              width={35}
              tickCount={5}
              allowDecimals={false}
              dx={-5}
            />

            {/* Historical Price */}
            <Line
              type="monotone"
              dataKey="close"
              stroke="rgba(255, 255, 255, 0.7)"
              strokeWidth={1.5}
              dot={false}
            />

            {/* Bullish Projection */}
            <Line
              type="linear"
              dataKey="bullish"
              stroke="rgba(34, 197, 94, 0.7)"
              strokeWidth={2}
              dot={false}
              connectNulls={true}
            />

            {/* Bearish Projection */}
            <Line
              type="linear"
              dataKey="bearish"
              stroke="rgba(239, 68, 68, 0.7)"
              strokeWidth={2}
              dot={false}
              connectNulls={true}
            />

            {/* Target Points */}
            <ReferenceDot
              x={combinedData[combinedData.length - 1]?.date}
              y={bullishTarget}
              r={4}
              fill="#22c55e"
              stroke="rgba(255, 255, 255, 0.2)"
              strokeWidth={2}
            />
            <ReferenceDot
              x={combinedData[combinedData.length - 1]?.date}
              y={bearishTarget}
              r={4}
              fill="#ef4444"
              stroke="rgba(255, 255, 255, 0.2)"
              strokeWidth={2}
            />

            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null

                const data = payload[0].payload
                return (
                  <div className="bg-black/85 backdrop-blur-md p-2 rounded border border-white/10 text-sm">
                    {data.close && (
                      <div className="text-white">
                        Price: ${data.close?.toFixed(2)}
                      </div>
                    )}
                    {data.bullish && (
                      <div className="text-emerald-400">
                        Bull Target: ${data.bullish?.toFixed(2)}
                      </div>
                    )}
                    {data.bearish && (
                      <div className="text-red-400">
                        Bear Target: ${data.bearish?.toFixed(2)}
                      </div>
                    )}
                  </div>
                )
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  } catch (error) {
    console.error("Error rendering chart:", error)
    return (
      <div className="h-[200px] bg-white/5 rounded-lg flex items-center justify-center text-white/50 text-sm">
        Unable to display chart
      </div>
    )
  }
}
