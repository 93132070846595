import { useState } from "react"

interface StockLogoProps {
  symbol: string
  size?: "sm" | "md" | "lg"
  className?: string
}

const sizeClasses = {
  sm: "w-8 h-8",
  md: "w-10 h-10",
  lg: "w-12 h-12",
}

export function StockLogo({
  symbol,
  size = "md",
  className = "",
}: StockLogoProps) {
  const [imageError, setImageError] = useState(false)

  if (imageError) {
    return (
      <div
        className={`${sizeClasses[size]} rounded-lg bg-black/40 border border-white/[0.08]
          backdrop-blur-sm flex items-center justify-center
          text-white/60 font-medium text-sm
          shadow-inner shadow-black/20
          ${className}`}
      >
        {symbol.slice(0, 2)}
      </div>
    )
  }

  return (
    <div
      className={`${sizeClasses[size]} relative group
        rounded-lg overflow-hidden
        before:absolute before:inset-0 
        before:bg-gradient-to-br before:from-white/10 before:to-transparent
        before:opacity-0 before:group-hover:opacity-100
        before:transition-opacity before:duration-200
        ${className}`}
    >
      <div className="absolute inset-0 bg-black/40 backdrop-blur-sm" />
      <div
        className="absolute inset-[1px] rounded-[7px]
          bg-gradient-to-br from-white/[0.15] to-white/[0.05]
          border border-white/[0.05]"
      >
        <img
          src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
          alt={`${symbol} logo`}
          className="w-full h-full object-contain p-2 relative z-10"
          onError={() => setImageError(true)}
        />
      </div>
    </div>
  )
}
