import { useEffect, useState, useRef } from "react"
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Bar,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { motion } from "framer-motion"
import { cn } from "@/lib/utils"

function formatMarketCap(marketCap: number): string {
  if (marketCap >= 1e12) return `$${(marketCap / 1e12).toFixed(2)}T`
  if (marketCap >= 1e9) return `$${(marketCap / 1e9).toFixed(2)}B`
  if (marketCap >= 1e6) return `$${(marketCap / 1e6).toFixed(2)}M`
  return `$${marketCap.toFixed(2)}`
}

interface PriceData {
  date: string
  open: number
  high: number
  low: number
  close: number
  volume: number
}

type TimeRange = "1D" | "1W" | "1M" | "3M" | "1Y" | "ALL"

const timeRanges: TimeRange[] = ["1D", "1W", "1M", "3M", "1Y", "ALL"]

interface StockChartProps {
  symbol: string
  profile?: {
    mktCap: number
    volAvg: number
    exchange: string
  }
}

interface MousePosition {
  x: number
  y: number
}

export function StockChart({ symbol, profile }: StockChartProps) {
  const [selectedRange, setSelectedRange] = useState<TimeRange>("1W")
  const { getAccessTokenSilently } = useAuth0()
  const [mousePosition, setMousePosition] = useState<MousePosition | null>(null)
  const chartRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect()
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      })
    }
  }

  const handleMouseLeave = () => {
    setMousePosition(null)
  }

  const { data: priceData, isLoading } = useQuery({
    queryKey: ["stockPrice", symbol, selectedRange],
    queryFn: async () => {
      try {
        let endpoint = ""
        const now = new Date()

        switch (selectedRange) {
          case "1D":
            const twoDaysAgo = new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000)
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/5min?from=${format(twoDaysAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
            break
          case "1W":
            const weekAgo = new Date(now.setDate(now.getDate() - 7))
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/15min?from=${format(weekAgo, "yyyy-MM-dd")}`
            break
          case "1M":
            const monthAgo = new Date(now.setMonth(now.getMonth() - 1))
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(monthAgo, "yyyy-MM-dd")}`
            break
          case "3M":
            const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3))
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(threeMonthsAgo, "yyyy-MM-dd")}`
            break
          case "1Y":
            const yearAgo = new Date(now.setFullYear(now.getFullYear() - 1))
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}?from=${format(yearAgo, "yyyy-MM-dd")}`
            break
          case "ALL":
            endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}`
            break
        }

        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })

        if (!response.ok) {
          throw new Error("Failed to fetch price data")
        }

        const data = await response.json()

        // For intraday data, sort by date in descending order
        if (selectedRange === "1D") {
          const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)
          return data
            .filter((item: PriceData) => new Date(item.date) >= oneDayAgo)
            .sort(
              (a: PriceData, b: PriceData) =>
                new Date(a.date).getTime() - new Date(b.date).getTime(),
            )
        } else if (
          selectedRange === "1W" ||
          selectedRange === "1M" ||
          selectedRange === "3M"
        ) {
          return data.sort(
            (a: PriceData, b: PriceData) =>
              new Date(a.date).getTime() - new Date(b.date).getTime(),
          )
        }

        // For historical data, sort and map the data
        return data.historical.sort(
          (a: PriceData, b: PriceData) =>
            new Date(a.date).getTime() - new Date(b.date).getTime(),
        )
      } catch (error) {
        console.error("Error fetching price data:", error)
        throw error
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
    retry: 1,
  })

  // Add debug logging
  console.log("Price data:", priceData)

  const formatXAxis = (value: string) => {
    const date = new Date(value)
    if (selectedRange === "1D") {
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    }
    return date.toLocaleDateString([], { month: "short", day: "numeric" })
  }

  const formatTooltip = (value: number) => {
    return `$${value.toFixed(2)}`
  }

  const formatVolumeTooltip = (value: number) => {
    return value.toLocaleString()
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!priceData || priceData.length === 0) {
    return <div>No data available</div>
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.002 }}
      className="relative group w-full"
    >
      {/* Gradient border effect - reduced opacity */}
      <div className="absolute inset-0 bg-gradient-to-r from-white/5 via-white/2 to-transparent rounded-xl blur-xl group-hover:opacity-50 opacity-0 transition-opacity duration-500" />

      {/* Glassmorphic container */}
      <div className="relative backdrop-blur-xl bg-black/10 border border-white/5 rounded-xl overflow-hidden">
        {/* Time range buttons container */}
        <div className="flex items-center justify-between mb-6 relative z-10 p-6">
          {/* Time range buttons on the left */}
          <div className="flex gap-2">
            {timeRanges.map((range) => (
              <Button
                key={range}
                variant={selectedRange === range ? "default" : "ghost"}
                onClick={() => setSelectedRange(range)}
                size="sm"
                className={cn(
                  "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                  selectedRange === range
                    ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                    : "text-gray-500 hover:bg-white/5 hover:text-white",
                )}
              >
                {range}
              </Button>
            ))}
          </div>

          {/* Market data on the right */}
          {profile && (
            <div className="flex items-center gap-6 text-sm">
              <div className="flex items-center gap-2">
                <span className="text-gray-400">Cap</span>
                <span className="text-white font-medium">
                  {formatMarketCap(profile.mktCap)}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400">Vol</span>
                <span className="text-white font-medium">
                  {profile.volAvg.toLocaleString()}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400">Exc</span>
                <span className="text-white font-medium">
                  {profile.exchange}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Chart container with reduced hover animation */}
        <motion.div
          ref={chartRef}
          className="h-[400px] w-full relative"
          whileHover={{ scale: 1.001 }}
          transition={{ duration: 0.3 }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/[0.01] to-transparent opacity-0 group-hover:opacity-50 transition-opacity duration-500" />

          {/* Spotlight effect */}
          {mousePosition && (
            <div
              className="pointer-events-none absolute inset-0"
              style={{
                background: `radial-gradient(circle 60px at ${mousePosition.x}px ${mousePosition.y}px, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)`,
                transition: "opacity 0.15s ease",
              }}
            />
          )}

          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={priceData}>
              <defs>
                <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#ffffff" stopOpacity={0.1} />
                  <stop offset="100%" stopColor="#ffffff" stopOpacity={0.02} />
                </linearGradient>
                <linearGradient id="volumeGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="rgba(255, 255, 255, 0.15)" />
                  <stop offset="100%" stopColor="rgba(255, 255, 255, 0.05)" />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxis}
                minTickGap={50}
                stroke="transparent"
                tick={false}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                yAxisId="price"
                domain={["auto", "auto"]}
                tickFormatter={formatTooltip}
                width={0}
                stroke="transparent"
                tick={false}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                yAxisId="volume"
                orientation="right"
                tickFormatter={formatVolumeTooltip}
                domain={["auto", "auto"]}
                width={0}
                stroke="transparent"
                tick={false}
                axisLine={false}
                tickLine={false}
                hide={true}
              />
              <Tooltip
                formatter={(value, name) => {
                  if (name === "Volume") {
                    return [value.toLocaleString(), name]
                  }
                  return [`$${Number(value).toFixed(2)}`, name]
                }}
                labelFormatter={(label) => new Date(label).toLocaleString()}
                contentStyle={{
                  backgroundColor: "rgba(0,0,0,0.85)",
                  backdropFilter: "blur(8px)",
                  border: "1px solid rgba(255,255,255,0.1)",
                  borderRadius: "4px",
                  color: "#fff",
                  padding: "8px",
                  fontSize: "12px",
                }}
              />
              <Area
                yAxisId="price"
                type="monotone"
                dataKey="close"
                name="Price"
                stroke="rgba(255, 255, 255, 0.7)"
                strokeWidth={1.5}
                fillOpacity={1}
                fill="url(#chartGradient)"
                isAnimationActive={false}
                dot={false}
                activeDot={{
                  r: 4,
                  fill: "#fff",
                  stroke: "rgba(255, 255, 255, 0.2)",
                  strokeWidth: 8,
                }}
              />
              <Bar
                yAxisId="volume"
                dataKey="volume"
                name="Volume"
                fill="url(#volumeGradient)"
                stroke="none"
                barSize={1}
                radius={[0, 0, 0, 0]}
                opacity={0.2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </motion.div>
      </div>
    </motion.div>
  )
}
