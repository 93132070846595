import { XCircle, X, Sparkles } from "lucide-react"
import { DNA } from "react-loader-spinner"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { motion } from "framer-motion"
import { Resizable } from "re-resizable"
import Flag from "react-flagkit"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useState, useEffect } from "react"

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const highlightText = (text: string, keyPoints: string[]) => {
  let highlightedText = text
  keyPoints.forEach((point) => {
    const escapedPoint = escapeRegExp(point)
    const regex = new RegExp(escapedPoint, "gi")
    highlightedText = highlightedText.replace(
      regex,
      `<span class="bg-yellow-500/30 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
    )
  })
  return (
    <p
      className="text-gray-300"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  )
}

const SummaryLoadingSkeleton = ({ messages }: { messages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 2000) // Change message every 2 seconds

    return () => clearInterval(interval)
  }, [messages.length])

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center justify-center py-8">
        <div className="flex gap-1">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ color: "orange" }}
            wrapperClass="dna-wrapper"
          />
        </div>
        <h2 className="text-xl font-medium text-white mb-4">
          {messages[currentIndex]}
        </h2>
      </div>

      <div className="space-y-6">
        {[...Array(6)].map((_, index) => (
          <div key={index}>
            <Skeleton
              height={24}
              width={160}
              className="mb-3"
              baseColor="#27272a"
              highlightColor="#3f3f46"
            />
            <div className="space-y-2">
              <Skeleton
                height={16}
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
              <Skeleton
                height={16}
                width="75%"
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const getCountryFlag = (countryCode: string) => {
  const flagMap: Record<string, string> = {
    US: "US", // United States
    EU: "EU", // European Union
    DE: "DE", // Germany
    FR: "FR", // France
    ES: "ES", // Spain
    JP: "JP", // Japan
    UK: "GB", // United Kingdom (uses GB code for flag)
    CN: "CN", // China
    IN: "IN", // India
    AU: "AU", // Australia
    NZ: "NZ", // New Zealand
    RU: "RU", // Russia
  }

  return <Flag country={flagMap[countryCode] || countryCode} size={21} />
}

const getRegionCountryCode = (region: string): string => {
  const regionMap: Record<string, string> = {
    "United States": "US",
    "European Union": "EU",
    Germany: "DE",
    France: "FR",
    Spain: "ES",
    Japan: "JP",
    "United Kingdom": "UK",
    China: "CN",
    India: "IN",
    Australia: "AU",
    "New Zealand": "NZ",
    Russia: "RU",
  }

  return regionMap[region] || ""
}

interface GlobalEconomyAnalysisPanelProps {
  analysis: any
  isLoading: boolean
  onClose: () => void
  region: string
}

export const GlobalEconomyAnalysisPanel = ({
  analysis,
  isLoading,
  onClose,
  region,
}: GlobalEconomyAnalysisPanelProps) => {
  const loadingMessages = [
    "Analyzing economic indicators...",
    "Evaluating market trends...",
    "Processing regional data...",
    "Synthesizing insights...",
    "Generating comprehensive analysis...",
  ]

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: 500, height: "100%" }}
        minWidth={500}
        maxWidth={1000}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleClasses={{
          left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 border-b border-[#333] bg-black/50 backdrop-blur-sm">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-purple-500/10 border border-purple-500/20">
                <Sparkles className="w-5 h-5 text-purple-400" />
              </div>
              <div>
                <div className="flex items-center gap-2 mb-1">
                  {getCountryFlag(getRegionCountryCode(region))}
                  <h2 className="text-xl font-semibold text-white">
                    {region} Economic State
                  </h2>
                </div>
              </div>
            </div>
            <button
              onClick={onClose}
              className="flex items-center justify-center w-8 h-8 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <SimpleBar
            className="flex-1 overflow-y-auto"
            style={{ height: "100%" }}
            autoHide={false}
          >
            <div className="p-6 pb-12">
              {isLoading ? (
                <SummaryLoadingSkeleton messages={loadingMessages} />
              ) : analysis ? (
                <div className="space-y-8">
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Current Economic State
                    </h3>
                    {highlightText(
                      analysis.economic_state.text,
                      analysis.economic_state.key_points,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Inflation Expectations
                    </h3>
                    {highlightText(
                      analysis.inflation_expectations.text,
                      analysis.inflation_expectations.key_points,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Monetary Policy Outlook
                    </h3>
                    {highlightText(
                      analysis.monetary_policy_outlook.text,
                      analysis.monetary_policy_outlook.key_points,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Growth Trajectory
                    </h3>
                    {highlightText(
                      analysis.growth_trajectory.text,
                      analysis.growth_trajectory.key_points,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Risks and Opportunities
                    </h3>
                    {highlightText(
                      analysis.risks_and_opportunities.text,
                      analysis.risks_and_opportunities.key_points,
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-12">
                  <XCircle className="w-12 h-12 text-red-500/70 mb-4" />
                  <h3 className="text-lg font-medium text-white mb-2">
                    Analysis Failed
                  </h3>
                  <p className="text-zinc-400 text-sm">
                    Unable to generate analysis. Please try again later.
                  </p>
                </div>
              )}
            </div>
          </SimpleBar>
        </div>
      </Resizable>
    </motion.div>
  )
}
