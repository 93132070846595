import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  ReferenceLine,
  Area,
  XAxis,
} from "recharts"
import { format } from "date-fns"

interface SupportResistanceChartProps {
  symbol: string
  supportLevel: number[]
  resistanceLevel: number[]
  yearlyLow?: number
  yearlyHigh?: number
  highlightedLevel?: number
}

interface ChartDataPoint {
  date: number
  close: number
}

export function SupportResistanceChart({
  symbol,
  supportLevel,
  resistanceLevel,
  yearlyLow,
  yearlyHigh,
  highlightedLevel,
}: SupportResistanceChartProps) {
  const { getAccessTokenSilently } = useAuth0()

  const { data: stockPriceData, isLoading } = useQuery({
    queryKey: ["stockPrice", symbol, "3M"],
    retry: false,
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        const now = new Date()
        const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3))

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/4hour?from=${format(threeMonthsAgo, "yyyy-MM-dd")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (!response.ok) {
          throw new Error("Failed to fetch price data")
        }
        const data = await response.json()
        if (!data?.length) {
          throw new Error("No intraday price data available")
        }
        return data.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime(),
        )
      } catch (error) {
        console.error("Error fetching stock price data:", error)
        throw error
      }
    },
  })

  if (isLoading || !stockPriceData?.length) {
    return <div className="h-[200px] bg-white/5 rounded-lg animate-pulse" />
  }

  try {
    // Get one data point per day (last price of each day)
    const dailyData = stockPriceData.reduce((acc: any[], current: any) => {
      const date = new Date(current.date)
      const dateStr = format(date, "yyyy-MM-dd")

      const existingIndex = acc.findIndex(
        (item) => format(new Date(item.date), "yyyy-MM-dd") === dateStr,
      )

      if (existingIndex === -1) {
        acc.push(current)
      } else {
        // If we already have a point for this day, update it if current is later
        const existing = new Date(acc[existingIndex].date)
        if (date > existing) {
          acc[existingIndex] = current
        }
      }

      return acc
    }, [])

    const chartData = dailyData.map(
      (d: any): ChartDataPoint => ({
        date: new Date(d.date).getTime(),
        close: d.close,
      }),
    )

    // Calculate price range from the data
    const priceMin = Math.min(...chartData.map((d: ChartDataPoint) => d.close))
    const priceMax = Math.max(...chartData.map((d: ChartDataPoint) => d.close))
    const priceRange = priceMax - priceMin

    // Only include support and resistance levels
    const relevantLevels = [...supportLevel, ...resistanceLevel].filter(
      (level): level is number => typeof level === "number",
    )

    return (
      <div className="h-[300px] relative">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={chartData}>
            <defs>
              <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ffffff" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0.02} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="date"
              type="number"
              domain={["dataMin", "dataMax"]}
              tickFormatter={(timestamp) =>
                format(new Date(timestamp), "MMM d")
              }
              minTickGap={30}
              interval="preserveStartEnd"
              stroke="transparent"
              tick={{ fill: "rgba(255, 255, 255, 0.4)", fontSize: 10 }}
              axisLine={false}
              tickLine={false}
            />

            <YAxis
              domain={[
                (dataMin: number) =>
                  Math.min(dataMin * 0.99, ...relevantLevels),
                (dataMax: number) =>
                  Math.max(dataMax * 1.01, ...relevantLevels),
              ]}
              tick={false}
              width={35}
              stroke="transparent"
              axisLine={false}
              tickLine={false}
              orientation="right"
            />

            {/* Price Line */}
            <Area
              type="monotone"
              dataKey="close"
              stroke="rgba(255, 255, 255, 0.7)"
              strokeWidth={1.5}
              fillOpacity={0}
              isAnimationActive={false}
              dot={false}
              activeDot={{
                r: 3,
                fill: "#fff",
                stroke: "rgba(255, 255, 255, 0.2)",
                strokeWidth: 4,
              }}
            />

            {/* Support Level */}
            {supportLevel.map((level, index) => (
              <ReferenceLine
                key={`support-${index}`}
                y={level}
                stroke={
                  level === highlightedLevel
                    ? "rgba(34, 197, 94, 0.9)"
                    : "rgba(34, 197, 94, 0.6)"
                }
                strokeWidth={level === highlightedLevel ? 1.5 : 0.75}
                ifOverflow="extendDomain"
                label={{
                  value: `$${level.toFixed(2)}`,
                  position: "right",
                  fill:
                    level === highlightedLevel
                      ? "rgba(34, 197, 94, 1)"
                      : "rgba(34, 197, 94, 0.8)",
                  fontSize: 10,
                  dx: -8,
                }}
              />
            ))}

            {/* Resistance Level */}
            {resistanceLevel.map((level, index) => (
              <ReferenceLine
                key={`resistance-${index}`}
                y={level}
                stroke={
                  level === highlightedLevel
                    ? "rgba(239, 68, 68, 0.9)"
                    : "rgba(239, 68, 68, 0.6)"
                }
                strokeWidth={level === highlightedLevel ? 1.5 : 0.75}
                ifOverflow="extendDomain"
                label={{
                  value: `$${level.toFixed(2)}`,
                  position: "right",
                  fill:
                    level === highlightedLevel
                      ? "rgba(239, 68, 68, 1)"
                      : "rgba(239, 68, 68, 0.8)",
                  fontSize: 10,
                  dx: -8,
                }}
              />
            ))}
            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null

                const data = payload[0].payload
                return (
                  <div className="bg-black/85 backdrop-blur-md p-2 rounded border border-white/10 text-sm">
                    <div className="text-white">
                      Price: ${data.close?.toFixed(2)}
                    </div>
                    <div className="text-gray-400 text-xs">
                      {format(new Date(data.date), "MMM d, yyyy")}
                    </div>
                  </div>
                )
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  } catch (error) {
    console.error("Error rendering chart:", error)
    return (
      <div className="h-[200px] bg-white/5 rounded-lg flex items-center justify-center text-white/50 text-sm">
        Unable to display chart
      </div>
    )
  }
}
