import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  ReferenceDot,
  Area,
  CartesianGrid,
  Bar,
  XAxis,
} from "recharts"
import { motion } from "framer-motion"
import { useRef, useState } from "react"
import { format } from "date-fns"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

interface PriceTarget {
  symbol: string
  publishedDate: string
  newsURL: string
  newsTitle: string
  analystName: string
  priceTarget: number
  adjPriceTarget: number
  priceWhenPosted: number
  newsPublisher: string
  newsBaseURL: string
  analystCompany: string
}

interface PriceTargetsProps {
  symbol: string
}

interface MousePosition {
  x: number
  y: number
}

interface CombinedChartData {
  date: string
  close?: number
  volume?: number
  price?: number
  high?: number
  median?: number
  low?: number
}

// Add this helper function to generate random price path
function generatePricePath(
  startPrice: number,
  endPrice: number,
  numPoints: number = 20,
): number[] {
  const prices: number[] = []
  const totalDiff = endPrice - startPrice
  const volatility = Math.abs(totalDiff) * 0.3 // 30% volatility factor

  for (let i = 0; i < numPoints; i++) {
    const progress = i / (numPoints - 1)
    const expectedPrice = startPrice + totalDiff * progress
    const randomFactor = (Math.random() - 0.5) * volatility
    const price = expectedPrice + randomFactor
    prices.push(price)
  }

  // Ensure the last point matches the target exactly
  prices[prices.length - 1] = endPrice
  return prices
}

function PriceChart({
  data,
  targetValue,
  targetLabel,
  currentPrice,
}: {
  data: CombinedChartData[]
  targetValue: number
  targetLabel: string
  currentPrice: number
}) {
  const percentageDiff = ((targetValue - currentPrice) / currentPrice) * 100
  const targetKey = targetLabel.split(" ")[0].toLowerCase()
  const isLowTarget = targetKey === "low"
  const isBullish = targetValue > currentPrice

  // Generate path data
  const pathPoints = generatePricePath(currentPrice, targetValue, 30)

  // Safely get the last date from historical data
  const lastHistoricalDate = new Date(data[data.length - 2]?.date || new Date())
  if (isNaN(lastHistoricalDate.getTime())) {
    // Fallback to current date if invalid
    lastHistoricalDate.setTime(new Date().getTime())
  }

  // Calculate projection dates
  const msPerDay = 24 * 60 * 60 * 1000
  const projectionDays = 270 // 9 months
  const bufferDays = 21

  const projectionStartDate = new Date(
    lastHistoricalDate.getTime() + bufferDays * msPerDay,
  )
  const projectionEndDate = new Date(
    lastHistoricalDate.getTime() + (bufferDays + projectionDays) * msPerDay,
  )

  // Create projection path data
  const projectionData = pathPoints.map((price, index) => {
    const timeProgress = index / (pathPoints.length - 1)
    const pointDate = new Date(
      projectionStartDate.getTime() +
        (projectionEndDate.getTime() - projectionStartDate.getTime()) *
          timeProgress,
    )

    return {
      date: pointDate.toISOString(),
      [targetKey]: price,
    }
  })

  // Add gap point
  const gapPoint = {
    date: new Date(
      lastHistoricalDate.getTime() + (bufferDays / 2) * msPerDay,
    ).toISOString(),
    [targetKey]: null,
  }

  // Combine data safely
  const combinedChartData = [
    ...data
      .filter((d) => d.date && !isNaN(new Date(d.date).getTime()))
      .slice(0, -1),
    gapPoint,
    ...projectionData,
  ]

  return (
    <div className="relative h-[200px]">
      {/* Dimmer Sentiment Gradient Background */}
      <div
        className={`absolute inset-0 rounded-lg opacity-20 blur-2xl
          ${
            isBullish
              ? "bg-gradient-to-br from-emerald-500/30 via-emerald-500/10 to-transparent"
              : "bg-gradient-to-br from-red-500/30 via-red-500/10 to-transparent"
          }
        `}
      />

      {/* Additional glow effect - dimmer */}
      <div
        className={`absolute inset-0 rounded-lg opacity-10
          ${
            isBullish
              ? "bg-gradient-to-t from-emerald-500/20 to-transparent"
              : "bg-gradient-to-t from-red-500/20 to-transparent"
          }
        `}
      />

      {/* Main Chart Container - slightly more transparent */}
      <div className="relative h-full backdrop-blur-xl bg-black/20 border border-white/5 rounded-lg p-4">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-400">{targetLabel}</span>
            <span
              className={`text-sm font-medium ${isLowTarget ? "text-red-400" : "text-emerald-400"}`}
            >
              ${targetValue.toFixed(2)}
            </span>
          </div>
          <div className="flex justify-end">
            <span
              className={`text-xs ${percentageDiff >= 0 ? "text-emerald-500" : "text-red-500"}`}
            >
              {percentageDiff >= 0 ? "+" : ""}
              {percentageDiff.toFixed(2)}%
            </span>
          </div>
        </div>

        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={combinedChartData}>
            <defs>
              <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ffffff" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0.02} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="date"
              tick={false}
              axisLine={false}
              tickLine={false}
            />

            {/* Stock Price Area */}
            <Area
              yAxisId="price"
              type="monotone"
              dataKey="close"
              stroke="rgba(255, 255, 255, 0.7)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#chartGradient)"
              isAnimationActive={false}
              dot={false}
            />

            {/* Projection Area - with conditional color */}
            <Area
              yAxisId="price"
              type="monotone"
              dataKey={targetKey}
              stroke={
                isLowTarget
                  ? "rgba(239, 68, 68, 0.7)"
                  : "rgba(34, 197, 94, 0.7)"
              }
              strokeWidth={1.5}
              fill="url(#chartGradient)"
              fillOpacity={0.1}
              isAnimationActive={false}
              dot={false}
              connectNulls={true}
            />

            {/* Target Point */}
            <ReferenceDot
              yAxisId="price"
              x={combinedChartData[combinedChartData.length - 1]?.date}
              y={targetValue}
              r={4}
              fill={isLowTarget ? "#ef4444" : "#22c55e"}
              stroke="rgba(255, 255, 255, 0.2)"
              strokeWidth={2}
            />

            <YAxis
              yAxisId="price"
              domain={["auto", "auto"]}
              width={0}
              tick={false}
              axisLine={false}
              tickLine={false}
            />

            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null

                const data = payload[0].payload
                const targetData = data[targetKey]

                return (
                  <div className="bg-black/85 backdrop-blur-md p-2 rounded border border-white/10 text-sm">
                    <div className="text-white">
                      Price: ${data.close?.toFixed(2)}
                    </div>
                    {targetData && (
                      <div className="text-emerald-400 mt-1">
                        {targetLabel}: ${targetData.toFixed(2)}
                      </div>
                    )}
                  </div>
                )
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

// Update the interface for the new response format
interface PriceTargetData {
  symbol: string
  targetHigh: number
  targetLow: number
  targetConsensus: number
  targetMedian: number
}

function LoadingSkeleton() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="relative backdrop-blur-xl bg-black/10 border border-white/5 rounded-xl overflow-hidden p-6"
    >
      {/* Header Skeleton */}
      <div className="flex flex-col mb-6">
        <Skeleton
          width={100}
          height={24}
          className="mb-2"
          baseColor="#1f2937"
          highlightColor="#374151"
        />
      </div>

      {/* Charts Skeleton */}
      <div className="grid grid-cols-3 gap-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="relative h-[200px]">
            <div className="relative h-full backdrop-blur-xl bg-black/20 border border-white/5 rounded-lg p-4">
              <div className="flex flex-col gap-1 mb-2">
                <Skeleton
                  width={80}
                  height={16}
                  className="mb-1"
                  baseColor="#1f2937"
                  highlightColor="#374151"
                />
                <Skeleton
                  width={60}
                  height={14}
                  baseColor="#1f2937"
                  highlightColor="#374151"
                />
              </div>
              <Skeleton
                height={120}
                className="mt-2"
                baseColor="#1f2937"
                highlightColor="#374151"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Summary Skeleton */}
      <div className="mt-6 grid grid-cols-3 gap-4 text-center">
        {[1, 2, 3].map((i) => (
          <Skeleton
            key={i}
            width={100}
            height={16}
            baseColor="#1f2937"
            highlightColor="#374151"
          />
        ))}
      </div>
    </motion.div>
  )
}

export function PriceTargets({ symbol }: PriceTargetsProps) {
  const { getAccessTokenSilently } = useAuth0()
  const [mousePosition, setMousePosition] = useState<MousePosition | null>(null)
  const chartRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect()
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      })
    }
  }

  const handleMouseLeave = () => {
    setMousePosition(null)
  }

  const { data: stockPriceData, isLoading: isLoadingPrices } = useQuery({
    queryKey: ["stockPrice", symbol, "6M"],
    retry: false,
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const now = new Date()
      const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6))

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}?from=${format(sixMonthsAgo, "yyyy-MM-dd")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch price data")
      }
      const data = await response.json()
      return data.historical.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
      )
    },
  })

  const { data: priceTargets, isLoading } = useQuery({
    queryKey: ["priceTargets", symbol],
    retry: false,
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/price-target/${symbol}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch price targets")
      }
      const data = await response.json()
      return data as PriceTargetData
    },
  })

  if (isLoading || isLoadingPrices) {
    return <LoadingSkeleton />
  }

  // Update empty state check
  if (!priceTargets) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-center h-[300px] backdrop-blur-xl bg-white/[0.02] border border-white/10 shadow-lg shadow-black/5 rounded-xl p-6"
      >
        <div className="text-gray-400 text-lg mb-2">
          No Price Targets Available
        </div>
        <div className="text-gray-500 text-sm">
          No analyst price targets have been published for {symbol}
        </div>
      </motion.div>
    )
  }

  // Get the latest stock price from historical data
  const currentPrice = stockPriceData?.[stockPriceData.length - 1]?.close || 0

  // Use the new price target values directly
  const highTarget = priceTargets.targetHigh
  const lowTarget = priceTargets.targetLow
  const medianTarget = priceTargets.targetMedian

  // Calculate potential percentage using median target
  const potentialGain = ((medianTarget - currentPrice) / currentPrice) * 100

  // Prepare the data
  const combinedData: CombinedChartData[] =
    stockPriceData?.map((item: any) => ({
      date: item.date,
      close: item.close,
    })) || []

  // Add projection points
  if (combinedData.length > 0) {
    const lastDate = new Date(combinedData[combinedData.length - 1].date)
    const projectionDate = new Date(
      lastDate.getTime() + 90 * 24 * 60 * 60 * 1000,
    )

    // Make sure the projection starts from the current price
    const lastPoint = {
      date: lastDate.toISOString(),
      close: currentPrice,
      high: currentPrice,
      median: currentPrice,
      low: currentPrice,
    }

    const targetPoint = {
      date: projectionDate.toISOString(),
      high: highTarget,
      median: medianTarget,
      low: lowTarget,
    }

    // Add the transition points
    combinedData.push(lastPoint, targetPoint)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.002 }}
      className="relative group"
    >
      {/* Gradient border effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-white/5 via-white/2 to-transparent rounded-xl blur-xl group-hover:opacity-50 opacity-0 transition-opacity duration-500" />

      {/* Glassmorphic container */}
      <div className="relative backdrop-blur-xl bg-black/10 border border-white/5 rounded-xl overflow-hidden p-6">
        {/* Price Target Header */}
        <div className="flex flex-col mb-6">
          <span className="text-lg text-white">Price target</span>
          <div className="flex items-baseline gap-2">
            {/* <span className="text-2xl font-bold text-emerald-400">
              ${medianTarget.toFixed(2)}
            </span>
            <span className="text-sm text-emerald-500">
              ({potentialGain >= 0 ? "+" : ""}
              {potentialGain.toFixed(2)}% potential)
            </span> */}
          </div>
        </div>

        {/* Charts Container */}
        <div className="grid grid-cols-3 gap-4">
          {/* Low Target Chart */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="relative"
          >
            <PriceChart
              data={combinedData}
              targetValue={lowTarget}
              targetLabel="Low Target"
              currentPrice={currentPrice}
            />
          </motion.div>

          {/* Median Target Chart */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="relative"
          >
            <PriceChart
              data={combinedData}
              targetValue={medianTarget}
              targetLabel="Median Target"
              currentPrice={currentPrice}
            />
          </motion.div>

          {/* High Target Chart */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="relative"
          >
            <PriceChart
              data={combinedData}
              targetValue={highTarget}
              targetLabel="High Target"
              currentPrice={currentPrice}
            />
          </motion.div>
        </div>

        {/* Summary */}
        <div className="mt-6 grid grid-cols-3 gap-4 text-center text-sm">
          <div className="text-gray-400">
            Low: <span className="text-gray-200">${lowTarget.toFixed(2)}</span>
          </div>
          <div className="text-gray-400">
            Median:{" "}
            <span className="text-gray-200">${medianTarget.toFixed(2)}</span>
          </div>
          <div className="text-gray-400">
            High:{" "}
            <span className="text-gray-200">${highTarget.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
