import { Editor, Element as SlateElement, Transforms, Text } from "slate"
import { ReactEditor } from "slate-react"
import type { CustomElement } from "../types/slate"

type CustomEditor = Editor & ReactEditor

type BlockType =
  | "paragraph"
  | "heading-one"
  | "heading-two"
  | "heading-three"
  | "image"

type MarkFormat = "bold" | "italic"

export const toggleBlock = (editor: CustomEditor, format: BlockType) => {
  const isActive = isBlockActive(editor, format)
  const newProperties: Partial<SlateElement> = {
    type: isActive ? "paragraph" : format,
  }

  Transforms.setNodes(editor, newProperties)
}

export const isBlockActive = (editor: CustomEditor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

export const insertImage = (editor: CustomEditor) => {
  const url = window.prompt("Enter image URL:")
  if (!url) return

  const image: CustomElement = {
    type: "image" as const,
    url,
    alt: "An image",
    children: [{ text: "" }],
  }

  Transforms.insertNodes(editor, image)
}

export const toggleMark = (editor: CustomEditor, format: MarkFormat) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isMarkActive = (editor: CustomEditor, format: MarkFormat) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const insertHeadline = (
  editor: CustomEditor,
  headlineData: CustomElement["headlineData"],
) => {
  const headline: CustomElement = {
    type: "headline",
    headlineData,
    children: [{ text: "" }],
  }
  Transforms.insertNodes(editor, headline)
}

export const insertEconomicEvent = (
  editor: CustomEditor,
  eventData: CustomElement["eventData"],
) => {
  const event: CustomElement = {
    type: "economic-event",
    eventData,
    children: [{ text: "" }],
  }
  Transforms.insertNodes(editor, event)
}
