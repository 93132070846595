import { createFileRoute } from "@tanstack/react-router"
import { Navbar } from "@/components"
import { useUser } from "@/providers/UserProvider"
import { LoadingScreen } from "@/providers"
import { Outlet } from "@tanstack/react-router"
import { ActiveStocks } from "@/presentation/screens/equities/components/ActiveStocks/ActiveStocks"
import { EquitiesNews } from "@/presentation/screens/equities/components/EquitiesNews/EquitiesNews"
import { SectorPerformance } from "@/presentation/screens/equities/components/SectorPerformance/SectorPerformance"
import { HomescreenHeader } from "@/presentation/screens/homescreen/homescreen"
import { useState } from "react"
import { MarketIndicesChart } from "@/presentation/screens/equities/components/MarketIndicesChart/MarketIndicesChart"
import { BiggestMovers } from "@/presentation/screens/equities/components/BiggestMovers/BiggestMovers"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { SearchTicker } from "@/presentation/screens/equities/components/SearchTicker/SearchTicker"
import { EarningsCalendar } from "@/presentation/screens/equities/components/EarningsCalendar/EarningsCalendar"

export const Route = createFileRoute("/equities")({
  component: EquitiesLayout,
})

function EquitiesLayout() {
  const { isLoading } = useUser()

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <div className="px-12 sm:px-6 lg:px-12 mx-auto mt-2 flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <HomescreenHeader />
          <SearchTicker />
        </div>
      </div>
      <Outlet />
    </div>
  )
}

// Move the dashboard component to a separate file
export function EquitiesDashboard() {
  const { isLoading } = useUser()
  const [activeTab, setActiveTab] = useState<"overview" | "earnings">(
    "overview",
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="bg-black min-h-screen">
      <div className="px-12 sm:px-6 lg:px-12 mx-auto mt-2">
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => setActiveTab("overview")}
            className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
              ${activeTab === "overview" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab("earnings")}
            className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
              ${activeTab === "earnings" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
          >
            Earnings Calendar
          </button>
        </div>

        {activeTab === "overview" ? (
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-4 gap-4 mt-2">
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]}
                symbolLabels={{
                  ESUSD: "S&P 500",
                  NQUSD: "Nasdaq",
                  YMUSD: "Dow Jones",
                  RTYUSD: "Russell 2000",
                }}
                title="US Indices"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["SPY", "EWU", "EWG", "EWJ", "EWH", "EZU"]}
                symbolLabels={{
                  SPY: "S&P 500",
                  EWU: "FTSE 100",
                  EWG: "DAX",
                  EWJ: "Nikkei 225",
                  EWH: "Hang Seng",
                  EZU: "Euro Stoxx",
                }}
                title="Global Indices"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["^VIX"]}
                symbolLabels={{
                  "^VIX": "VIX",
                }}
                title="Volatility Index"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <div className="w-full h-full flex flex-col">
                <BiggestMovers />
              </div>
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl lg:col-span-2 border border-white/10 p-4 h-[600px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <EquitiesNews />
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <div className="w-full h-full flex flex-col">
                <ActiveStocks />
              </div>
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
            >
              <div className="w-full h-full flex flex-col">
                <SectorPerformance />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[calc(100vh-200px)] 
            shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
            before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
            after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
            after:pointer-events-none 
            relative 
            bg-gradient-to-b from-black/50 to-black/30
            hover:border-white/20 transition-colors duration-200"
          >
            <EarningsCalendar />
          </div>
        )}
      </div>
    </div>
  )
}
