import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts"
import { format, parseISO } from "date-fns"
import { motion } from "framer-motion"

interface AnalystRating {
  symbol: string
  date: string
  analystRatingsbuy: number
  analystRatingsHold: number
  analystRatingsSell: number
  analystRatingsStrongSell: number
  analystRatingsStrongBuy: number
}

interface RadarData {
  rating: string
  value: number
  fullLabel: string
}

interface Props {
  symbol: string
}

export function AnalystRecommendations({ symbol }: Props) {
  const { getAccessTokenSilently } = useAuth0()

  const { data: recommendations, isLoading } = useQuery({
    queryKey: ["analystRecommendations", symbol],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/analyst-recommendations/${symbol}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch analyst recommendations")
      }
      return response.json() as Promise<AnalystRating[]>
    },
  })

  if (isLoading || !recommendations) {
    return null
  }

  const recentData = recommendations.slice(0, 3)
  const [latest, ...historical] = recentData

  const processMonthData = (monthData: AnalystRating): RadarData[] => [
    {
      rating: "Strong Buy",
      value: monthData.analystRatingsStrongBuy,
      fullLabel: `Strong Buy ${monthData.analystRatingsStrongBuy}`,
    },
    {
      rating: "Buy",
      value: monthData.analystRatingsbuy,
      fullLabel: `Buy ${monthData.analystRatingsbuy}`,
    },
    {
      rating: "Hold",
      value: monthData.analystRatingsHold,
      fullLabel: `Hold ${monthData.analystRatingsHold}`,
    },
    {
      rating: "Sell",
      value: monthData.analystRatingsSell,
      fullLabel: `Sell ${monthData.analystRatingsSell}`,
    },
    {
      rating: "Strong Sell",
      value: monthData.analystRatingsStrongSell,
      fullLabel: `Strong Sell ${monthData.analystRatingsStrongSell}`,
    },
  ]

  return (
    <div className="flex flex-col gap-6">
      {/* Latest Month - Full Width Radar */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full h-[300px]"
      >
        <div className="backdrop-blur-xl bg-black/10 border border-white/5 rounded-xl p-6 h-full">
          <h4 className="text-sm font-medium mb-2 text-center text-white">
            {format(parseISO(latest.date), "MMMM yyyy")}
          </h4>
          <div className="w-full h-[calc(100%-2rem)]">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart
                cx="50%"
                cy="50%"
                outerRadius="75%"
                data={processMonthData(latest)}
              >
                <PolarGrid stroke="rgba(255,255,255,0.1)" />
                <PolarAngleAxis
                  dataKey="fullLabel"
                  tick={{
                    fill: "rgba(255,255,255,0.8)",
                    fontSize: 11,
                  }}
                />
                <PolarRadiusAxis
                  angle={90}
                  domain={[0, "auto"]}
                  tick={false}
                  axisLine={false}
                />
                <Radar
                  dataKey="value"
                  stroke="rgba(255,255,255,0.8)"
                  fill="rgba(255,255,255,0.2)"
                  fillOpacity={0.5}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </motion.div>

      {/* Historical Data - Compact View */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {historical.map((monthData, index) => {
          const data = processMonthData(monthData)
          const total = data.reduce((sum, item) => sum + item.value, 0)

          return (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: (index + 1) * 0.1 }}
              key={monthData.date}
              className="backdrop-blur-xl bg-black/5 border border-white/5 rounded-lg p-4"
            >
              <h4 className="text-sm font-medium mb-3 text-white/60">
                {format(parseISO(monthData.date), "MMMM yyyy")}
              </h4>
              <div className="space-y-2">
                {data.map((item) => (
                  <div
                    key={item.rating}
                    className="flex items-center justify-between gap-3"
                  >
                    <span className="text-sm text-white/60 whitespace-nowrap">
                      {item.rating}
                    </span>
                    <div className="flex items-center gap-2 flex-1">
                      <div className="w-full h-1.5 bg-white/5 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-white/20 rounded-full"
                          style={{
                            width: `${(item.value / total) * 100}%`,
                          }}
                        />
                      </div>
                      <span className="text-sm text-white/40 w-6 text-right">
                        {item.value}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}
