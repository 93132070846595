import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router"
import { Navbar } from "@/components"
import { useUser } from "@/providers/UserProvider"
import { LoadingScreen } from "@/providers"
import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

export const Route = createFileRoute("/dashboard")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, userData, isLoading: isUserLoading } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: "/dashboard",
            plan,
          },
        })
      }

      // Redirect to checkout if not subscribed
      if (isAuthenticated && !isSubscribed) {
        if (!plan) {
          navigate({ to: "/checkout" })
        } else {
          navigate({ to: "/checkout", search: { plan } })
        }
      }

      // Redirect to onboarding if user hasn't completed it
      if (
        isAuthenticated &&
        !isUserLoading &&
        isSubscribed &&
        !userData?.hasOnboarded
      ) {
        navigate({ to: "/onboarding" })
      }
    }, [
      isLoading,
      isAuthenticated,
      plan,
      isSubscribed,
      userData,
      isUserLoading,
    ])

    // Show LoadingScreen while checking auth status or redirecting
    if (isLoading || isUserLoading || !isAuthenticated) {
      return <LoadingScreen />
    }

    return <DashboardLayout />
  },
})

function DashboardLayout() {
  const { userData, isLoading } = useUser()

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <main className="py-6">
        <div className="px-12 sm:px-6 lg:px-12 mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  )
}
