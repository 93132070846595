import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "@tanstack/react-router"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog"
import { Search } from "lucide-react"
import { countryOptions } from "./EconomicCalendar"

export const categoryOptions = [
  { label: "Inflation", value: "33303f5e-1e3c-4016-ab2d-ac87e98f57ca" },
  { label: "Interest Rates", value: "9c4a731a-d993-4d55-89f3-dc707cc1d596" },
  { label: "Labor Market", value: "91da97bd-d94a-4ce8-a02b-b96ee2944e4c" },
  { label: "Economic Activity", value: "24127f3b-edce-4dc4-afdf-0b3bd8a964be" },
  { label: "Capital Flows", value: "fa6570f6-e494-4563-a363-00d0f2abec37" },
  { label: "Consumption", value: "e229c890-80fc-40f3-b6f4-b658f3a02635" },
  { label: "Housing Market", value: "1e06a304-fac6-440c-9ced-9225a6277a55" },
  { label: "Bond Auctions", value: "8896aa26-a50c-4f8b-aa11-8b3fccda1dfd" },
  { label: "Energy", value: "dd332fd3-6996-41be-8c41-33f277074fa7" },
]

export function EventSearch() {
  const [open, setOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState("US")
  const [searchTerm, setSearchTerm] = useState("")
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()

  const { data: events, isLoading } = useQuery({
    queryKey: ["eventSearch", selectedCountry],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const categories = categoryOptions.map((cat) => cat.value).join(",")

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/events?countries=${selectedCountry}&categories=${categories}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch events")
      }

      return response.json()
    },
    enabled: !!selectedCountry,
  })

  const filteredEvents = events
    ? Object.entries(events).map(([categoryId, category]: [string, any]) => ({
        ...category,
        events:
          category.events?.filter((event: any) =>
            event.name.toLowerCase().includes(searchTerm.toLowerCase()),
          ) || [],
      }))
    : []

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="inline-flex items-center gap-2 px-3 py-2 bg-[#201f21] hover:bg-[#333] transition-colors rounded-md border border-[#333]"
      >
        <Search className="w-4 h-4" />
        <span className="text-md">Search</span>
      </button>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[850px] bg-[#111] border-[#333]">
          <DialogHeader>
            <DialogTitle className="text-white">
              Search Economic Events
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-4">
            <div className="flex items-center gap-2 border-b border-[#333] p-2">
              <Search className="w-4 h-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search events..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-transparent border-none outline-none text-white placeholder:text-gray-400"
              />
            </div>

            <div className="flex flex-wrap gap-2 p-2">
              {countryOptions.map((country) => (
                <button
                  key={country.value}
                  onClick={() => setSelectedCountry(country.value)}
                  className={`px-3 py-1.5 rounded-md text-sm ${
                    selectedCountry === country.value
                      ? "bg-purple-500/20 text-purple-400"
                      : "bg-[#222] hover:bg-[#333] text-white"
                  }`}
                >
                  {country.label}
                </button>
              ))}
            </div>

            <div className="max-h-[400px] overflow-y-auto space-y-4">
              {isLoading ? (
                <div className="p-4 text-center text-gray-400">
                  Loading events...
                </div>
              ) : (
                filteredEvents.map(
                  (category: any) =>
                    category.events.length > 0 && (
                      <div key={category.categoryName} className="space-y-2">
                        <h3 className="text-sm font-medium text-gray-400 px-2">
                          {category.categoryName}
                        </h3>
                        <div className="space-y-1">
                          {category.events.map((event: any) => (
                            <button
                              key={event.id}
                              onClick={() => {
                                const formattedEventName = encodeURIComponent(
                                  decodeURIComponent(event.name.trim()),
                                )
                                navigate({
                                  to: "/event/$eventId/$eventName",
                                  params: {
                                    eventId: event.id,
                                    eventName: formattedEventName,
                                  },
                                })
                                setOpen(false)
                              }}
                              className="w-full text-left px-2 py-1.5 hover:bg-[#222] rounded-sm text-white"
                            >
                              {event.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    ),
                )
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
