import { useState } from "react"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"

const STOCK_CATEGORIES = {
  "large-caps": {
    label: "Large Caps",
    TOP_ROW: ["AAPL", "NVDA", "MSFT", "GOOG"],
    BOTTOM_ROW: ["AMZN", "META", "TSLA", "BRK-A"],
  },
  finance: {
    label: "Finance",
    TOP_ROW: ["JPM", "BAC", "WFC", "AXP"],
    BOTTOM_ROW: ["BRK-A", "BX", "MS", "GS"],
  },
  ai: {
    label: "AI",
    TOP_ROW: ["NVDA", "PLTR", "ADBE", "DT"],
    BOTTOM_ROW: ["AMD"],
  },
  retail: {
    label: "Retail",
    TOP_ROW: ["AMZN", "WMT", "COST", "HD"],
    BOTTOM_ROW: ["BABA"],
  },
  defense: {
    label: "Defense",
    TOP_ROW: ["LMT", "RTX", "NOC", "GD"],
    BOTTOM_ROW: ["BA"],
  },
} as const

type ChartView = keyof typeof STOCK_CATEGORIES

const SYMBOL_LABELS: Record<string, string> = {
  AAPL: "Apple",
  NVDA: "NVIDIA",
  MSFT: "Microsoft",
  GOOG: "Google",
  AMZN: "Amazon",
  META: "Meta",
  TSLA: "Tesla",
  "BRK-A": "Berkshire",
  JPM: "JP Morgan",
  BAC: "Bank of America",
  WFC: "Wells Fargo",
  AXP: "American Express",
  BX: "Blackstone",
  MS: "Morgan Stanley",
  GS: "Goldman Sachs",
  PLTR: "Palantir",
  ADBE: "Adobe",
  DT: "Dynatrace",
  AMD: "AMD",
  WMT: "Walmart",
  COST: "Costco",
  HD: "Home Depot",
  BABA: "Alibaba",
  LMT: "Lockheed Martin",
  RTX: "RTX Corp",
  NOC: "Northrop Grumman",
  GD: "General Dynamics",
  BA: "Boeing",
}

const COLORS: Record<string, string> = {
  AAPL: "rgba(255, 255, 255, 0.7)",
  NVDA: "rgba(147, 197, 253, 0.7)",
  MSFT: "rgba(167, 139, 250, 0.7)",
  GOOG: "rgba(248, 113, 113, 0.7)",
  AMZN: "rgba(74, 222, 128, 0.7)",
  META: "rgba(251, 191, 36, 0.7)",
  TSLA: "rgba(236, 72, 153, 0.7)",
  "BRK-A": "rgba(129, 140, 248, 0.7)",
  JPM: "rgba(244, 114, 182, 0.7)",
  BAC: "rgba(168, 162, 158, 0.7)",
  WFC: "rgba(234, 179, 8, 0.7)",
  AXP: "rgba(34, 211, 238, 0.7)",
  BX: "rgba(163, 230, 53, 0.7)",
  MS: "rgba(216, 180, 254, 0.7)",
  GS: "rgba(252, 165, 165, 0.7)",
  PLTR: "rgba(45, 212, 191, 0.7)",
  ADBE: "rgba(251, 146, 60, 0.7)",
  DT: "rgba(147, 51, 234, 0.7)",
  AMD: "rgba(239, 68, 68, 0.7)",
  WMT: "rgba(59, 130, 246, 0.7)",
  COST: "rgba(220, 38, 38, 0.7)",
  HD: "rgba(249, 115, 22, 0.7)",
  BABA: "rgba(234, 88, 12, 0.7)",
  LMT: "rgba(124, 58, 237, 0.7)",
  RTX: "rgba(37, 99, 235, 0.7)",
  NOC: "rgba(55, 65, 81, 0.7)",
  GD: "rgba(20, 184, 166, 0.7)",
  BA: "rgba(6, 182, 212, 0.7)",
}

function CategoryButton({
  view,
  currentView,
  onClick,
}: {
  view: ChartView
  currentView: ChartView
  onClick: () => void
}) {
  return (
    <Button
      variant={currentView === view ? "default" : "ghost"}
      onClick={onClick}
      size="sm"
      className={cn(
        "text-sm px-4 py-2 h-auto transition-all duration-200 font-medium",
        currentView === view
          ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
          : "text-gray-500 hover:bg-white/5 hover:text-white",
      )}
    >
      {STOCK_CATEGORIES[view].label}
    </Button>
  )
}

const getAllSymbols = (view: ChartView) => {
  const category = STOCK_CATEGORIES[view]
  return [...category.TOP_ROW, ...category.BOTTOM_ROW]
}

export function BiggestMovers() {
  const [chartView, setChartView] = useState<ChartView>("large-caps")
  const symbols = getAllSymbols(chartView)

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex gap-3 mb-6">
        {(Object.keys(STOCK_CATEGORIES) as ChartView[]).map((view) => (
          <CategoryButton
            key={view}
            view={view}
            currentView={chartView}
            onClick={() => setChartView(view)}
          />
        ))}
      </div>

      <div className="flex-1">
        <MultiSymbolChart
          symbols={symbols}
          symbolLabels={SYMBOL_LABELS}
          symbolColors={COLORS}
          height={400}
          defaultVisibleSymbols={symbols}
        />
      </div>
    </div>
  )
}
