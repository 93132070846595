import { InterestRates, SessionVolume } from "./components"
import { EconomicCalendar } from "./components/EconomicCalendar"
import { MarketHeadlines } from "./components/MarketHeadlines"
import { Map } from "./components/Map"
import "simplebar-react/dist/simplebar.min.css"
import { CentralBankEvents } from "./components/CentralBankEvents/CentralBankEvents"
import { useUser } from "@/providers"
import { useState, useCallback } from "react"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { TopCommodities } from "./components/TopCommodities/TopCommodities"
import { TopCurrencies } from "./components/TopCurrencies/TopCurrencies"
import { TopStocks } from "./components/TopStocks/TopStocks"
import { MarketNews } from "./components/MarketNews/MarketNews"
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline"
import { GlobalEconomy } from "./components/GlobalEconomy/GlobalEconomy"
import { AIGenerateButton } from "@/components/ui/AIGenerateButton"
import { format } from "date-fns"

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Brain, Loader2 } from "lucide-react"
import { cn } from "@nextui-org/theme"
import { Calendar } from "@/components/ui/calendar"

// Component to display headlines
import { toast } from "@/hooks/use-toast"
import { useAuth0 } from "@auth0/auth0-react"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import { DailySummaryPanel } from "./components/MarketHeadlines/DailySummaryPanel"
function getFormattedDate(): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const date = new Date()
  const formattedDate = date.toLocaleDateString("en-US", options)

  // Custom suffix for day
  const day = date.getDate()
  const daySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th"
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`)
}

type HomeScreenHeaderProps = {}

export const HomescreenHeader = ({}: HomeScreenHeaderProps) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const { userData } = useUser()

  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-2xl font-light text-white">
          Welcome, {userData?.name ?? "Trader"}
        </h1>
        <h3 className="text-lg font-light text-gray-500">
          {getFormattedDate()}
        </h3>
      </div>
    </div>
  )
}

type NewsTab = "headlines" | "articles"

export const HomeScreen = () => {
  const [activeTab, setActiveTab] = useState<
    "sentiment" | "global" | "currencies" | "commodities" | "economy"
  >("sentiment")
  const [selectedNewsTab, setSelectedNewsTab] = useState<NewsTab>("headlines")
  const [isPrioritized, setIsPrioritized] = useState(false)
  const [open, setOpen] = useState(false)
  const [isDailySummaryLoading, setIsDailySummaryLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [showDailySummary, setShowDailySummary] = useState(false)
  const [dailySummaryAnalysis, setDailySummaryAnalysis] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()
  const SYMBOLS = ["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]
  const SYMBOL_LABELS = {
    ESUSD: "S&P 500",
    NQUSD: "NASDAQ",
    YMUSD: "DOW JONES",
    RTYUSD: "RUSSELL 2000",
  }

  const DEFAULT_VISIBLE_SYMBOLS = ["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]

  const GLOBAL_SYMBOLS = ["USDCNY", "USDJPY", "EURUSD", "AUDUSD", "GBPUSD"]
  const GLOBAL_SYMBOL_LABELS = {
    USDCNY: "USD/CNY",
    USDJPY: "USD/JPY",
    EURUSD: "EUR/USD",
    AUDUSD: "AUD/USD",
    GBPUSD: "GBP/USD",
  }

  const CRYPTO_SYMBOLS = ["GCUSD", "SIUSD", "CLUSD"]
  const CRYPTO_SYMBOL_LABELS = {
    GCUSD: "Gold",
    SIUSD: "Silver",
    CLUSD: "Crude Oil",
  }

  const VOLATILITY_SYMBOLS = ["^VIX"]
  const VOLATILITY_LABELS = {
    "^VIX": "S&P 500 VIX",
  }

  const MACRO_SYMBOLS = ["DXUSD", "XAUUSD", "ZBUSD", "ZFUSD"]
  const MACRO_SYMBOL_LABELS = {
    DXUSD: "USD",
    XAUUSD: "Gold",
    ZBUSD: "30Y",
    ZFUSD: "5Y",
  }

  const GLOBAL_INDICES_SYMBOLS = ["SPY", "EWU", "EWG", "EWJ", "EWH", "EZU"]
  const GLOBAL_INDICES_LABELS: { [key: string]: string } = {
    SPY: "S&P 500",
    EWU: "FTSE 100",
    EWG: "DAX",
    EWJ: "Nikkei 225",
    EWH: "Hang Seng",
    EZU: "Euro Stoxx",
  }

  const CURRENCY_PAIRS_SYMBOLS = [
    "XAUUSD",
    "USDJPY",
    "EURUSD",
    "GBPUSD",
    "USDCNY",
    "USDCHF",
  ]
  const CURRENCY_PAIRS_LABELS: { [key: string]: string } = {
    XAUUSD: "Gold",
    USDJPY: "USD/JPY",
    EURUSD: "EUR/USD",
    GBPUSD: "GBP/USD",
    USDCNY: "USD/CNY",
    USDCHF: "USD/CHF",
  }

  const COMMODITIES_SYMBOLS = [
    "GCUSD",
    "SIUSD",
    "CLUSD",
    "NGUSD",
    "KCUSX",
    "ZSUSX",
  ]
  const COMMODITIES_LABELS: { [key: string]: string } = {
    GCUSD: "Gold",
    SIUSD: "Silver",
    CLUSD: "Crude Oil",
    NGUSD: "Natural Gas",
    KCUSX: "Coffee",
    ZSUSX: "Soybean",
  }

  const handleGetDailySummary = async () => {
    setIsDailySummaryLoading(true)
    setShowDailySummary(true)
    try {
      const dateParam = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : undefined
      const queryString = dateParam ? `?date=${dateParam}` : ""

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/headlines/daily${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch daily summary")
      }

      const data = await response.json()
      setDailySummaryAnalysis(data)
    } catch (error) {
      console.error("Error fetching daily summary:", error)
      toast({
        title: "Error",
        description: "Failed to fetch daily summary",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      setIsDailySummaryLoading(false)
    }
  }

  const handleCloseDailySummary = () => {
    setShowDailySummary(false)
    setDailySummaryAnalysis(null)
  }

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <div className="flex justify-between items-center">
        <div className="flex items-end space-x-8 -mt-4">
          <HomescreenHeader />

          {/* Tab Navigation */}
          {!isPrioritized && (
            <div className="flex space-x-4 mb-1">
              <button
                onClick={() => setActiveTab("sentiment")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "sentiment" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Market Sentiment
              </button>
              <button
                onClick={() => setActiveTab("global")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "global" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Global Markets
              </button>
              <button
                onClick={() => setActiveTab("currencies")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "currencies" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Currencies
              </button>
              <button
                onClick={() => setActiveTab("commodities")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "commodities" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Commodities
              </button>
              <button
                onClick={() => setActiveTab("economy")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "economy" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Global Economy
              </button>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2">
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <button
                className={cn(
                  "p-2 rounded-lg",
                  "bg-gradient-to-r from-purple-500/10 to-blue-500/10",
                  "hover:from-purple-500/20 hover:to-blue-500/20",
                  "border border-purple-500/50",
                  "transition-all duration-300 ease-out",
                  "relative group",
                )}
              >
                <Brain
                  className={cn(
                    "w-6 h-6",
                    "transition-colors",
                    isDailySummaryLoading
                      ? "text-purple-400"
                      : "text-purple-400/80 group-hover:text-purple-300",
                  )}
                />
                <div
                  className={cn(
                    "absolute inset-0 -z-10",
                    "bg-gradient-to-r from-purple-500/5 to-blue-500/5",
                    "blur-xl opacity-0 group-hover:opacity-100",
                    "transition-opacity duration-300",
                  )}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              className="w-[300px] p-0 bg-black/95 border border-[#333] shadow-xl backdrop-blur-sm"
              align="end"
              side="bottom"
              sideOffset={5}
              alignOffset={0}
            >
              <div className="flex flex-col">
                <div className="p-4 border-b border-zinc-800/50">
                  <span className="text-zinc-400 text-sm">
                    {selectedDate
                      ? format(selectedDate, "MMMM d, yyyy")
                      : "Select a date"}
                  </span>
                </div>

                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={setSelectedDate}
                  className="p-0 mx-auto"
                  disabled={{ after: new Date() }}
                  classNames={{
                    months: "flex flex-col space-y-4",
                    month: "space-y-4",
                    caption:
                      "flex justify-center pt-1 relative items-center text-zinc-400",
                    caption_label: "text-sm font-medium",
                    nav: "space-x-1 flex items-center",
                    nav_button:
                      "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 hover:bg-zinc-800/50 rounded-md text-zinc-400",
                    nav_button_previous: "absolute left-1",
                    nav_button_next: "absolute right-1",
                    table: "w-full border-collapse space-y-1",
                    head_row: "flex",
                    head_cell:
                      "text-zinc-400 rounded-md w-9 font-normal text-[0.8rem]",
                    row: "flex w-full mt-2 justify-center",
                    cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-zinc-800/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 text-zinc-400 hover:bg-zinc-800/50 rounded-md transition-colors",
                    day_selected:
                      "bg-purple-400/30 text-white hover:bg-purple-400 hover:text-white focus:bg-purple-400 focus:text-white",
                    day_today: "bg-zinc-800/50 text-white",
                    day_outside: "opacity-50",
                    day_disabled: "opacity-30 cursor-not-allowed",
                    day_range_middle:
                      "aria-selected:bg-zinc-800/50 aria-selected:text-zinc-400",
                    day_hidden: "invisible",
                  }}
                />
                <div className="p-4 border-t border-zinc-800/50 space-y-2">
                  <Button
                    onClick={() => {
                      handleGetDailySummary()
                      setOpen(false)
                    }}
                    disabled={!selectedDate || isDailySummaryLoading}
                    className="w-full hover:from-purple-500/20 hover:to-blue-500/20 bg-gradient-to-r from-purple-500/10 to-blue-500/10 text-purple-300 border border-purple-500/30 transition-colors duration-200"
                  >
                    {isDailySummaryLoading ? (
                      <Loader2 className="w-4 h-4 animate-spin mr-2" />
                    ) : (
                      <Brain className="w-4 h-4 mr-2" />
                    )}
                    View Market Summary
                  </Button>
                </div>
              </div>
            </PopoverContent>
          </Popover>
          <button
            onClick={() => setIsPrioritized(!isPrioritized)}
            className="inline-flex items-center gap-2 px-4 py-2 text-white bg-[#111] border 
            border-[#333] rounded-md hover:bg-[#333] transition-colors"
          >
            <ArrowsRightLeftIcon className="h-5 w-5" />
            {isPrioritized ? "Dashboard View" : "Prioritized View"}
          </button>
        </div>
      </div>

      {/* Modified Content Section */}
      {isPrioritized ? (
        <div className="grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 gap-4 mt-6">
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
            <EconomicCalendar />
          </div>
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
            <MarketHeadlines />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-6 gap-4 mt-6">
          {activeTab === "sentiment" ? (
            <>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={SYMBOLS}
                  symbolLabels={SYMBOL_LABELS}
                  defaultVisibleSymbols={DEFAULT_VISIBLE_SYMBOLS}
                  height={400}
                  title="US Market Indices"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={GLOBAL_SYMBOLS}
                  symbolLabels={GLOBAL_SYMBOL_LABELS}
                  height={450}
                  title="Global Currencies"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={MACRO_SYMBOLS}
                  symbolLabels={MACRO_SYMBOL_LABELS}
                  height={400}
                  title="Macro Markets"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={CRYPTO_SYMBOLS}
                  symbolLabels={CRYPTO_SYMBOL_LABELS}
                  height={400}
                  title="Commodities"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={VOLATILITY_SYMBOLS}
                  symbolLabels={VOLATILITY_LABELS}
                  height={400}
                  title="Market Volatility"
                />
              </div>
              <div className="rounded-lg border border-[#333] p-4 h-[400px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <CentralBankEvents />
              </div>
            </>
          ) : activeTab === "global" ? (
            <>
              {GLOBAL_INDICES_SYMBOLS.map((symbol, index) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: GLOBAL_INDICES_LABELS[symbol] }}
                    height={400}
                    title={GLOBAL_INDICES_LABELS[symbol]}
                    colors={["#FFA726"]}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "currencies" ? (
            <>
              {CURRENCY_PAIRS_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: CURRENCY_PAIRS_LABELS[symbol] }}
                    height={400}
                    title={CURRENCY_PAIRS_LABELS[symbol]}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "economy" ? (
            <div className="col-span-full">
              <GlobalEconomy />
            </div>
          ) : (
            // Commodities Content
            <>
              {COMMODITIES_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: COMMODITIES_LABELS[symbol] }}
                    height={400}
                    title={COMMODITIES_LABELS[symbol]}
                    colors={["#4CAF50"]}
                  />
                </div>
              ))}
            </>
          )}

          {activeTab !== "economy" && (
            <>
              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <EconomicCalendar />
              </div>

              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-base font-medium text-white/80">
                    Market Updates
                  </h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setSelectedNewsTab("headlines")}
                      className={`px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                        selectedNewsTab === "headlines"
                          ? "bg-white/5 text-white shadow-lg shadow-white/5"
                          : "text-gray-500 hover:bg-white/5 hover:text-white"
                      }`}
                    >
                      Headlines
                    </button>
                    <button
                      onClick={() => setSelectedNewsTab("articles")}
                      className={`px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                        selectedNewsTab === "articles"
                          ? "bg-white/5 text-white shadow-lg shadow-white/5"
                          : "text-gray-500 hover:bg-white/5 hover:text-white"
                      }`}
                    >
                      Articles
                    </button>
                  </div>
                </div>
                <div className="h-[calc(100%-60px)] overflow-auto">
                  {selectedNewsTab === "headlines" ? (
                    <MarketHeadlines />
                  ) : (
                    <MarketNews />
                  )}
                </div>
              </div>
            </>
          )}
          <AnimatePresence>
            {showDailySummary && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="fixed inset-0 bg-black/50 z-50"
              >
                <DailySummaryPanel
                  analysis={dailySummaryAnalysis?.analysis}
                  isLoading={isDailySummaryLoading}
                  onClose={handleCloseDailySummary}
                  selectedDate={selectedDate}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  )
}
