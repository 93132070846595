import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { format, subMonths, subYears, addMonths } from "date-fns"
import { Card } from "@/components/ui/card"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { HistoricalBarChart } from "./HistoricalBarChart"
import { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../components/ui/table"
import {
  getValueWithIndicator,
  formatValue,
  getCountryFlag,
} from "../../screens/homescreen/components/EconomicCalendar/EconomicCalendar"
import { enUS } from "date-fns/locale"
import { cn } from "@/lib/utils"
import { BarChart3, LineChart } from "lucide-react"
import { Link, useNavigate } from "@tanstack/react-router"

type TimeRange = "1Y" | "2Y" | "5Y" | "MAX"
type Category = "Actual" | "Forecast"

interface HistoricalRecord {
  id: string
  dateUtc: string
  periodDateUtc: string | null
  actual: number | null
  revised: number | null
  consensus: number | null
  previous: number | null
  ratioDeviation: number | null
  isPreliminary: boolean
  isBetterThanExpected: boolean | null
}

// Add custom theme for skeleton
const skeletonBaseColor = "#222"
const skeletonHighlightColor = "#333"

// Add this interface for the event details
interface EventDetails {
  id: string
  name: string
  countryCode: string
  currencyCode: string
  category: {
    id: string
    name: string
  }
  unit: string
  rise: string
  potency: string
  periodType: string
  isReport: boolean
  isSpeech: boolean
  description: string
  whyMatters: string
  tags: string[]
  source: string
  officialSourceUrl: string
}

// Add this interface near the other interfaces
interface UpcomingEvent {
  id: string
  dateUtc: string
  consensus: number | null
  previous: number | null
  isPreliminary: boolean
}

// Add this interface near the other interfaces
interface RelatedEvent {
  id: string
  name: string
}

interface RelatedEventsResponse {
  countryCode: string
  events: RelatedEvent[]
}

const colors = {
  Actual: "rgb(16 185 129)", // text-emerald-500
  Forecast: "rgb(139 92 246)", // text-violet-500
}

export function EventFullView({
  eventId,
  eventName,
}: {
  eventId: string
  eventName: string
}) {
  const [timeRange, setTimeRange] = useState<TimeRange>("2Y")
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([
    "Actual",
  ])
  const [chartTypes, setChartTypes] = useState<
    Record<Category, "bar" | "line">
  >({
    Actual: "bar",
    Forecast: "line",
  })
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  // Decode the event name
  const decodedEventName = decodeURIComponent(eventName)

  const getDateRange = (range: TimeRange) => {
    const toDate = new Date()
    switch (range) {
      case "1Y":
        return subYears(toDate, 1)
      case "2Y":
        return subYears(toDate, 2)
      case "5Y":
        return subYears(toDate, 5)
      case "MAX":
        return subYears(toDate, 10) // or whatever max you want
      default:
        return subYears(toDate, 2)
    }
  }

  const renderHtmlContent = (content: string) => {
    return (
      <p
        className="text-[16px] text-gray-400 [&_a]:text-purple-400 [&_a]:hover:text-purple-300 [&_a]:transition-colors"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )
  }

  // Add event details query
  const { data: eventDetails } = useQuery({
    queryKey: ["eventDetails", eventId],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch event details")
      }

      return response.json() as Promise<EventDetails>
    },
    enabled: !!eventId,
  })

  const { data: allHistoricalData, isLoading: isLoadingHistorical } = useQuery({
    queryKey: ["eventHistorical", eventId],
    queryFn: async () => {
      const token = await getAccessTokenSilently()

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/events/${eventId}/historical`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch historical data")
      }

      return response.json()
    },
    enabled: !!eventId,
  })

  // Filter data based on timeRange in the component
  const historicalData = allHistoricalData
    ? allHistoricalData
        .filter((record: HistoricalRecord) => {
          const recordDate = new Date(record.dateUtc)
          const toDate = new Date()
          const fromDate = getDateRange(timeRange)
          return recordDate >= fromDate && recordDate <= toDate
        })
        .sort(
          (a: HistoricalRecord, b: HistoricalRecord) =>
            new Date(b.dateUtc).getTime() - new Date(a.dateUtc).getTime(),
        )
    : null

  const toggleCategory = (category: Category) => {
    setSelectedCategories((prev) => {
      // Don't allow deselecting if it's the last category
      if (prev.length === 1 && prev.includes(category)) {
        return prev
      }

      return prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    })
  }

  const toggleChartType = (category: Category) => {
    setChartTypes((prev) => ({
      ...prev,
      [category]: prev[category] === "bar" ? "line" : "bar",
    }))
  }

  // Add the query for upcoming event data after the other queries
  const { data: upcomingEvent } = useQuery({
    queryKey: ["eventUpcoming", eventId],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/events/${eventId}/upcoming`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch upcoming event")
      }

      const data = await response.json()
      return data[0] as UpcomingEvent // Return the first item since it's an array
    },
    enabled: !!eventId,
  })

  // Add the query for related events after the other queries
  const { data: relatedEvents } = useQuery({
    queryKey: ["eventRelated", eventId],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/events/${eventId}/relatedByTags?take=6`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch related events")
      }

      return response.json() as Promise<RelatedEventsResponse>
    },
    enabled: !!eventId,
  })

  // Add this helper function
  const formatPeriodDate = (dateUtc: string, periodType: string) => {
    const date = new Date(dateUtc)
    return format(date, "MMM d, yyyy", { locale: enUS })
  }

  if (isLoadingHistorical) {
    return (
      <Card className="max-w-[1200px] mx-auto mt-8 bg-[#111] border-[#333]">
        <div className="border-b border-[#333] p-6">
          <Skeleton
            className="w-1/3 h-8 mb-2"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            className="w-2/3 h-5"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>

        <div className="p-6">
          <div className="flex items-center gap-2 mb-6">
            <Skeleton
              className="w-24 h-8"
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
            />
            <div className="flex gap-1">
              {[1, 2, 3, 4].map((i) => (
                <Skeleton
                  key={i}
                  className="w-16 h-8"
                  baseColor={skeletonBaseColor}
                  highlightColor={skeletonHighlightColor}
                />
              ))}
            </div>
          </div>

          <div className="bg-black/20 rounded-lg border border-[#333] p-6">
            <Skeleton
              className="w-full h-[300px]"
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
            />
          </div>

          <div className="mt-6">
            <Table className="w-full border border-[#333] rounded-lg overflow-hidden">
              <TableBody>
                <TableRow className="bg-black/40">
                  {[1, 2, 3, 4].map((i) => (
                    <TableCell key={i}>
                      <Skeleton
                        className="w-20 h-6"
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                {[1, 2, 3, 4, 5].map((row) => (
                  <TableRow key={row}>
                    {[1, 2, 3, 4].map((cell) => (
                      <TableCell key={cell}>
                        <Skeleton
                          className="w-16 h-6"
                          baseColor={skeletonBaseColor}
                          highlightColor={skeletonHighlightColor}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Card>
    )
  }

  if (!historicalData) {
    return <div>Event not found</div>
  }

  const timeRangeOptions: TimeRange[] = ["1Y", "2Y", "5Y", "MAX"]

  return (
    <Card className="max-w-[1400px] mx-auto mt-8 bg-[#111] border-[#333]">
      <div className="border-b border-[#333] p-6">
        <div className="flex items-center gap-2">
          <h2 className="text-2xl font-medium text-white">
            {eventDetails?.name || decodedEventName}
          </h2>
          {getCountryFlag(eventDetails?.countryCode ?? "")}
        </div>
      </div>

      <div className="flex">
        {/* Main content - Chart and Table */}
        <div className="flex-1 p-6 border-r border-[#333]">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">Time Range:</span>
                <div className="flex gap-1">
                  {timeRangeOptions.map((range) => (
                    <button
                      key={range}
                      onClick={() => setTimeRange(range)}
                      className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                        timeRange === range
                          ? "bg-purple-500/20 text-purple-400 border border-purple-500/20"
                          : "text-gray-400 hover:text-white border border-[#333] hover:bg-[#333]"
                      }`}
                    >
                      {range}
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">Categories:</span>
                <div className="flex gap-3">
                  {["Actual", "Forecast"].map((category) => (
                    <div key={category} className="flex items-center gap-1">
                      <button
                        onClick={() => toggleCategory(category as Category)}
                        className={cn(
                          "px-3 py-1.5 text-sm rounded-md transition-colors border",
                          selectedCategories.includes(category as Category)
                            ? "bg-purple-500/20 text-purple-400 border-purple-500/20"
                            : "text-gray-400 hover:text-white border-[#333] hover:bg-[#333]",
                        )}
                      >
                        {category}
                      </button>
                      {selectedCategories.includes(category as Category) && (
                        <div className="flex rounded-md border border-[#333] overflow-hidden">
                          <button
                            onClick={() =>
                              toggleChartType(category as Category)
                            }
                            className={cn(
                              "p-1.5 transition-colors",
                              chartTypes[category as Category] === "bar"
                                ? "bg-[#222] text-purple-400"
                                : "text-gray-400 hover:text-white hover:bg-[#222]",
                            )}
                            title="Bar Chart"
                          >
                            <BarChart3 size={16} />
                          </button>
                          <button
                            onClick={() =>
                              toggleChartType(category as Category)
                            }
                            className={cn(
                              "p-1.5 transition-colors border-l border-[#333]",
                              chartTypes[category as Category] === "line"
                                ? "bg-[#222] text-purple-400"
                                : "text-gray-400 hover:text-white hover:bg-[#222]",
                            )}
                            title="Line Chart"
                          >
                            <LineChart size={16} />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-black/20 rounded-lg border border-[#333] p-6">
            {historicalData ? (
              <HistoricalBarChart
                data={historicalData}
                unit={eventDetails?.unit ?? null}
                potency={eventDetails?.potency ?? null}
                selectedCategories={selectedCategories}
                chartTypes={chartTypes}
                periodType={eventDetails?.periodType ?? ""}
              />
            ) : (
              <div className="h-[300px] flex flex-col items-center justify-center text-gray-400">
                <p className="text-lg mb-1">No Historical Data</p>
              </div>
            )}
          </div>

          <div className="mt-6">
            <Table className="w-full border border-[#333] rounded-lg overflow-hidden">
              <TableBody>
                <TableRow className="bg-black/40">
                  <TableCell className="text-gray-400 font-medium">
                    Date
                  </TableCell>
                  <TableCell className="text-gray-400 font-medium">
                    Actual
                  </TableCell>
                  <TableCell className="text-gray-400 font-medium">
                    Forecast
                  </TableCell>
                  <TableCell className="text-gray-400 font-medium">
                    Previous
                  </TableCell>
                </TableRow>
                {historicalData.slice(0, 10).map((record: HistoricalRecord) => {
                  const actualValue = getValueWithIndicator(
                    record.actual,
                    record.consensus,
                    record.previous,
                    formatValue(
                      record.actual,
                      eventDetails?.unit ?? null,
                      eventDetails?.potency ?? null,
                    ),
                  )

                  return (
                    <TableRow key={record.id} className="hover:bg-[#222]">
                      <TableCell className="text-gray-400">
                        {formatPeriodDate(
                          record.dateUtc,
                          eventDetails?.periodType ?? "",
                        )}
                      </TableCell>
                      <TableCell className={actualValue.color}>
                        {actualValue.value}
                        {actualValue.indicator}
                      </TableCell>
                      <TableCell className="text-white">
                        {record.consensus
                          ? formatValue(
                              record.consensus,
                              eventDetails?.unit ?? null,
                              eventDetails?.potency ?? null,
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell className="text-white">
                        {record.revised ?? record.previous
                          ? formatValue(
                              record.revised ?? record.previous,
                              eventDetails?.unit ?? null,
                              eventDetails?.potency ?? null,
                            )
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </div>

        {/* Right sidebar - Event Details */}
        <div className="w-96 p-8 bg-black/20">
          <div className="space-y-6">
            {upcomingEvent && (
              <div className="p-4 rounded-lg border border-purple-500/20 bg-purple-500/10">
                <h3 className="text-lg font-medium text-white mb-3">
                  Next Release
                </h3>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-400">Date:</span>
                    <span className="text-sm text-white">
                      {format(
                        new Date(upcomingEvent.dateUtc),
                        "MMM d, yyyy h:mm a",
                        {
                          locale: enUS,
                        },
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-400">Forecast:</span>
                    <span className="text-sm text-white">
                      {upcomingEvent.consensus !== null
                        ? formatValue(
                            upcomingEvent.consensus,
                            eventDetails?.unit ?? null,
                            eventDetails?.potency ?? null,
                          )
                        : "-"}
                    </span>
                  </div>
                  {upcomingEvent.previous !== null && (
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-400">Previous:</span>
                      <span className="text-sm text-white">
                        {formatValue(
                          upcomingEvent.previous,
                          eventDetails?.unit ?? null,
                          eventDetails?.potency ?? null,
                        )}
                      </span>
                    </div>
                  )}
                  {upcomingEvent.isPreliminary && (
                    <div className="mt-2 text-xs text-yellow-500">
                      * Preliminary release
                    </div>
                  )}
                </div>
              </div>
            )}

            <div>
              <h3 className="text-lg font-medium text-white mb-2">
                Event Details
              </h3>
              <div className="text-gray-400 text-sm">
                {eventDetails?.description &&
                  renderHtmlContent(eventDetails.description)}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-white mb-2">
                Event Significance
              </h3>
              <div className="text-gray-400 text-sm">
                {eventDetails?.whyMatters &&
                  renderHtmlContent(eventDetails.whyMatters)}
              </div>
            </div>

            <div className="space-y-3">
              <div>
                <span className="text-sm text-gray-400">Source:</span>
                <span className="ml-2 text-white text-sm">
                  {eventDetails?.source}
                </span>
              </div>
              <div>
                <span className="text-sm text-gray-400">Category:</span>
                <span className="ml-2 text-white text-sm">
                  {eventDetails?.category.name}
                </span>
              </div>
              <div>
                <span className="text-sm text-gray-400">Period:</span>
                <span className="ml-2 text-white text-sm">
                  {eventDetails?.periodType?.toLowerCase() ?? "-"}
                </span>
              </div>
              {eventDetails?.officialSourceUrl && (
                <a
                  href={eventDetails.officialSourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 text-sm inline-flex items-center gap-1"
                >
                  Official Source
                  <span className="text-xs">↗</span>
                </a>
              )}
              {relatedEvents && (
                <div className="pt-6 border-t border-[#333]">
                  <h3 className="text-lg font-medium text-white mb-3">
                    Related Events
                  </h3>
                  <div className="space-y-2">
                    {relatedEvents?.events?.map((event) => (
                      <button
                        key={event.id}
                        onClick={() => {
                          navigate({
                            to: "/event/$eventId/$eventName",
                            params: {
                              eventId: event.id,
                              eventName: encodeURIComponent(event.name),
                            },
                          })
                        }}
                        className="block w-full text-left text-sm text-gray-400 hover:text-purple-400 transition-colors"
                      >
                        {event.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
