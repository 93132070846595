import { InterestRates, SessionVolume } from "./components"
import { EconomicCalendar } from "./components/EconomicCalendar"
import { MarketHeadlines } from "./components/MarketHeadlines"
import { Map } from "./components/Map"
import "simplebar-react/dist/simplebar.min.css"
import { CentralBankEvents } from "./components/CentralBankEvents/CentralBankEvents"
import { useUser } from "@/providers"
import { useState, useCallback } from "react"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { TopCommodities } from "./components/TopCommodities/TopCommodities"
import { TopCurrencies } from "./components/TopCurrencies/TopCurrencies"
import { TopStocks } from "./components/TopStocks/TopStocks"
import { MarketNews } from "./components/MarketNews/MarketNews"
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline"
import { GlobalEconomy } from "./components/GlobalEconomy/GlobalEconomy"

// Component to display headlines
function getFormattedDate(): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const date = new Date()
  const formattedDate = date.toLocaleDateString("en-US", options)

  // Custom suffix for day
  const day = date.getDate()
  const daySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th"
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`)
}

type HomeScreenHeaderProps = {}

export const HomescreenHeader = ({}: HomeScreenHeaderProps) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const { userData } = useUser()

  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-2xl font-light text-white">
          Welcome, {userData?.name ?? "Trader"}
        </h1>
        <h3 className="text-lg font-light text-gray-500">
          {getFormattedDate()}
        </h3>
      </div>
    </div>
  )
}

type NewsTab = "headlines" | "articles"

export const HomeScreen = () => {
  const [activeTab, setActiveTab] = useState<
    "sentiment" | "global" | "currencies" | "commodities" | "economy"
  >("sentiment")
  const [selectedNewsTab, setSelectedNewsTab] = useState<NewsTab>("headlines")
  const [isPrioritized, setIsPrioritized] = useState(false)

  const SYMBOLS = ["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]
  const SYMBOL_LABELS = {
    ESUSD: "S&P 500",
    NQUSD: "NASDAQ",
    YMUSD: "DOW JONES",
    RTYUSD: "RUSSELL 2000",
  }

  const DEFAULT_VISIBLE_SYMBOLS = ["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]

  const GLOBAL_SYMBOLS = ["USDCNY", "USDJPY", "EURUSD", "AUDUSD", "GBPUSD"]
  const GLOBAL_SYMBOL_LABELS = {
    USDCNY: "USD/CNY",
    USDJPY: "USD/JPY",
    EURUSD: "EUR/USD",
    AUDUSD: "AUD/USD",
    GBPUSD: "GBP/USD",
  }

  const CRYPTO_SYMBOLS = ["GCUSD", "SIUSD", "CLUSD"]
  const CRYPTO_SYMBOL_LABELS = {
    GCUSD: "Gold",
    // PLUSD: "Platinum",
    // HGUSD: "Copper",
    SIUSD: "Silver",
    CLUSD: "Crude Oil",
  }

  const VOLATILITY_SYMBOLS = ["^VIX", "^OVX", "^GVZ"]
  const VOLATILITY_LABELS = {
    "^VIX": "S&P 500 VIX",
    "^OVX": "Oil VIX",
    "^GVZ": "Gold VIX",
  }

  const MACRO_SYMBOLS = ["ZQUSD", "DXUSD", "XAUUSD", "ZBUSD", "ZFUSD"]
  const MACRO_SYMBOL_LABELS = {
    ZQUSD: "Fed Funds Futures",
    DXUSD: "USD",
    XAUUSD: "Gold",
    ZBUSD: "30Y",
    ZFUSD: "5Y",
  }

  const GLOBAL_INDICES_SYMBOLS = ["SPY", "EWU", "EWG", "EWJ", "EWH", "EZU"]
  const GLOBAL_INDICES_LABELS: { [key: string]: string } = {
    SPY: "S&P 500",
    EWU: "FTSE 100",
    EWG: "DAX",
    EWJ: "Nikkei 225",
    EWH: "Hang Seng",
    EZU: "Euro Stoxx",
  }

  const CURRENCY_PAIRS_SYMBOLS = [
    "XAUUSD",
    "USDJPY",
    "EURUSD",
    "GBPUSD",
    "USDCNY",
    "USDCHF",
  ]
  const CURRENCY_PAIRS_LABELS: { [key: string]: string } = {
    XAUUSD: "Gold",
    USDJPY: "USD/JPY",
    EURUSD: "EUR/USD",
    GBPUSD: "GBP/USD",
    USDCNY: "USD/CNY",
    USDCHF: "USD/CHF",
  }

  const COMMODITIES_SYMBOLS = [
    "GCUSD",
    "SIUSD",
    "CLUSD",
    "NGUSD",
    "KCUSX",
    "ZSUSX",
  ]
  const COMMODITIES_LABELS: { [key: string]: string } = {
    GCUSD: "Gold",
    SIUSD: "Silver",
    CLUSD: "Crude Oil",
    NGUSD: "Natural Gas",
    KCUSX: "Coffee",
    ZSUSX: "Soybean",
  }

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <div className="flex justify-between items-center">
        <div className="flex items-end space-x-8 -mt-4">
          <HomescreenHeader />

          {/* Tab Navigation */}
          {!isPrioritized && (
            <div className="flex space-x-4 mb-1">
              <button
                onClick={() => setActiveTab("sentiment")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "sentiment" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Market Sentiment
              </button>
              <button
                onClick={() => setActiveTab("global")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "global" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Global Markets
              </button>
              <button
                onClick={() => setActiveTab("currencies")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "currencies" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Currencies
              </button>
              <button
                onClick={() => setActiveTab("commodities")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "commodities" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Commodities
              </button>
              <button
                onClick={() => setActiveTab("economy")}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${activeTab === "economy" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
              >
                Global Economy
              </button>
            </div>
          )}
        </div>

        {/* View Toggle Button */}
        <button
          onClick={() => setIsPrioritized(!isPrioritized)}
          className="inline-flex items-center gap-2 px-4 py-2 text-white bg-[#111] border 
            border-[#333] rounded-md hover:bg-[#333] transition-colors"
        >
          <ArrowsRightLeftIcon className="h-5 w-5" />
          {isPrioritized ? "Dashboard View" : "Prioritized View"}
        </button>
      </div>

      {/* Modified Content Section */}
      {isPrioritized ? (
        <div className="grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 gap-4 mt-6">
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
            <EconomicCalendar />
          </div>
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.06] to-black/30 overflow-y-auto">
            <MarketHeadlines />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-6 gap-4 mt-6">
          {activeTab === "sentiment" ? (
            <>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={SYMBOLS}
                  symbolLabels={SYMBOL_LABELS}
                  defaultVisibleSymbols={DEFAULT_VISIBLE_SYMBOLS}
                  height={400}
                  title="US Market Indices"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={GLOBAL_SYMBOLS}
                  symbolLabels={GLOBAL_SYMBOL_LABELS}
                  height={450}
                  title="Global Currencies"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={MACRO_SYMBOLS}
                  symbolLabels={MACRO_SYMBOL_LABELS}
                  height={400}
                  title="Macro Markets"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={CRYPTO_SYMBOLS}
                  symbolLabels={CRYPTO_SYMBOL_LABELS}
                  height={400}
                  title="Commodities"
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                after:pointer-events-none 
                relative 
                bg-gradient-to-b from-black/50 to-black/30
                hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={VOLATILITY_SYMBOLS}
                  symbolLabels={VOLATILITY_LABELS}
                  height={400}
                  title="Market Volatility"
                />
              </div>
              <div className="rounded-lg border border-[#333] p-4 h-[400px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <CentralBankEvents />
              </div>
            </>
          ) : activeTab === "global" ? (
            <>
              {GLOBAL_INDICES_SYMBOLS.map((symbol, index) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: GLOBAL_INDICES_LABELS[symbol] }}
                    height={400}
                    title={GLOBAL_INDICES_LABELS[symbol]}
                    colors={["#FFA726"]}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "currencies" ? (
            <>
              {CURRENCY_PAIRS_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: CURRENCY_PAIRS_LABELS[symbol] }}
                    height={400}
                    title={CURRENCY_PAIRS_LABELS[symbol]}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "economy" ? (
            <div className="col-span-full">
              <GlobalEconomy />
            </div>
          ) : (
            // Commodities Content
            <>
              {COMMODITIES_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.06] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: COMMODITIES_LABELS[symbol] }}
                    height={400}
                    title={COMMODITIES_LABELS[symbol]}
                    colors={["#4CAF50"]}
                  />
                </div>
              ))}
            </>
          )}

          {activeTab !== "economy" && (
            <>
              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <EconomicCalendar />
              </div>

              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.06] to-black/30">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-base font-medium text-white/80">
                    Market Updates
                  </h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setSelectedNewsTab("headlines")}
                      className={`px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                        selectedNewsTab === "headlines"
                          ? "bg-white/5 text-white shadow-lg shadow-white/5"
                          : "text-gray-500 hover:bg-white/5 hover:text-white"
                      }`}
                    >
                      Headlines
                    </button>
                    <button
                      onClick={() => setSelectedNewsTab("articles")}
                      className={`px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                        selectedNewsTab === "articles"
                          ? "bg-white/5 text-white shadow-lg shadow-white/5"
                          : "text-gray-500 hover:bg-white/5 hover:text-white"
                      }`}
                    >
                      Articles
                    </button>
                  </div>
                </div>
                <div className="h-[calc(100%-60px)] overflow-auto">
                  {selectedNewsTab === "headlines" ? (
                    <MarketHeadlines />
                  ) : (
                    <MarketNews />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
