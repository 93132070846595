import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createRouter, Router, RouterProvider } from "@tanstack/react-router"
import * as ReactDOM from "react-dom/client"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

import { FloatingButton } from "./components/FloatingButton/FloatingButton"
import "./index.css"

import {
  AuthProvider,
  EconomicCalendarProvider,
  MarketHeadlinesProvider,
  WebSocketMessage,
  WebSocketProvider,
  LoadingProvider,
  UserProvider,
} from "./providers"

import { routeTree } from "./routeTree.gen"
import { Toaster } from "./components/ui/toaster"
import { AppErrorBoundary } from "@/components/ErrorBoundary"

import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import * as Sentry from "@sentry/react"
import { ResearchProvider } from "@/providers/ResearchProvider"
import { ArticlesProvider } from "./providers/ArticlesProvider"

// Only initialize Sentry in production environment
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "https://app.mrktedge.ai"], // Only trace production domains
  environment: import.meta.env.VITE_ENVIRONMENT,
})

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
})

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!, // Will be set when router is created
  },
})

// Register router for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof Router
  }
}

const handleMessage = (message: WebSocketMessage) => {
  console.log("Received message:", message?.messageText)
}

const handleCalendarResponse = (data: any) => {
  console.log("Calendar Data: ", data)
}

const queryClient = new QueryClient()

function App() {
  return (
    <AppErrorBoundary>
      <LoadingProvider>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              {/* Uncomment after full integration */}
              <ArticlesProvider>
                <MarketHeadlinesProvider>
                  <EconomicCalendarProvider>
                    <ResearchProvider>
                      <WebSocketProvider
                        url1={import.meta.env.VITE_HEADLINES_WEBSOCKET_URL}
                        url2={import.meta.env.VITE_CALENDAR_WEBSOCKET_URL}
                        onMessage1={handleMessage}
                        onMessage2={handleCalendarResponse}
                      >
                        <SimpleBar className="h-screen w-screen">
                          <RouterProvider router={router} />
                          <FloatingButton />
                          <Toaster />
                        </SimpleBar>
                      </WebSocketProvider>
                    </ResearchProvider>
                  </EconomicCalendarProvider>
                </MarketHeadlinesProvider>
              </ArticlesProvider>
            </UserProvider>
          </QueryClientProvider>
        </AuthProvider>
      </LoadingProvider>
    </AppErrorBoundary>
  )
}

// Render the app
const rootElement = document.getElementById("root")!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>,
  )
}
