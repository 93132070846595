import { useState } from "react"
import { createPortal } from "react-dom"
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  addDays,
  isSameMonth,
} from "date-fns"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { ChevronDown, ChevronLeft, ChevronRight, Sun, Moon } from "lucide-react"
import { useNavigate } from "@tanstack/react-router"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useRef, useEffect } from "react"

interface EarningsData {
  date: string
  symbol: string
  eps: number | null
  epsEstimated: number | null
  time: string
  revenue: number | null
  revenueEstimated: number | null
  fiscalDateEnding: string
  updatedFromDate: string
}

type CalendarView = "day" | "week" | "month"

export function EarningsCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [view, setView] = useState<CalendarView>("day")
  const { getAccessTokenSilently } = useAuth0()

  const getDateRange = () => {
    switch (view) {
      case "day":
        return {
          start: startOfDay(currentDate),
          end: endOfDay(currentDate),
          format: "MMMM d, yyyy",
        }
      case "week":
        return {
          start: startOfWeek(currentDate),
          end: endOfWeek(currentDate),
          format: "'Week of' MMM d, yyyy",
        }
      case "month":
      default:
        return {
          start: startOfMonth(currentDate),
          end: endOfMonth(currentDate),
          format: "MMMM yyyy",
        }
    }
  }

  const { start, end, format: dateFormat } = getDateRange()
  const startDate = format(start, "yyyy-MM-dd")
  const endDate = format(end, "yyyy-MM-dd")

  const {
    data: earningsData = [],
    isLoading,
    error,
  } = useQuery<EarningsData[]>({
    queryKey: ["earningsCalendar", startDate, endDate],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        const url = `${import.meta.env.VITE_MRKT_SERVER}/equities/earnings-calendar-by-date?from=${startDate}&to=${endDate}`
        console.log("Fetching earnings data from:", url)

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok) {
          console.error("Error response:", response.status, response.statusText)
          const text = await response.text()
          console.error("Error body:", text)
          throw new Error(
            `Failed to fetch earnings data: ${response.statusText}`,
          )
        }

        const data = await response.json()
        console.log("Received earnings data:", data)
        return data
      } catch (err) {
        console.error("Error fetching earnings data:", err)
        throw err
      }
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    refetchOnWindowFocus: false,
  })

  const getDays = () => {
    switch (view) {
      case "day":
        return [currentDate]
      case "week":
        return eachDayOfInterval({
          start: startOfWeek(currentDate),
          end: endOfWeek(currentDate),
        })
      case "month":
      default:
        // Get the start of the first week of the month
        const monthStart = startOfMonth(currentDate)
        const monthEnd = endOfMonth(currentDate)
        const calendarStart = startOfWeek(monthStart)
        const calendarEnd = endOfWeek(monthEnd)

        return eachDayOfInterval({
          start: calendarStart,
          end: calendarEnd,
        })
    }
  }

  const days = getDays()

  const getEarningsForDate = (date: Date) => {
    const dateStr = format(date, "yyyy-MM-dd")
    return earningsData
      .filter(
        (earning: EarningsData) =>
          earning.date === dateStr &&
          earning.revenueEstimated !== null &&
          !earning.symbol.includes(".") && // Filter out symbols containing dots
          earning.revenueEstimated < 220_000_000_000, // Filter out earnings with revenue over 220B
      )
      .sort((a, b) => {
        // Sort by revenueEstimated in descending order
        return (b.revenueEstimated || 0) - (a.revenueEstimated || 0)
      })
  }

  const handleNavigate = (direction: "prev" | "next") => {
    const amount = direction === "prev" ? -1 : 1
    switch (view) {
      case "day":
        setCurrentDate(
          new Date(currentDate.setDate(currentDate.getDate() + amount)),
        )
        break
      case "week":
        setCurrentDate(
          new Date(currentDate.setDate(currentDate.getDate() + amount * 7)),
        )
        break
      case "month":
        setCurrentDate(
          new Date(currentDate.setMonth(currentDate.getMonth() + amount)),
        )
        break
    }
  }

  const navigate = useNavigate()

  if (isLoading) {
    return (
      <SkeletonTheme baseColor="#333" highlightColor="#444">
        <div className="h-full flex flex-col p-2">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-4">
              <Skeleton className="h-6 w-32" />
              <div className="flex rounded-md overflow-hidden">
                <Skeleton className="h-7 w-[150px]" />
              </div>
            </div>
            <div className="flex gap-2">
              <Skeleton className="h-8 w-24" />
              <Skeleton className="h-8 w-24" />
            </div>
          </div>

          {view === "day" ? (
            <div className="flex-1 overflow-x-auto">
              <table className="w-full min-w-[800px] h-full">
                <thead>
                  <tr>
                    <th className="p-3 w-48">
                      <Skeleton className="h-5 w-full" />
                    </th>
                    <th className="p-3 w-20">
                      <Skeleton className="h-5 w-full" />
                    </th>
                    <th className="p-3">
                      <Skeleton className="h-5 w-full" />
                    </th>
                    <th className="p-3">
                      <Skeleton className="h-5 w-full" />
                    </th>
                    <th className="p-3">
                      <Skeleton className="h-5 w-full" />
                    </th>
                    <th className="p-3">
                      <Skeleton className="h-5 w-full" />
                    </th>
                  </tr>
                </thead>
                <tbody className="h-full">
                  {[...Array(10)].map((_, idx) => (
                    <tr key={idx}>
                      <td className="p-3">
                        <div className="flex items-center gap-3">
                          <Skeleton className="h-8 w-8 rounded-md" />
                          <Skeleton className="h-5 w-24" />
                        </div>
                      </td>
                      <td className="p-3">
                        <Skeleton className="h-5 w-5" />
                      </td>
                      <td className="p-3">
                        <Skeleton className="h-5 w-24" />
                      </td>
                      <td className="p-3">
                        <Skeleton className="h-5 w-24" />
                      </td>
                      <td className="p-3">
                        <Skeleton className="h-5 w-24" />
                      </td>
                      <td className="p-3">
                        <Skeleton className="h-5 w-24" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex-1 flex flex-col min-h-0">
              <div className="grid grid-cols-7 gap-1 text-center mb-2">
                {[...Array(7)].map((_, idx) => (
                  <Skeleton key={idx} className="h-6" />
                ))}
              </div>
              <div className="grid grid-cols-7 gap-1 flex-1">
                {[...Array(view === "week" ? 7 : 35)].map((_, idx) => (
                  <div
                    key={idx}
                    className="relative flex flex-col h-full p-2 rounded-lg bg-black/20"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-2">
                        <Skeleton className="h-7 w-7 rounded" />
                        <Skeleton className="h-4 w-16" />
                      </div>
                    </div>
                    <div className="space-y-1.5 flex-1">
                      {[...Array(3)].map((_, i) => (
                        <div key={i} className="flex items-center gap-2 p-1.5">
                          <Skeleton className="h-6 w-6 rounded" />
                          <div className="flex-1">
                            <Skeleton className="h-4 w-16 mb-1" />
                            <Skeleton className="h-3 w-12" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </SkeletonTheme>
    )
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-red-400">Error loading earnings data</div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col p-2">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-4">
          <div className="text-white/80">{format(currentDate, dateFormat)}</div>
          <div className="flex rounded-md overflow-hidden">
            {(["day", "week", "month"] as const).map((viewType) => (
              <button
                key={viewType}
                onClick={() => setView(viewType)}
                className={`
                  px-3 py-1 text-sm
                  ${
                    view === viewType
                      ? "bg-white/20 text-white"
                      : "bg-white/10 text-white/60 hover:bg-white/15"
                  }
                  capitalize transition-colors
                `}
              >
                {viewType}
              </button>
            ))}
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => handleNavigate("prev")}
            className="px-3 py-1 rounded bg-white/10 hover:bg-white/20 text-white inline-flex items-center gap-1"
          >
            <ChevronLeft className="w-4 h-4" />
            Previous
          </button>
          <button
            onClick={() => handleNavigate("next")}
            className="px-3 py-1 rounded bg-white/10 hover:bg-white/20 text-white inline-flex items-center gap-1"
          >
            Next
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>

      {view === "day" ? (
        <DayView
          date={currentDate}
          earnings={getEarningsForDate(currentDate)}
        />
      ) : (
        <div className="flex-1 flex flex-col min-h-0 overflow-hidden">
          <div className="grid grid-cols-7 gap-1 text-center text-white/60 mb-2">
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <div key={day} className="font-medium">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1 flex-1 min-h-0 overflow-y-auto custom-scrollbar">
            {days.map((date, i) => (
              <CalendarDay
                key={i}
                date={date}
                earnings={getEarningsForDate(date)}
                isCurrentMonth={isSameMonth(date, currentDate)}
                isWeekView={view === "week"}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

interface CalendarDayProps {
  date: Date
  earnings: EarningsData[]
  isCurrentMonth: boolean
  isWeekView?: boolean
}

function CalendarDay({
  date,
  earnings,
  isCurrentMonth,
  isWeekView,
}: CalendarDayProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const cellRef = useRef<HTMLDivElement>(null)
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    openUpward: false,
  })
  const navigate = useNavigate()
  const hasEarnings = earnings.length > 0
  const visibleEarnings = isWeekView ? earnings : earnings.slice(0, 2)
  const remainingCount = isWeekView ? 0 : earnings.length - 2

  useEffect(() => {
    if (isExpanded && cellRef.current) {
      const rect = cellRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      const spaceBelow = viewportHeight - rect.bottom
      const spaceAbove = rect.top
      const openUpward = spaceBelow < 300 && spaceAbove > spaceBelow // 300px is max-height of dropdown

      setDropdownPosition({
        top: openUpward ? rect.top - 4 : rect.bottom + 4,
        left: rect.left,
        width: rect.width,
        openUpward,
      })
    }
  }, [isExpanded])

  useEffect(() => {
    if (isExpanded) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          cellRef.current &&
          !cellRef.current.contains(event.target as Node) &&
          !(event.target as Element).closest(".earnings-dropdown")
        ) {
          setIsExpanded(false)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isExpanded])

  return (
    <div
      ref={cellRef}
      className={`
        relative flex flex-col
        min-h-0 p-2 rounded-lg
        ${isCurrentMonth ? "bg-black/40" : "bg-black/20"}
        ${hasEarnings && !isWeekView ? "hover:bg-white/5 cursor-pointer" : ""}
        transition-colors
        border border-white/[0.06]
        backdrop-blur-sm
        ${isWeekView ? "h-[calc(100vh-12rem)]" : ""}
      `}
      onClick={() => hasEarnings && !isWeekView && setIsExpanded(!isExpanded)}
    >
      <div className="flex justify-between items-center mb-2 flex-shrink-0">
        <div className="flex items-center gap-2">
          <div
            className={`
              flex flex-col items-center justify-center
              w-7 h-7 rounded
              ${isCurrentMonth ? "bg-white/10" : "bg-white/5"}
              ${hasEarnings ? "ring-1 ring-white/20" : ""}
            `}
          >
            <div
              className={`text-sm font-medium ${
                isCurrentMonth ? "text-white/80" : "text-white/40"
              }`}
            >
              {format(date, "d")}
            </div>
          </div>
          {hasEarnings && (
            <div className="text-xs text-white/40">
              {earnings.length} earnings
            </div>
          )}
        </div>
        {hasEarnings && !isWeekView && (
          <ChevronDown
            className={`w-4 h-4 text-white/60 transition-transform ${
              isExpanded ? "rotate-180" : ""
            }`}
          />
        )}
      </div>

      {hasEarnings && (
        <div className={`flex-1 flex flex-col ${isWeekView ? "min-h-0" : ""}`}>
          <div
            className={`space-y-1.5 ${isWeekView ? "overflow-y-auto custom-scrollbar" : ""}`}
          >
            {visibleEarnings.map((earning, idx) => (
              <div
                key={idx}
                className={`
                  flex items-center gap-2 p-1.5 rounded-md
                  ${isWeekView ? "bg-black/20 hover:bg-white/5" : ""}
                  transition-colors
                  cursor-pointer
                `}
                onClick={(e) => {
                  e.stopPropagation()
                  navigate({
                    to: "/equities/symbol/$symbol",
                    params: { symbol: earning.symbol },
                  })
                }}
              >
                <div className="w-6 h-6 rounded bg-black/40 overflow-hidden flex-shrink-0">
                  <img
                    src={`https://financialmodelingprep.com/image-stock/${earning.symbol}.png`}
                    alt={`${earning.symbol} logo`}
                    className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement
                      target.style.display = "none"
                      target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50">${earning.symbol}</div>`
                    }}
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <div className="text-xs text-white/80 font-medium truncate">
                    {earning.symbol}
                  </div>
                  {earning.revenueEstimated && (
                    <div className="text-xs text-white/40 truncate">
                      {earning.revenueEstimated >= 1_000_000_000
                        ? `$${(earning.revenueEstimated / 1_000_000_000).toFixed(1)}B`
                        : `$${(earning.revenueEstimated / 1_000_000).toFixed(0)}M`}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {!isWeekView && remainingCount > 0 && (
            <div className="text-xs text-white/40 mt-1 text-center">
              +{remainingCount} more earnings
            </div>
          )}
        </div>
      )}

      {!isWeekView &&
        isExpanded &&
        createPortal(
          <div
            className={`fixed z-50 shadow-[0_8px_32px_rgba(0,0,0,0.8)]
              backdrop-blur-xl bg-black/95 border border-white/20 rounded-lg p-3
              max-h-[300px] overflow-y-auto custom-scrollbar earnings-dropdown
              ring-1 ring-white/10
              ${dropdownPosition.openUpward ? "origin-bottom" : "origin-top"}
            `}
            style={{
              top: dropdownPosition.openUpward ? "auto" : dropdownPosition.top,
              bottom: dropdownPosition.openUpward
                ? `${window.innerHeight - dropdownPosition.top}px`
                : "auto",
              left: dropdownPosition.left,
              width: dropdownPosition.width,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="space-y-2">
              {earnings.map((earning, idx) => (
                <div
                  key={idx}
                  className="cursor-pointer hover:bg-white/5 rounded-md transition-colors"
                  onClick={() => {
                    navigate({
                      to: "/equities/symbol/$symbol",
                      params: { symbol: earning.symbol },
                    })
                  }}
                >
                  <EarningCard earning={earning} />
                </div>
              ))}
            </div>
          </div>,
          document.body,
        )}
    </div>
  )
}

function DayView({ date, earnings }: { date: Date; earnings: EarningsData[] }) {
  const navigate = useNavigate()

  const formatRevenue = (revenue: number) => {
    if (revenue >= 1_000_000_000) {
      return `$${(revenue / 1_000_000_000).toFixed(2)}B`
    }
    return `$${(revenue / 1_000_000).toFixed(2)}M`
  }

  const getTimeLabel = (time: string) => {
    switch (time.toLowerCase()) {
      case "bmo":
        return "Before Market Open"
      case "amc":
        return "After Market Close"
      default:
        return time
    }
  }

  const TimeIcon = ({ time }: { time: string }) => {
    switch (time.toLowerCase()) {
      case "bmo":
        return <Sun className="w-3.5 h-3.5 text-yellow-500/80" />
      case "amc":
        return <Moon className="w-3.5 h-3.5 text-blue-400/60" />
      default:
        return null
    }
  }

  return (
    <div className="flex-1 overflow-x-auto overflow-y-auto custom-scrollbar">
      {earnings.length === 0 ? (
        <div className="text-white/60 text-center py-4">
          No earnings scheduled for this day
        </div>
      ) : (
        <table className="w-full min-w-[800px]">
          <thead>
            <tr className="bg-black/40 backdrop-blur-sm">
              <th className="text-left p-3 text-sm font-medium text-white/80">
                Company
              </th>
              <th className="text-left p-3 text-sm font-medium text-white/80 w-10">
                Time
              </th>
              <th className="text-right p-3 text-sm font-medium text-white/80">
                Est. Revenue
              </th>
              <th className="text-right p-3 text-sm font-medium text-white/80">
                Revenue
              </th>
              <th className="text-right p-3 text-sm font-medium text-white/80">
                Est. EPS
              </th>
              <th className="text-right p-3 text-sm font-medium text-white/80">
                EPS
              </th>
              <th className="text-right p-3 text-sm font-medium text-white/80">
                Fiscal End
              </th>
            </tr>
          </thead>
          <tbody>
            {earnings.map((earning, idx) => (
              <tr
                key={idx}
                className="bg-black/20 backdrop-blur-sm hover:bg-white/5 transition-colors cursor-pointer"
                onClick={() => {
                  navigate({
                    to: "/equities/symbol/$symbol",
                    params: { symbol: earning.symbol },
                  })
                }}
              >
                <td className="p-3">
                  <div className="flex items-center gap-3">
                    <div className="w-8 h-8 rounded-md bg-black/40 overflow-hidden flex-shrink-0">
                      <img
                        src={`https://financialmodelingprep.com/image-stock/${earning.symbol}.png`}
                        alt={`${earning.symbol} logo`}
                        className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement
                          target.style.display = "none"
                          target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50">${earning.symbol}</div>`
                        }}
                      />
                    </div>
                    <span className="font-medium text-white/90">
                      {earning.symbol}
                    </span>
                  </div>
                </td>
                <td className="p-3">
                  <TimeIcon time={earning.time} />
                </td>
                <td className="p-3 text-right text-white/80">
                  {earning.revenueEstimated
                    ? formatRevenue(earning.revenueEstimated)
                    : "-"}
                </td>
                <td className="p-3 text-right text-white/80">
                  {earning.revenue ? formatRevenue(earning.revenue) : "-"}
                </td>
                <td className="p-3 text-right text-white/80">
                  {earning.epsEstimated
                    ? `$${earning.epsEstimated.toFixed(2)}`
                    : "-"}
                </td>
                <td className="p-3 text-right text-white/80">
                  {earning.eps ? `$${earning.eps.toFixed(2)}` : "-"}
                </td>
                <td className="p-3 text-right text-white/60">
                  {format(new Date(earning.fiscalDateEnding), "MMM d, yyyy")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

function EarningCard({
  earning,
  expanded = false,
}: {
  earning: EarningsData
  expanded?: boolean
}) {
  const formatRevenue = (revenue: number) => {
    if (revenue >= 1_000_000_000) {
      return `$${(revenue / 1_000_000_000).toFixed(2)}B`
    }
    return `$${(revenue / 1_000_000).toFixed(2)}M`
  }

  return (
    <div
      className="text-xs bg-black/40 backdrop-blur-sm rounded-lg p-2 
      hover:bg-white/5 transition-colors border border-white/[0.06]"
    >
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 rounded bg-black/40 overflow-hidden flex-shrink-0">
          <img
            src={`https://financialmodelingprep.com/image-stock/${earning.symbol}.png`}
            alt={`${earning.symbol} logo`}
            className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
            onError={(e) => {
              const target = e.target as HTMLImageElement
              target.style.display = "none"
              target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50">${earning.symbol}</div>`
            }}
          />
        </div>
        <div>
          <div className="font-medium text-white/90">{earning.symbol}</div>
          <div className="mt-1 space-y-0.5">
            {!expanded && earning.revenueEstimated && (
              <div className="text-white/60">
                Rev: {formatRevenue(earning.revenueEstimated)}
              </div>
            )}
            {expanded && (
              <>
                {earning.revenueEstimated && (
                  <div className="text-white/60">
                    Est. Revenue: {formatRevenue(earning.revenueEstimated)}
                  </div>
                )}
                {earning.revenue && (
                  <div className="text-white/60">
                    Actual Revenue: {formatRevenue(earning.revenue)}
                  </div>
                )}
                {earning.epsEstimated && (
                  <div className="text-white/60">
                    Est. EPS: ${earning.epsEstimated.toFixed(2)}
                  </div>
                )}
                {earning.eps && (
                  <div className="text-white/60">
                    Actual EPS: ${earning.eps.toFixed(2)}
                  </div>
                )}
                <div className="text-white/60">
                  Time: {earning.time.toUpperCase()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
