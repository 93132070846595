import {
  X,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  Minus,
  TrendingDown,
  TrendingUp,
  ExternalLink,
  FileText,
  Sparkles,
  XCircle,
  Info,
  Building2,
  MessageCircle,
  Quote,
} from "lucide-react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { HeadlineBadge } from "../MarketHeadlines"
import { Tab } from "@headlessui/react"
import { formatEventDate } from "../../utilities"
import { useState, useEffect } from "react"
import { DNA } from "react-loader-spinner"
import { format, subMonths } from "date-fns"
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts"
import { format as formatDate, parseISO } from "date-fns"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useAuth0 } from "@auth0/auth0-react"
import { formatValue, getCountryFlag } from "./EconomicCalendar"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  getCentralBankName,
  getEventImage,
  noAIExplanationEvents,
} from "./utils"

interface EventAnalysisPanelProps {
  event: any
  onClose: () => void
}

/* eslint-disable @typescript-eslint/no-unused-vars */
interface HistoricalDataQueryProps {
  eventId: string
  preliminary: boolean
  amount: number
  dateFromUtc: string
  dateToUtc: string
}
/* eslint-enable @typescript-eslint/no-unused-vars */
const SummaryLoadingSkeleton = ({
  messages,
  currentIndex,
}: {
  messages: string[]
  currentIndex: number
}) => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center justify-center py-8">
        <div className="flex gap-1">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ color: "orange" }}
            wrapperClass="dna-wrapper"
          />
        </div>
        <h2 className="text-xl font-medium text-white mb-4">
          {messages[currentIndex]}
        </h2>
      </div>

      <div className="space-y-6">
        <div>
          <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
          <div className="space-y-2">
            <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
            <div className="h-4 bg-zinc-800/50 rounded w-3/4"></div>
          </div>
        </div>

        <div>
          <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
          <div className="space-y-2">
            <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
            <div className="h-4 bg-zinc-800/50 rounded w-5/6"></div>
            <div className="h-4 bg-zinc-800/50 rounded w-4/5"></div>
          </div>
        </div>

        <div>
          <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
          <div className="space-y-2">
            <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
            <div className="h-4 bg-zinc-800/50 rounded w-3/4"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const getImpactIcon = (impact: string) => {
  switch (impact.toLowerCase()) {
    case "bearish":
      return <ArrowDown className="w-4 h-4 text-red-500" aria-label="Bearish" />
    case "bullish":
      return <ArrowUp className="w-4 h-4 text-green-500" aria-label="Bullish" />
    case "slightly bearish":
      return (
        <TrendingDown
          className="w-4 h-4 text-red-400"
          aria-label="Slightly Bearish"
        />
      )
    case "slightly bullish":
      return (
        <TrendingUp
          className="w-4 h-4 text-green-400"
          aria-label="Slightly Bullish"
        />
      )
    case "neutral":
      return <Minus className="w-4 h-4 text-yellow-500" aria-label="Neutral" />
    default:
      return (
        <ArrowRight
          className="w-4 h-4 text-gray-500"
          aria-label="Unknown impact"
        />
      )
  }
}

export const HistoricalAreaChart = ({
  data,
  unit,
  potency,
}: {
  data: any
  unit: string
  potency: string
}) => {
  const chartData = data
    .sort(
      (a: any, b: any) =>
        new Date(a.dateUtc).getTime() - new Date(b.dateUtc).getTime(),
    )
    .map((item: any) => ({
      date: formatDate(parseISO(item.dateUtc), "MMM yyyy"),
      actual: item.actual,
    }))

  return (
    <div className="w-full h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={chartData}
          margin={{
            top: 5,
            right: 10,
            left: -10,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#10B981" stopOpacity={0.4} />
              <stop offset="95%" stopColor="#059669" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            stroke="#666"
            tick={{ fill: "#666", fontSize: 12 }}
            axisLine={{ stroke: "#333" }}
            tickLine={false}
          />
          <YAxis
            stroke="#666"
            tick={{ fill: "#666", fontSize: 12 }}
            axisLine={{ stroke: "#333" }}
            tickLine={false}
            tickFormatter={(value) => formatValue(value, unit, potency)}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            }}
            labelStyle={{ color: "#999", fontWeight: 500, marginBottom: "4px" }}
            itemStyle={{ color: "#fff", fontSize: "14px", padding: "4px 0" }}
            formatter={(value: number) => [
              formatValue(value, unit, potency),
              "Actual",
            ]}
          />
          <Area
            type="monotone"
            dataKey="actual"
            stroke="#10B981"
            strokeWidth={2}
            fill="url(#colorActual)"
            dot={false}
            activeDot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

interface SummaryQueryParams {
  url: string
  eventName: string
  date: string
  country: string
}

export const EventAnalysisPanel = ({
  event,
  onClose,
}: EventAnalysisPanelProps) => {
  const queryClient = useQueryClient()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [summaryData, setSummaryData] = useState<any>(null)
  const [shouldFetchSummary, setShouldFetchSummary] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState<string[]>([
    "Evaluating economic event...",
    "Analyzing market implications...",
    "Processing historical context...",
    "Evaluating key indicators...",
    "Synthesizing market insights...",
    "Highlighting key points...",
    "Generating comprehensive summary...",
  ])
  const [currentLoadingIndex, setCurrentLoadingIndex] = useState(0)
  const hasEventPassed = new Date(event.dateUtc) < new Date()
  const { getAccessTokenSilently } = useAuth0()
  const [summaryError, setSummaryError] = useState(false)

  // Reset states when event changes
  useEffect(() => {
    setSummaryData(null)
    setShouldFetchSummary(false)
    setCurrentLoadingIndex(0)
    setSelectedTabIndex(0)
    // Invalidate the previous summary query
    queryClient.removeQueries({ queryKey: ["eventSummary"] })
  }, [event.id, queryClient])

  const { data: historicalData, isLoading: isLoadingHistorical } = useQuery({
    queryKey: ["eventHistorical", event.eventId],
    queryFn: async () => {
      // Calculate dates based on periodType
      const token = await getAccessTokenSilently()
      const toDate = new Date()
      const fromDate =
        event.periodType === "MONTH"
          ? subMonths(toDate, 24)
          : subMonths(toDate, 12)

      const params = new URLSearchParams({
        eventId: event.eventId,
        preliminary: "true",
        amount: "100",
        dateFromUtc: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        dateToUtc: format(toDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      })

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/historical?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch historical data")
      }
      return response.json()
    },
    enabled: !!event.eventId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { data: eventDetails, isLoading: isLoadingEventDetails } = useQuery({
    queryKey: ["eventDetails", event.id],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const params = new URLSearchParams({
        eventId: event.id,
      })

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/get-event-details?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch event details")
      }
      return response.json()
    },
    enabled: !!event.eventId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  console.log("eventDetails", eventDetails)
  const {
    mutate: analyzeEvent,
    data: analysis,
    isPending,
  } = useMutation({
    mutationKey: ["eventAnalysis", event.name],
    mutationFn: async () => {
      const token = await getAccessTokenSilently()

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/calendar/analyze`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            event: event.name,
            country: event.countryCode,
            date: event.dateUtc,
            impact: event.volatility.toLowerCase(),
            previous: event.previous?.toString() ?? "undefined",
            forecast: event.consensus?.toString() ?? "undefined",
            eventId: event.id,
            historicalData: historicalData,
          }),
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch analysis")
      }
      const data = await response.json()
      return data
    },
  })

  // Trigger the analysis when we have both historical data and event details
  useEffect(() => {
    if (historicalData && eventDetails && event.name) {
      analyzeEvent()
    }
  }, [historicalData, eventDetails, event.name, analyzeEvent])

  useEffect(() => {
    if (analysis) {
      console.log("Analysis data updated")
    }
  }, [analysis])

  useEffect(() => {
    if (eventDetails) {
      console.log("Event details updated")
    }
  }, [eventDetails])

  const eventDescription = eventDetails?.description
  const eventSignificance =
    eventDetails?.whyMatters ?? analysis?.data?.analysis?.event_significance
  const eventSourceUrl =
    eventDetails?.urlSource ?? eventDetails?.officialSourceUrl
  const urlSource = eventDetails?.urlSource

  const isAIExplanationDisabled =
    noAIExplanationEvents.includes(event.name) || event.isSpeech || event.allDay

  const isEventSpeech = event.isSpeech && event.countryCode === "US" //for now we only want to show the AI explanation for US speeches
  const summaryButtonText = isEventSpeech
    ? "Generate Speech Summary"
    : "Generate Report Summary"

  const { data: aiSummary, isLoading: isSummaryLoading } = useQuery({
    queryKey: ["eventSummary", event.id, urlSource],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()

        const params = new URLSearchParams({
          url: urlSource!,
          event: event.name,
          date: event.dateUtc,
          country: event.countryCode,
          isSpeech: isEventSpeech ? "true" : "false",
        })

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/calendar/get-summary?${params}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (!response.ok) {
          throw new Error("Failed to fetch summary")
        }
        setSummaryError(false)
        return response.json()
      } catch (error) {
        setSummaryError(true)
        throw error
      }
    },
    enabled: !!urlSource && shouldFetchSummary,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    if (aiSummary) {
      setSummaryData(aiSummary)
    }
  }, [aiSummary])

  useEffect(() => {
    if (isSummaryLoading && currentLoadingIndex < loadingMessages.length - 1) {
      const timer = setTimeout(() => {
        setCurrentLoadingIndex((prev) => prev + 1)
      }, 2500) // Change message every 2.5 seconds

      return () => clearTimeout(timer)
    }
  }, [isSummaryLoading, currentLoadingIndex, loadingMessages.length])

  const handleGetSummary = () => {
    setShouldFetchSummary(true)
    setSelectedTabIndex(2) // Switch to the Report Summary tab (index 2)
    setCurrentLoadingIndex(0) // Reset the loading message index
  }

  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  }

  const highlightText = (text: string, keyPoints: string[]) => {
    let highlightedText = text
    keyPoints.forEach((point) => {
      const escapedPoint = escapeRegExp(point)
      const regex = new RegExp(escapedPoint, "gi")
      highlightedText = highlightedText.replace(
        regex,
        `<span class="bg-yellow-500/30 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
      )
    })
    return (
      <p
        className="text-[16px] text-gray-400 [&_a]:text-purple-400 [&_a]:hover:text-purple-300 [&_a]:transition-colors"
        dangerouslySetInnerHTML={{ __html: highlightedText }}
      />
    )
  }

  const shouldShowSummaryButton = urlSource && !event.isSpeech

  const renderHtmlContent = (content: string) => {
    return (
      <p
        className="text-[16px] text-gray-400 [&_a]:text-purple-400 [&_a]:hover:text-purple-300 [&_a]:transition-colors"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )
  }

  return (
    <div className="h-full w-full flex flex-col overflow-hidden relative">
      <SimpleBar
        className="flex-1 h-full scrollbar-hide"
        style={{ width: "100%" }}
      >
        <div className="p-6">
          <div className="flex flex-col mb-6">
            <div className="flex justify-between items-start mb-2">
              <div className="flex flex-col gap-1">
                <h2 className="text-xl font-medium text-white">{event.name}</h2>
                <span className="text-md font-medium text-gray-400">
                  {formatEventDate(event.dateUtc)}
                </span>
              </div>
              <button
                onClick={onClose}
                className="rounded-md text-white hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="flex items-center gap-2 mt-2">
              <div className="flex items-center gap-1 bg-zinc-500/30 px-3 py-1 rounded-md">
                {getCountryFlag(event.countryCode)}
                <span className="text-md text-gray-200">
                  {event.countryCode}
                </span>
              </div>
              <span className="flex items-center gap-1 bg-zinc-500/30 px-3 py-1 rounded-md">
                {event.currencyCode}
              </span>
              {HeadlineBadge(
                event.name === "Retail Sales (YoY)" ? "HIGH" : event.volatility,
              )}
              {eventSourceUrl && (
                <a
                  href={eventSourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-400 hover:text-purple-300 flex items-center gap-2"
                >
                  <ExternalLink className="w-4 h-4" />
                  Source
                </a>
              )}
            </div>
          </div>

          {isEventSpeech ? (
            <div className="bg-zinc-900/50 rounded-lg border border-[#333] p-6">
              <div className="space-y-6">
                <div className="flex items-center gap-8">
                  <div className="w-44 h-44 rounded-xl overflow-hidden bg-zinc-800 flex-shrink-0 ring-1 ring-[#333] shadow-lg">
                    {getEventImage(event.name) ? (
                      <img
                        src={getEventImage(event.name)}
                        alt={event.name}
                        className="w-full h-full object-cover object-center object-[center_20%]"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-zinc-800">
                        <FileText className="w-12 h-12 text-zinc-500" />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col px-2 py-4 rounded-lg">
                    <span className="text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Speaker
                    </span>
                    <span className="text-2xl font-medium text-white mt-2">
                      {event.name === "Fed Monetary Policy Statement"
                        ? "Fed's Powell"
                        : event.name.replace(" speech", "")}
                    </span>
                    <span className="inline-flex items-center text-sm text-gray-400 mt-2">
                      <Building2 className="w-4 h-4 mr-1.5" />
                      {getCentralBankName(event.countryCode)}
                    </span>
                  </div>
                </div>

                <div className="prose prose-invert max-w-none prose-sm">
                  {isLoadingEventDetails ? (
                    <div className="space-y-4">
                      <Skeleton
                        count={6}
                        height={20}
                        baseColor="#27272a"
                        highlightColor="#3f3f46"
                        className="my-2"
                      />
                    </div>
                  ) : (
                    eventDescription && renderHtmlContent(eventDescription)
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-zinc-900/50 rounded-lg border border-[#333] p-4">
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col gap-1">
                  <span className="text-md font-medium text-gray-400">
                    Actual
                  </span>
                  <div className="flex items-center gap-2 rounded-md py-2">
                    <span className="text-md font-medium text-white">
                      {event.actual
                        ? formatValue(event.actual, event.unit, event.potency)
                        : hasEventPassed
                          ? "-"
                          : "Pending"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-md font-medium text-gray-400">
                    Forecast
                  </span>
                  <div className="flex items-center gap-2 rounded-md py-2">
                    <span className="text-md font-medium text-white">
                      {event.consensus
                        ? formatValue(
                            event.consensus,
                            event.unit,
                            event.potency,
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-md font-medium text-gray-400">
                    Previous
                  </span>
                  <div className="flex items-center gap-2 rounded-md py-2">
                    <span className="text-md font-medium text-white">
                      {event.previous
                        ? formatValue(event.previous, event.unit, event.potency)
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {analysis?.data?.analysis?.actual_reading_estimate &&
            !isAIExplanationDisabled && (
              <div className="bg-gradient-to-br from-purple-500/10 via-indigo-500/10 to-blue-500/10 rounded-lg border border-purple-500/20 p-4 mt-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <Sparkles className="w-5 h-5 text-purple-400" />
                    <h3 className="text-md font-medium text-white">
                      AI Prediction
                    </h3>
                  </div>
                </div>

                <div className="flex items-center justify-between bg-black/20 p-4 rounded-lg mb-4">
                  <div>
                    <span className="text-sm text-gray-400">
                      Estimated Actual
                    </span>
                    <div className="text-2xl font-medium text-purple-400 mt-1">
                      {formatValue(
                        Number(analysis.data.analysis.actual_reading_estimate),
                        event.unit,
                        event.potency,
                      )}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div>
                      <span className="text-sm text-gray-400">Min</span>
                      <div className="text-md font-medium text-gray-300 mt-1">
                        {formatValue(
                          Number(analysis.data.analysis.minimum_expectation),
                          event.unit,
                          event.potency,
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="text-sm text-gray-400">Max</span>
                      <div className="text-md font-medium text-gray-300 mt-1">
                        {formatValue(
                          Number(analysis.data.analysis.maximum_expectation),
                          event.unit,
                          event.potency,
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-2 bg-black/20 p-3 rounded-lg">
                  <Info className="w-4 h-4 text-gray-400 mt-1 flex-shrink-0" />
                  <p className="text-sm text-gray-400">
                    Disclaimer: AI predictions are based on historical patterns,
                    statistical deviations, and current market conditions. These
                    insights should be used as one of several tools in your
                    analysis and not as a sole basis for decision-making. Always
                    consider other analysis methods and consult with a financial
                    professional when necessary.
                  </p>
                </div>
              </div>
            )}

          {!isPending && eventDetails?.urlSource && (
            <div className="mt-4 space-y-4">
              {(shouldShowSummaryButton || isEventSpeech) && (
                <button
                  onClick={handleGetSummary}
                  disabled={isSummaryLoading || summaryData !== null}
                  className="flex items-center gap-2 text-sm font-medium bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 hover:from-blue-700 hover:via-indigo-700 hover:to-purple-700 text-white px-5 py-2.5 rounded-lg transition-all duration-200 transform hover:scale-[1.02] hover:shadow-xl hover:shadow-indigo-500/25 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:shadow-none border border-indigo-400/20"
                >
                  <Sparkles className="w-4 h-4" />
                  {summaryData !== null
                    ? "Summary Generated"
                    : summaryButtonText}
                </button>
              )}
            </div>
          )}

          {isPending || isLoadingHistorical || isLoadingEventDetails ? (
            <SummaryLoadingSkeleton
              messages={loadingMessages}
              currentIndex={currentLoadingIndex}
            />
          ) : (
            <>
              {historicalData &&
                historicalData.some((item: any) => item.actual !== null) && (
                  <div className="mt-6 space-y-6">
                    <div>
                      <h3 className="text-lg font-medium text-white mb-2">
                        Historical Data
                      </h3>
                      <HistoricalAreaChart
                        data={historicalData.filter(
                          (item: any) => item.actual !== null,
                        )}
                        unit={event.unit}
                        potency={event.potency}
                      />
                    </div>
                  </div>
                )}

              <Tab.Group
                selectedIndex={selectedTabIndex}
                onChange={setSelectedTabIndex}
              >
                <Tab.List className="w-full grid grid-cols-3 bg-zinc-900 rounded-md p-1 mt-4">
                  <Tab
                    className={({ selected }) =>
                      `w-full h-8 rounded-sm transition-colors ${
                        selected
                          ? "bg-zinc-800 text-white"
                          : "text-gray-400 hover:text-white"
                      }`
                    }
                  >
                    Analysis
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      `w-full h-8 rounded-sm transition-colors ${
                        selected
                          ? "bg-zinc-800 text-white"
                          : "text-gray-400 hover:text-white"
                      }`
                    }
                  >
                    Impact
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      `w-full h-8 rounded-sm transition-colors ${
                        selected
                          ? "bg-zinc-800 text-white"
                          : "text-gray-400 hover:text-white"
                      }`
                    }
                  >
                    Report Summary
                  </Tab>
                </Tab.List>

                <Tab.Panels>
                  <Tab.Panel className="space-y-6">
                    {!isEventSpeech && (
                      <div>
                        <h3 className="font-bold text-lg text-white mb-3 mt-4">
                          Event Details
                        </h3>
                        {eventDescription &&
                          renderHtmlContent(eventDescription)}
                      </div>
                    )}

                    {eventSignificance && (
                      <div>
                        <h3 className="font-bold text-lg text-white mb-3 mt-4">
                          Event Significance
                        </h3>
                        <p className="text-[16px] text-gray-400">
                          {eventSignificance}
                        </p>
                      </div>
                    )}

                    <div>
                      <h3 className="font-bold text-lg text-white mb-3">
                        Market Analysis
                      </h3>
                      <p className="text-[16px] text-gray-400 mb-4">
                        {analysis?.data?.analysis?.analysis ||
                          "No data available"}
                      </p>

                      <h4 className="font-bold text-lg text-white mb-3">
                        Market Expectations
                      </h4>
                      <p className="text-[16px] text-gray-400 mb-4">
                        {analysis?.data?.analysis?.market_expectations ||
                          "No data available"}
                      </p>

                      <h4 className="font-bold text-lg text-white mb-3">
                        Potential Risks
                      </h4>
                      <p className="text-[16px] text-gray-400">
                        {analysis?.data?.analysis?.potential_risks ||
                          "No data available"}
                      </p>
                    </div>
                  </Tab.Panel>

                  <Tab.Panel className="mt-4">
                    <div>
                      {analysis?.data?.analysis?.expected_asset_impacts && (
                        <div className="space-y-6">
                          {Object.entries(
                            analysis.data.analysis.expected_asset_impacts,
                          ).map(([scenario, impacts]) => (
                            <div key={scenario}>
                              <h4 className="text-md font-medium text-white/80 capitalize mb-3">
                                {scenario.replace(/_/g, " ")}:
                              </h4>
                              <div className="grid gap-3">
                                {Object.entries(
                                  impacts as Record<string, string>,
                                ).map(([asset, impact]) => (
                                  <div
                                    key={asset}
                                    className="flex items-center justify-between bg-zinc-900/50 hover:bg-zinc-900/70 transition-colors rounded-lg p-3 border border-zinc-800/50"
                                  >
                                    <div className="flex items-center space-x-3">
                                      <span className="text-white text-md font-medium">
                                        {asset.toLocaleUpperCase()}
                                      </span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                      {getImpactIcon(impact)}
                                      <span className="text-md text-gray-300">
                                        {impact.toLocaleUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Tab.Panel>

                  <Tab.Panel className="space-y-6">
                    {summaryData ? (
                      <div>
                        {isEventSpeech && summaryData.tone && (
                          <div className="mt-8 mb-8">
                            {/* Classification Badge */}
                            <div className="inline-flex items-center gap-2 px-3 py-1.5 bg-zinc-800 rounded-md border border-zinc-700/50 mb-6">
                              <MessageCircle className="w-4 h-4 text-zinc-400" />
                              <span className="text-sm font-medium text-zinc-200">
                                {summaryData?.tone?.classification} Tone
                              </span>
                            </div>

                            {/* Key Statements */}
                            <div className="space-y-3">
                              {summaryData?.tone?.supporting_headlines.map(
                                (headline: string, index: number) => (
                                  <div
                                    key={index}
                                    className="text-[15px] leading-relaxed text-zinc-300 border-l-4 border-zinc-700 pl-4 py-1 hover:border-zinc-500 transition-colors"
                                  >
                                    {headline}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        )}

                        <div>
                          <h3 className="font-bold text-lg text-white mb-3 mt-4">
                            {isEventSpeech
                              ? "Speech Overview"
                              : "Report Overview"}
                          </h3>
                          {highlightText(
                            summaryData.summary.report_overview.text,
                            summaryData.summary.report_overview.key_points,
                          )}
                        </div>

                        <div>
                          <h3 className="font-bold text-lg text-white mb-3 mt-4">
                            Key Findings
                          </h3>
                          {highlightText(
                            summaryData.summary.key_findings.text,
                            summaryData.summary.key_findings.key_points,
                          )}
                        </div>

                        <div>
                          <h3 className="font-bold text-lg text-white mb-3 mt-4">
                            Broader Context
                          </h3>
                          {highlightText(
                            summaryData.summary.broader_context.text,
                            summaryData.summary.broader_context.key_points,
                          )}
                        </div>

                        <div>
                          <h3 className="font-bold text-lg text-white mb-3 mt-4">
                            Risks & Implications
                          </h3>
                          {highlightText(
                            summaryData.summary.risks_and_implications.text,
                            summaryData.summary.risks_and_implications
                              .key_points,
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {summaryError ? (
                          <div className="flex flex-col items-center justify-center py-12">
                            <div className="bg-zinc-900/50 rounded-xl p-8 max-w-md text-center">
                              <XCircle className="w-12 h-12 text-red-500/70 mx-auto mb-4" />
                              <h3 className="text-lg font-medium text-white mb-2">
                                Unable to Generate Summary
                              </h3>
                              <p className="text-zinc-400 text-sm mb-4">
                                Sorry, we couldn't generate a summary for this
                                report. Please visit the official source for the
                                complete report.
                              </p>
                              <a
                                href={
                                  eventDetails?.urlSource ??
                                  eventDetails?.officialSourceUrl
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center gap-2 text-sm font-medium bg-zinc-800 hover:bg-zinc-700 text-white px-5 py-2.5 rounded-lg transition-all duration-200"
                              >
                                <ExternalLink className="w-4 h-4" />
                                Visit Official Source
                              </a>
                            </div>
                          </div>
                        ) : isSummaryLoading ? (
                          <SummaryLoadingSkeleton
                            messages={loadingMessages}
                            currentIndex={currentLoadingIndex}
                          />
                        ) : (
                          <div className="flex flex-col items-center justify-center py-12">
                            <div className="bg-zinc-900/50 rounded-xl p-8 max-w-md text-center">
                              <FileText className="w-12 h-12 text-zinc-600 mx-auto mb-4" />
                              <h3 className="text-lg font-medium text-white mb-2">
                                No Report Summary Available
                              </h3>
                              <p className="text-zinc-400 text-sm mb-6">
                                {shouldShowSummaryButton
                                  ? "Generate an AI-powered summary of this economic report to get key insights, analysis, and market implications."
                                  : "No report source available for this economic event."}
                              </p>
                              {shouldShowSummaryButton && (
                                <button
                                  onClick={handleGetSummary}
                                  disabled={
                                    isSummaryLoading || summaryData !== null
                                  }
                                  className="flex items-center gap-2 text-sm font-medium bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 hover:from-blue-700 hover:via-indigo-700 hover:to-purple-700 text-white px-5 py-2.5 rounded-lg transition-all duration-200 transform hover:scale-[1.02] hover:shadow-xl hover:shadow-indigo-500/25 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:shadow-none border border-indigo-400/20"
                                >
                                  <Sparkles className="w-4 h-4" />
                                  Generate AI Report Summary
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </>
          )}
        </div>
      </SimpleBar>
    </div>
  )
}
