import { Fragment, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { format } from "date-fns"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

type TimeRange = "1W" | "1M" | "3M" | "1Y" | "2Y"

interface SectorPerformanceData {
  date: string
  basicMaterialsChangesPercentage: number
  communicationServicesChangesPercentage: number
  consumerCyclicalChangesPercentage: number
  consumerDefensiveChangesPercentage: number
  energyChangesPercentage: number
  financialServicesChangesPercentage: number
  healthcareChangesPercentage: number
  industrialsChangesPercentage: number
  realEstateChangesPercentage: number
  technologyChangesPercentage: number
  utilitiesChangesPercentage: number
}

const timeRanges: TimeRange[] = ["1W", "1M", "3M", "1Y", "2Y"]

const sectorColors = {
  basicMaterials: "rgba(255, 255, 255, 0.7)",
  communicationServices: "rgba(147, 197, 253, 0.7)",
  consumerCyclical: "rgba(167, 139, 250, 0.7)",
  consumerDefensive: "rgba(248, 113, 113, 0.7)",
  energy: "rgba(74, 222, 128, 0.7)",
  financialServices: "rgba(251, 191, 36, 0.7)",
  healthcare: "rgba(236, 72, 153, 0.7)",
  industrials: "rgba(129, 140, 248, 0.7)",
  realEstate: "rgba(244, 114, 182, 0.7)",
  technology: "rgba(34, 211, 238, 0.7)",
  utilities: "rgba(163, 230, 53, 0.7)",
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-black/90 backdrop-blur-sm border border-white/10 rounded-lg p-3 shadow-xl">
        <p className="text-gray-400 text-xs font-medium mb-2">{label}</p>
        <div className="max-h-[200px] overflow-y-auto">
          {payload
            .sort((a: any, b: any) => b.value - a.value)
            .map((entry: any) => (
              <p
                key={entry.name}
                style={{ color: entry.color }}
                className="font-medium text-sm py-0.5 flex items-center justify-between gap-4"
              >
                <span className="truncate">{entry.name}</span>
                <span className="font-bold">{entry.value.toFixed(2)}%</span>
              </p>
            ))}
        </div>
      </div>
    )
  }
  return null
}

interface SectorToggleProps {
  sector: string
  color: string
  performance: number
  isActive: boolean
  onToggle: () => void
}

const SectorToggle = ({
  sector,
  color,
  performance,
  isActive,
  onToggle,
}: SectorToggleProps) => {
  const isPositive = performance >= 0
  const performanceValue = `${isPositive ? "+" : ""}${performance.toFixed(2)}%`

  return (
    <button
      onClick={onToggle}
      className={`flex items-center w-full py-1.5 px-2 rounded-md transition-all ${
        isActive
          ? "bg-neutral-800/50 hover:bg-neutral-800/70"
          : "opacity-50 hover:opacity-80 hover:bg-neutral-900/50"
      }`}
    >
      <div className="flex items-center gap-2.5 flex-1">
        <div
          className={`w-1 h-3 rounded-sm transition-opacity ${
            isActive ? "opacity-100" : "opacity-40"
          }`}
          style={{ backgroundColor: color }}
        />
        <span
          className={`text-sm ${isActive ? "text-gray-200" : "text-gray-500"}`}
        >
          {sector}
        </span>
        <span
          className={`text-sm ml-auto font-medium ${
            isPositive ? "text-emerald-500" : "text-red-500"
          }`}
        >
          {performanceValue}
        </span>
      </div>
    </button>
  )
}

function SectorButton({
  sector,
  color,
  isVisible,
  onToggle,
}: {
  sector: string
  color: string
  isVisible: boolean
  onToggle: () => void
}) {
  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={onToggle}
      className={cn(
        "text-[11px] h-7 transition-all duration-200 flex items-center gap-1.5 rounded-md px-2",
        "border border-transparent hover:border-white/10 backdrop-blur-sm",
        isVisible
          ? "bg-white/5 text-white hover:bg-white/10"
          : "text-gray-500 hover:text-white opacity-40 hover:opacity-100",
      )}
    >
      <div
        className="w-1.5 h-1.5 rounded-full"
        style={{ backgroundColor: color }}
      />
      <span className="tracking-wide font-medium">{sector}</span>
    </Button>
  )
}

export function SectorPerformance() {
  const [selectedRange, setSelectedRange] = useState<TimeRange>("1M")
  const [activeSectors, setActiveSectors] = useState<Set<string>>(() => {
    const initialSectors = [
      "Technology",
      "Financial Services",
      "Communication Services",
      "Healthcare",
    ]
    return new Set(initialSectors)
  })
  const { getAccessTokenSilently } = useAuth0()

  const { data, isLoading } = useQuery({
    queryKey: ["sectorPerformance", selectedRange],
    queryFn: async () => {
      const to = new Date()
      const from = new Date()

      switch (selectedRange) {
        case "1W":
          from.setDate(to.getDate() - 7)
          break
        case "1M":
          from.setMonth(to.getMonth() - 1)
          break
        case "3M":
          from.setMonth(to.getMonth() - 3)
          break
        case "1Y":
          from.setFullYear(to.getFullYear() - 1)
          from.setMonth(to.getMonth())
          from.setDate(to.getDate())
          break
        case "2Y":
          from.setFullYear(to.getFullYear() - 2)
          from.setMonth(to.getMonth())
          from.setDate(to.getDate())
          break
      }

      const url = new URL(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/sector-performance`,
      )
      url.searchParams.append("from", format(from, "yyyy-MM-dd"))
      url.searchParams.append("to", format(to, "yyyy-MM-dd"))

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch sector performance data")
      }
      return response.json()
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
    // cacheTime: 0,
    retry: 1,
  })

  if (isLoading) {
    return (
      <Card className="h-full w-full bg-inherit border-none p-0">
        <CardHeader className="p-2">
          <div className="flex justify-between items-center">
            <CardTitle className="text-lg font-medium text-white">
              <Skeleton
                width={150}
                height={24}
                baseColor="#222"
                highlightColor="#333"
              />
            </CardTitle>
            <div className="flex gap-2">
              {[1, 2, 3, 4, 5].map((i) => (
                <Skeleton
                  key={i}
                  width={40}
                  height={28}
                  className="rounded"
                  baseColor="#222"
                  highlightColor="#333"
                />
              ))}
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-0 h-[calc(100%-4rem)] flex">
          <div className="flex-1">
            <Skeleton height="100%" baseColor="#222" highlightColor="#333" />
          </div>
          <div className="w-72 border-l border-neutral-800/50 py-3 px-4 space-y-1">
            <Skeleton
              width={60}
              height={16}
              className="mb-3"
              baseColor="#222"
              highlightColor="#333"
            />
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
              <Skeleton
                key={i}
                height={32}
                className="mb-1"
                baseColor="#222"
                highlightColor="#333"
              />
            ))}
          </div>
        </CardContent>
      </Card>
    )
  }

  const chartData = data?.map((item: SectorPerformanceData) => ({
    date: format(new Date(item.date), "MMM d, yyyy"),
    "Basic Materials": item.basicMaterialsChangesPercentage,
    "Communication Services": item.communicationServicesChangesPercentage,
    "Consumer Cyclical": item.consumerCyclicalChangesPercentage,
    "Consumer Defensive": item.consumerDefensiveChangesPercentage,
    Energy: item.energyChangesPercentage,
    "Financial Services": item.financialServicesChangesPercentage,
    Healthcare: item.healthcareChangesPercentage,
    Industrials: item.industrialsChangesPercentage,
    "Real Estate": item.realEstateChangesPercentage,
    Technology: item.technologyChangesPercentage,
    Utilities: item.utilitiesChangesPercentage,
  }))

  const toggleSector = (sector: string) => {
    setActiveSectors((prev) => {
      const next = new Set(prev)
      if (next.has(sector)) {
        next.delete(sector)
      } else {
        next.add(sector)
      }
      return next
    })
  }

  // Get the latest performance values for each sector
  const latestPerformance = data?.[data.length - 1] || {}

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-1.5 overflow-x-auto pb-1 -mb-1 scrollbar-none">
          {timeRanges.map((range) => (
            <Button
              key={range}
              variant={selectedRange === range ? "default" : "ghost"}
              onClick={() => setSelectedRange(range)}
              size="sm"
              className={cn(
                "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                selectedRange === range
                  ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                  : "text-gray-500 hover:bg-white/5 hover:text-white",
              )}
            >
              {range}
            </Button>
          ))}
        </div>
      </div>

      <div className="flex-1 w-full mb-6">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            stackOffset="sign"
          >
            <XAxis
              dataKey="date"
              tickFormatter={(value) => value}
              minTickGap={50}
              stroke="transparent"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              domain={["auto", "auto"]}
              tickFormatter={(value) => `${value.toFixed(2)}%`}
              width={0}
              stroke="transparent"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip
              content={<CustomTooltip />}
              contentStyle={{
                backgroundColor: "rgba(0,0,0,0.7)",
                backdropFilter: "blur(8px)",
                border: "1px solid rgba(255,255,255,0.05)",
                borderRadius: "4px",
                color: "#fff",
                padding: "8px",
                fontSize: "12px",
              }}
            />
            {Object.entries({
              "Basic Materials": sectorColors.basicMaterials,
              "Communication Services": sectorColors.communicationServices,
              "Consumer Cyclical": sectorColors.consumerCyclical,
              "Consumer Defensive": sectorColors.consumerDefensive,
              Energy: sectorColors.energy,
              "Financial Services": sectorColors.financialServices,
              Healthcare: sectorColors.healthcare,
              Industrials: sectorColors.industrials,
              "Real Estate": sectorColors.realEstate,
              Technology: sectorColors.technology,
              Utilities: sectorColors.utilities,
            }).map(
              ([key, color]) =>
                activeSectors.has(key) && (
                  <Bar
                    key={key}
                    dataKey={key}
                    name={key}
                    fill={color}
                    stackId="stack"
                    isAnimationActive={false}
                    radius={[2, 2, 2, 2]}
                    maxBarSize={40}
                  />
                ),
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="border-t border-white/5 pt-4">
        <div className="flex flex-wrap gap-1.5 justify-center">
          {Object.entries({
            Technology: sectorColors.technology,
            "Communication Services": sectorColors.communicationServices,
            "Basic Materials": sectorColors.basicMaterials,
            "Consumer Cyclical": sectorColors.consumerCyclical,
            Healthcare: sectorColors.healthcare,
            Industrials: sectorColors.industrials,
            "Financial Services": sectorColors.financialServices,
            Energy: sectorColors.energy,
            "Consumer Defensive": sectorColors.consumerDefensive,
            Utilities: sectorColors.utilities,
            "Real Estate": sectorColors.realEstate,
          }).map(([sector, color]) => (
            <SectorButton
              key={sector}
              sector={sector}
              color={color}
              isVisible={activeSectors.has(sector)}
              onToggle={() => toggleSector(sector)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
