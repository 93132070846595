import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { Card, CardContent } from "@/components/ui/card"
import {
  Banknote,
  Gauge,
  TrendingUp,
  Scale,
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
} from "lucide-react"

interface FinancialHealthData {
  symbol: string
  altmanZScore: number
  piotroskiScore: number
  workingCapital: number
  totalAssets: number
  retainedEarnings: number
  ebit: number
  marketCap: number
  totalLiabilities: number
  revenue: number
}

interface FinancialHealthProps {
  symbol: string
}

export function FinancialHealth({ symbol }: FinancialHealthProps) {
  const { getAccessTokenSilently } = useAuth0()

  const { data, isLoading } = useQuery({
    queryKey: ["financialHealth", symbol],
    queryFn: async () => {
      if (!symbol) return null

      try {
        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/financial-health/${symbol}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (!response.ok) {
          console.error("Failed to fetch financial health data")
          return null
        }

        const healthData = await response.json()
        if (!healthData?.symbol) {
          console.error("Invalid financial health data")
          return null
        }

        return healthData as FinancialHealthData
      } catch (error) {
        console.error("Error fetching financial health:", error)
        return null
      }
    },
    enabled: !!symbol,
    retry: false,
  })

  if (isLoading) return <div>Loading...</div>
  if (!data) return null

  const formatCurrency = (value: number) => {
    if (Math.abs(value) >= 1e12) return `$${(value / 1e12).toFixed(1)}T`
    if (Math.abs(value) >= 1e9) return `$${(value / 1e9).toFixed(1)}B`
    if (Math.abs(value) >= 1e6) return `$${(value / 1e6).toFixed(1)}M`
    return `$${value.toFixed(0)}`
  }

  const metrics = {
    financialStrength: {
      value: data.altmanZScore,
      status:
        data.altmanZScore > 2.99
          ? "Strong"
          : data.altmanZScore > 1.81
            ? "Moderate"
            : "Weak",
      color:
        data.altmanZScore > 2.99
          ? "text-blue-400/80"
          : data.altmanZScore > 1.81
            ? "text-yellow-400/80"
            : "text-red-400/80",
    },
    profitMargin: {
      value: (data.ebit / data.revenue) * 100,
      status:
        (data.ebit / data.revenue) * 100 > 20
          ? "High"
          : (data.ebit / data.revenue) * 100 > 10
            ? "Medium"
            : "Low",
    },
    assetEfficiency: {
      value: (data.ebit / data.totalAssets) * 100,
      status:
        (data.ebit / data.totalAssets) * 100 > 20
          ? "Efficient"
          : (data.ebit / data.totalAssets) * 100 > 10
            ? "Moderate"
            : "Low",
    },
    debtLevel: {
      value: (data.totalLiabilities / data.totalAssets) * 100,
      status:
        (data.totalLiabilities / data.totalAssets) * 100 > 70
          ? "High"
          : (data.totalLiabilities / data.totalAssets) * 100 > 50
            ? "Medium"
            : "Low",
    },
  }

  return (
    <Card className="backdrop-blur-2xl bg-transparent">
      <CardContent className="p-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {/* Financial Strength */}
          <div className="relative p-4 rounded-lg bg-white/[0.02] border border-white/5 transition-all duration-200 hover:bg-white/[0.04] hover:border-white/10">
            <div className="flex items-center gap-2 mb-3">
              <Gauge className="w-4 h-4 text-blue-400/60" />
              <span className="text-xs font-medium text-gray-400">
                Strength
              </span>
            </div>
            <div className="flex flex-col">
              <span
                className={`text-2xl font-semibold ${metrics.financialStrength.color}`}
              >
                {metrics.financialStrength.value.toFixed(1)}
              </span>
              <span className="text-xs text-gray-500 mt-1">
                {metrics.financialStrength.status}
              </span>
            </div>
          </div>

          {/* Profit Margin */}
          <div className="relative p-4 rounded-lg bg-white/[0.02] border border-white/5 transition-all duration-200 hover:bg-white/[0.04] hover:border-white/10">
            <div className="flex items-center gap-2 mb-3">
              <Banknote className="w-4 h-4 text-green-400/60" />
              <span className="text-xs font-medium text-gray-400">Profit</span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-semibold text-gray-200">
                {metrics.profitMargin.value.toFixed(1)}%
              </span>
              <span className="text-xs text-gray-500 mt-1">
                {metrics.profitMargin.status} Margin
              </span>
            </div>
          </div>

          {/* Asset Efficiency */}
          <div className="relative p-4 rounded-lg bg-white/[0.02] border border-white/5 transition-all duration-200 hover:bg-white/[0.04] hover:border-white/10">
            <div className="flex items-center gap-2 mb-3">
              <TrendingUp className="w-4 h-4 text-indigo-400/60" />
              <span className="text-xs font-medium text-gray-400">
                Efficiency
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-semibold text-gray-200">
                {metrics.assetEfficiency.value.toFixed(1)}%
              </span>
              <span className="text-xs text-gray-500 mt-1">
                {metrics.assetEfficiency.status} Returns
              </span>
            </div>
          </div>

          {/* Debt Level */}
          <div className="relative p-4 rounded-lg bg-white/[0.02] border border-white/5 transition-all duration-200 hover:bg-white/[0.04] hover:border-white/10">
            <div className="flex items-center gap-2 mb-3">
              <Scale className="w-4 h-4 text-violet-400/60" />
              <span className="text-xs font-medium text-gray-400">Debt</span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-semibold text-gray-200">
                {metrics.debtLevel.value.toFixed(1)}%
              </span>
              <span className="text-xs text-gray-500 mt-1">
                {metrics.debtLevel.status} Leverage
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
