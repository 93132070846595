import { useParams } from "@tanstack/react-router"
import { EventFullView } from "./EventFullView"

export function EventDetailsPage() {
  const { eventId = "", eventName = "" } = useParams({
    from: "/event/$eventId/$eventName",
  })

  return <EventFullView eventId={eventId} eventName={eventName} />
}
