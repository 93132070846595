import { createFileRoute, useParams } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"
import { SlateRenderer } from "@/components/SlateRenderer"
import { BackButton } from "@/components/BackButton"
import { useUser } from "@/providers/UserProvider"
import { useState, useEffect } from "react"
import BlogEditor from "@/components/BlogEditor/BlogEditor"
import { Navbar } from "@/components"

interface Post {
  _id: string
  title: string
  description: string
  content: any
  author: string
  createdAt: string
  likes: string[]
}

function PostPage() {
  const [isEditing, setIsEditing] = useState(false)
  const [showCopiedToast, setShowCopiedToast] = useState(false)
  const [showShareUrl, setShowShareUrl] = useState(false)
  const [justPublished, setJustPublished] = useState(false)
  const { userData } = useUser()
  const { author, slug } = useParams({ from: "/research/$author/$slug" })

  const {
    data: post,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["post", author, slug],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/posts/${author}/${slug}`,
      )
      if (!response.ok) {
        throw new Error("Failed to fetch post")
      }
      return response.json() as Promise<Post>
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (post && justPublished) {
      setShowShareUrl(true)
      setTimeout(() => {
        setShowShareUrl(false)
        setJustPublished(false)
      }, 5000)
    }
  }, [post, justPublished])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    setShowCopiedToast(true)
    setTimeout(() => setShowCopiedToast(false), 2000)
  }

  const handleEditPublishComplete = async () => {
    setIsEditing(false)
    setJustPublished(true)
    await refetch()
  }

  const handleEditDraftComplete = async () => {
    setIsEditing(false)
    await refetch()
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-zinc-950 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="animate-pulse space-y-8">
            <div className="h-12 bg-zinc-800/50 rounded-lg w-3/4" />
            <div className="h-4 bg-zinc-800/50 rounded w-1/2" />
            <div className="space-y-4">
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={`loading-${i}`}
                  className="h-4 bg-zinc-800/50 rounded"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!post) return null

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <div className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          {!isEditing && (
            <div className="mb-8">
              <BackButton />
            </div>
          )}

          {showShareUrl && (
            <div className="mb-8 flex flex-col gap-3 bg-zinc-900/50 border border-zinc-800/50 p-4 rounded-lg">
              <div className="text-sm text-zinc-400">Share your research</div>
              <div className="flex items-center gap-2">
                <div className="flex-1 bg-black/30 border border-zinc-800/50 px-3 py-2 rounded-lg text-zinc-400 font-mono text-sm">
                  {window.location.href}
                </div>
                <button
                  onClick={handleCopyLink}
                  className="px-4 h-10 bg-zinc-800 hover:bg-zinc-700 text-zinc-300 rounded-lg transition-colors duration-200 flex items-center gap-2 text-sm font-medium"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                    <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                  </svg>
                  Copy
                </button>
              </div>
            </div>
          )}

          {showCopiedToast && (
            <div className="fixed bottom-8 right-8 bg-zinc-800 text-zinc-200 px-4 py-2 rounded-lg shadow-lg transition-all duration-200 border border-zinc-700/50">
              Copied to clipboard
            </div>
          )}

          {!isEditing ? (
            <article>
              <div className="prose prose-invert max-w-none">
                <SlateRenderer
                  content={post.content}
                  author={post.author}
                  userData={userData}
                  postId={post._id}
                  slug={slug}
                  onEdit={() => setIsEditing(true)}
                  title={post.title}
                  createdAt={post.createdAt}
                  likes={post.likes}
                  isLiked={post.likes?.includes(userData?.userName)}
                />
              </div>
            </article>
          ) : (
            <BlogEditor
              initialContent={post.content}
              postId={post._id}
              initialTitle={post.title}
              onCancel={() => setIsEditing(false)}
              onEditPublishComplete={handleEditPublishComplete}
              onEditDraftComplete={handleEditDraftComplete}
              shareUrl={window.location.href}
              onCopyLink={handleCopyLink}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const Route = createFileRoute("/research/$author/$slug")({
  component: PostPage,
})

export default PostPage
