import { useState } from "react"
import { Dialog } from "@headlessui/react"
import { HistoricalAreaChart } from "@/presentation/screens/homescreen/components/EconomicCalendar/EventAnalysisPanel"
import { formatValue } from "@/presentation/screens/homescreen/components/EconomicCalendar/EconomicCalendar"
import { useNavigate } from "@tanstack/react-router"
import { useUser } from "@/providers/UserProvider"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

interface SlateRendererProps {
  content: any[]
  author?: string
  userData?: any
  postId?: string
  slug?: string
  onEdit?: () => void
  title?: string
  createdAt?: string
  likes?: string[]
  isLiked?: boolean
}

export const SlateRenderer = ({
  content,
  author,
  userData,
  postId,
  slug,
  onEdit,
  title,
  createdAt,
  likes,
  isLiked = false,
}: SlateRendererProps) => {
  const [selectedImage, setSelectedImage] = useState<{
    url: string
    alt?: string
  } | null>(null)
  const { refetchUser } = useUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()

  const likeMutation = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/research/like/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userName: userData.userName }),
        },
      )
      if (!response.ok) {
        throw new Error("Failed to like post")
      }
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["post", author, slug] })
      refetchUser()
    },
    onError: (error) => {
      console.error("Error liking post:", error)
    },
  })

  const handleLikeClick = () => {
    if (!userData?.userName) {
      return
    }
    likeMutation.mutate()
  }

  const renderLeaf = (leaf: any) => {
    let text = leaf.text

    if (leaf.bold && leaf.italic) {
      return (
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>{text}</span>
      )
    }
    if (leaf.bold) {
      return <span style={{ fontWeight: "bold" }}>{text}</span>
    }
    if (leaf.italic) {
      return <span style={{ fontStyle: "italic" }}>{text}</span>
    }

    return text
  }

  const renderElementChildren = (children: any[]) => {
    return children.map((child, i) => <span key={i}>{renderLeaf(child)}</span>)
  }

  const renderElement = (element: any) => {
    const baseStyle = { color: "white", maxWidth: "700px", margin: "0 auto" }

    switch (element.type) {
      case "heading-one":
        return (
          <h1
            style={{
              ...baseStyle,
              fontSize: "2.5em",
              fontWeight: "bold",
              marginBottom: "0.5em",
              lineHeight: "1.2",
            }}
          >
            {renderElementChildren(element.children)}
          </h1>
        )

      case "heading-two":
        return (
          <h2
            style={{
              ...baseStyle,
              fontSize: "2em",
              fontWeight: "bold",
              marginBottom: "0.5em",
              lineHeight: "1.2",
            }}
          >
            {renderElementChildren(element.children)}
          </h2>
        )

      case "heading-three":
        return (
          <h3
            style={{
              ...baseStyle,
              fontSize: "1.5em",
              fontWeight: "bold",
              marginBottom: "0.5em",
              lineHeight: "1.2",
            }}
          >
            {renderElementChildren(element.children)}
          </h3>
        )

      case "paragraph":
        return (
          <p
            style={{
              ...baseStyle,
              fontSize: "1em",
              lineHeight: "1.7",
              marginBottom: "0.5em",
              color: "#e0e0e0",
            }}
          >
            {renderElementChildren(element.children)}
          </p>
        )

      case "headline":
        const { headlineData } = element
        return (
          <div
            style={{
              ...baseStyle,
              margin: "2em auto",
            }}
            className="group relative pl-2"
          >
            <div className="absolute left-[10px] top-[22px] w-[11px] h-[11px] rounded-full bg-purple-400/80 ring-4 ring-black group-hover:ring-zinc-800/50 transition-all duration-200" />
            <div className="ml-6 p-5 rounded-lg bg-zinc-900/50 border border-zinc-800/50 hover:bg-zinc-800/50 hover:border-zinc-700/80 transition-all duration-200">
              <div className="text-sm text-zinc-400 mb-2.5">
                {headlineData.time}
              </div>
              <div className="text-white text-lg font-medium leading-relaxed">
                {headlineData.title}
              </div>
            </div>
          </div>
        )

      case "economic-event":
        const { eventData } = element
        return (
          <div
            style={{
              ...baseStyle,
              padding: "1em",
              margin: "1em auto",
              backgroundColor: "#111",
              borderRadius: "8px",
              border: "1px solid #333",
            }}
          >
            <div className="flex items-center gap-2 mb-2">
              <span className="text-gray-400 text-sm">{eventData.dateUtc}</span>
              <span className="text-gray-400">•</span>
              <span className="text-gray-400 text-sm">
                {eventData.countryCode}
              </span>
            </div>
            <div className="text-white text-lg mb-2">{eventData.name}</div>
            <div className="grid grid-cols-3 gap-4 text-sm">
              <div>
                <span className="text-gray-400">Actual: </span>
                <span className="text-white">
                  {eventData.actual === null
                    ? "Pending"
                    : formatValue(
                        Number(eventData.actual),
                        eventData.unit,
                        eventData.potency,
                      )}
                </span>
              </div>
              <div>
                <span className="text-gray-400">Consensus: </span>
                <span className="text-white">
                  {eventData.consensus === null
                    ? "N/A"
                    : formatValue(
                        Number(eventData.consensus),
                        eventData.unit,
                        eventData.potency,
                      )}
                </span>
              </div>
              <div>
                <span className="text-gray-400">Previous: </span>
                <span className="text-white">
                  {formatValue(
                    Number(eventData.previous),
                    eventData.unit,
                    eventData.potency,
                  )}
                </span>
              </div>
            </div>
            {eventData.historicalData && (
              <div className="mt-4">
                <HistoricalAreaChart
                  data={eventData.historicalData.filter(
                    (item: any) => item.actual !== null,
                  )}
                  unit={eventData.unit}
                  potency={eventData.potency}
                />
              </div>
            )}
          </div>
        )

      case "image":
        return (
          <div
            style={{ margin: "1em auto" }}
            className="group relative cursor-pointer"
            onClick={() => setSelectedImage(element)}
          >
            <img
              src={element.url}
              alt={element.alt}
              style={{
                width: element.width || "100%",
                height: element.height || "auto",
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: "4px",
              }}
            />
            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v6m3-3H7"
                />
              </svg>
            </div>

            <Dialog
              open={selectedImage !== null}
              onClose={() => setSelectedImage(null)}
              className="fixed inset-0 z-50 overflow-y-auto"
            >
              <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-black/80" aria-hidden="true" />

                <div className="relative max-w-[90vw] max-h-[90vh]">
                  <img
                    src={selectedImage?.url}
                    alt={selectedImage?.alt}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "90vh",
                      objectFit: "contain",
                    }}
                  />
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedImage(null)
                    }}
                    className="absolute top-4 right-4 text-white/80 hover:text-white"
                    aria-label="Close dialog"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="max-w-[700px] mx-auto">
      {title && (
        <header className="mb-16 mt-8">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-4xl font-bold text-white leading-tight">
              {title}
            </h1>
            <button
              onClick={handleLikeClick}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors duration-200 ${
                isLiked
                  ? "bg-pink-500/10 text-pink-500 hover:bg-pink-500/20"
                  : "bg-zinc-800/50 text-zinc-400 hover:bg-zinc-700/50"
              }`}
              disabled={likeMutation.isPending}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 ${isLiked ? "fill-current" : "stroke-current fill-none"}`}
                viewBox="0 0 24 24"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
              </svg>
              <span>{likes?.length || 0}</span>
            </button>
          </div>
          <div className="flex items-center space-x-4 text-sm">
            {author && (
              <div className="flex items-center flex-1">
                <div
                  className="w-10 h-10 rounded-full bg-zinc-800 flex items-center justify-center text-zinc-300 cursor-pointer"
                  onClick={() => navigate({ to: `/research/${author}` })}
                >
                  {author[0].toUpperCase()}
                </div>
                <div className="ml-3">
                  <div
                    className="text-zinc-200 text-lg cursor-pointer"
                    onClick={() => navigate({ to: `/research/${author}` })}
                  >
                    {author}
                  </div>
                  {createdAt && (
                    <time className="text-zinc-400 text-sm">
                      {new Date(createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </time>
                  )}
                </div>
              </div>
            )}
            {userData?.userName === author && (
              <button
                onClick={onEdit}
                className="px-4 py-2 text-sm font-medium bg-zinc-800 text-zinc-100 rounded-md hover:bg-zinc-700 transition-colors"
              >
                Edit Post
              </button>
            )}
          </div>
          <div className="mt-8 border-b border-zinc-800" />
        </header>
      )}
      <div className="space-y-4">
        {content.map((element, index) => (
          <div key={index}>{renderElement(element)}</div>
        ))}
      </div>
    </div>
  )
}
