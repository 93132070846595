import { Brain, Sparkles } from "lucide-react"
import { ButtonHTMLAttributes } from "react"
import { cn } from "@/lib/utils"

interface AIGenerateButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  isGenerating?: boolean
  variant?: "icon" | "full"
  text?: string
}

export function AIGenerateButton({
  isGenerating = false,
  variant = "icon",
  text = "Analyze",
  className = "",
  ...props
}: AIGenerateButtonProps) {
  if (variant === "icon") {
    return (
      <button
        {...props}
        className={cn(
          "p-2.5 rounded-lg",
          "bg-gradient-to-r from-purple-500/10 to-blue-500/10",
          "hover:from-purple-500/20 hover:to-blue-500/20",
          "border border-purple-500/50",
          "transition-all duration-300 ease-out",
          "relative group",
          className,
        )}
      >
        <Brain
          className={cn(
            "w-7 h-7",
            "transition-colors",
            isGenerating
              ? "text-purple-400"
              : "text-purple-400/80 group-hover:text-purple-300",
          )}
        />
        <div
          className={cn(
            "absolute inset-0 -z-10",
            "bg-gradient-to-r from-purple-500/5 to-blue-500/5",
            "blur-xl opacity-0 group-hover:opacity-100",
            "transition-opacity duration-300",
          )}
        />
      </button>
    )
  }

  return (
    <button
      {...props}
      disabled={isGenerating}
      className={cn(
        "group relative inline-flex items-center gap-3 px-6 py-3 rounded-lg font-medium text-sm transition-all duration-300",
        "bg-gradient-to-r from-indigo-600 via-purple-600 to-purple-700",
        "hover:from-indigo-500 hover:via-purple-500 hover:to-purple-600",
        "disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:from-indigo-600 disabled:hover:via-purple-600 disabled:hover:to-purple-700",
        "border border-purple-500/20 shadow-md shadow-purple-900/20",
        "hover:shadow-purple-900/30 hover:border-purple-500/30 hover:-translate-y-0.5",
        "active:translate-y-0 active:shadow-purple-900/20",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <Sparkles className="w-4 h-4 text-purple-100/90" />
        <span className="text-white">{text}</span>
      </div>
    </button>
  )
}
